import React from "react";
import {
  ITranslationProps,
  IHierarchialDataset,
  IBaseItem,
} from "../data/interfaces";
import { IBreadCrumbNavigationElement } from "./BreadCrumbNavigation";
import BasePanel from "./BasePanel";
import LoadingIndicator from "./LoadingIndicator";
import ActionButtonList, { IActionButtonListItem } from "./ActionButtonList";
import { PrimaryButton } from "office-ui-fabric-react";
import * as Localization from "./Translate";
import { isSite, isDrive, isDriveItem } from "../helpers/typeCheck";
import { ModificationType } from "./ItemModificationQueue";

interface IPickDestinationDialogProps extends ITranslationProps {
  oneDrives: IHierarchialDataset;
  sites: IHierarchialDataset;
  items: IBaseItem[];
  modificationType: ModificationType;

  onMoveItemClickedEvent(
    sourceItems: IBaseItem[],
    targetContainer: IBaseItem
  ): Promise<void>;

  onCopyItemClickedEvent(
    sourceItems: IBaseItem[],
    targetContainer: IBaseItem
  ): Promise<void>;

  reloadItemRequestEvent(item: IBaseItem): Promise<IBaseItem>;

  onPanelCloseEvent(): void;
}

interface IPickDestinationDialogState {
  showPanel: boolean;
  loading: {
    active: boolean;
    message?: string;
  };
  context: {
    currentItems: IBaseItem[];
    selectedSite: IBaseItem | undefined;
    selectedDrive: IBaseItem | undefined;
    selectedDriveItem: IBaseItem | undefined;
  };
  activePanel: Panels;
  data: {
    oneDrives: IBaseItem[];
    sites: IBaseItem[];
    drives: IBaseItem[];
    driveItems: IBaseItem[];
  };
  breadcrumb: {
    historyOfStates: any[];
    items: IBreadCrumbNavigationElement[];
  };
}

enum Panels {
  SITES_AND_ONEDRIVE = 0,
  SUBSITES_AND_DRIVES = 1,
  DRIVEITEMS = 2,
}

class PickDestinationDialog extends React.Component<
  IPickDestinationDialogProps,
  IPickDestinationDialogState
> {
  constructor(props: IPickDestinationDialogProps) {
    super(props);

    this.state = {
      showPanel: true,
      loading: {
        active: false,
      },
      activePanel: Panels.SITES_AND_ONEDRIVE,
      context: {
        currentItems: this.props.items,
        selectedSite: undefined,
        selectedDrive: undefined,
        selectedDriveItem: undefined,
      },
      data: {
        oneDrives: this.props.oneDrives.children,
        sites: this.props.sites.children,
        drives: [],
        driveItems: [],
      },
      breadcrumb: {
        historyOfStates: [],
        items: [
          {
            key: "0",
            title: this.props.i18n.breadCrumbKey0Title,
          },
        ],
      },
    };
  }

  /**
   * Toggles the visibility of the loading component showing an (optional) message.
   * @param message String containing a message to shown, if not specified a default message is shown.
   */
  private toggleLoading(message?: string): void {
    this.setState({
      loading: {
        active: !this.state.loading.active,
        message:
          message !== undefined ? message : this.props.i18n.loadingMessage,
      },
    });
  }

  /**
   * Event handler when a site is selected in the panel
   * @param site ISite instance of the selected site
   */
  private siteSelectedHandler(item: IBaseItem): void {
    let updatedHistory: any[] = [...this.state.breadcrumb.historyOfStates];
    updatedHistory.push({ ...this.state });

    let breadcrumb = {
      historyOfStates: updatedHistory,
      items: [
        ...this.state.breadcrumb.items,
        {
          key: `${this.state.breadcrumb.items.length}`,
          title: item.displayName,
        },
      ],
    };

    if (item.isLoaded) {
      this.setState({
        data: {
          ...this.state.data,
          sites: item.children.filter((child: IBaseItem) => {
            return isSite(child);
          }),
          drives: item.children.filter((child: IBaseItem) => {
            return isDrive(child);
          }),
          driveItems: [],
        },
        activePanel: Panels.SUBSITES_AND_DRIVES,
        context: {
          ...this.state.context,
          selectedSite: item,
          selectedDrive: undefined,
          selectedDriveItem: undefined,
        },
        breadcrumb: breadcrumb,
      });
    } else {
      this.toggleLoading();

      this.props.reloadItemRequestEvent(item).then((loadedItem: IBaseItem) => {
        this.setState({
          data: {
            ...this.state.data,
            sites: loadedItem.children.filter((child: IBaseItem) => {
              return isSite(child);
            }),
            drives: loadedItem.children.filter((child: IBaseItem) => {
              return isDrive(child);
            }),
            driveItems: [],
          },
          activePanel: Panels.SUBSITES_AND_DRIVES,
          loading: {
            active: !this.state.loading.active,
          },
          context: {
            ...this.state.context,
            selectedSite: loadedItem,
            selectedDrive: undefined,
            selectedDriveItem: undefined,
          },
          breadcrumb: breadcrumb,
        });
      });
    }
  }

  /**
   * Event handler when a drive is selected in the panel
   * @param drive IDrive instance of the selected site
   */
  private driveSelectedHandler(item: IBaseItem): void {
    let updatedHistory: any[] = [...this.state.breadcrumb.historyOfStates];
    updatedHistory.push({ ...this.state });

    let breadcrumb = {
      ...this.state.breadcrumb,
      historyOfStates: updatedHistory,
      items: [
        ...this.state.breadcrumb.items,
        {
          key: `${this.state.breadcrumb.items.length}`,
          title: item.displayName,
        },
      ],
    };

    if (item.isLoaded) {
      this.setState({
        data: {
          ...this.state.data,
          driveItems: item.children.filter((child: IBaseItem) => {
            return isDriveItem(child) && child.isContainer;
          }),
        },
        activePanel: Panels.DRIVEITEMS,
        context: {
          ...this.state.context,
          selectedDrive: item,
          selectedDriveItem: undefined,
        },
        breadcrumb: breadcrumb,
      });
    } else {
      this.toggleLoading();

      this.props.reloadItemRequestEvent(item).then((loadedItem: IBaseItem) => {
        this.setState({
          data: {
            ...this.state.data,
            driveItems: loadedItem.children.filter((child: IBaseItem) => {
              return isDriveItem(child) && child.isContainer;
            }),
          },
          activePanel: Panels.DRIVEITEMS,
          loading: {
            active: !this.state.loading.active,
          },
          context: {
            ...this.state.context,
            selectedDrive: loadedItem,
            selectedDriveItem: undefined,
          },
          breadcrumb: breadcrumb,
        });
      });
    }
  }

  /**
   * Event handler when a drive item is selected in the panel
   * @param driveItem IDriveItem instance of the selected site
   */
  private driveItemSelectedHandler(item: IBaseItem): void {
    let updatedHistory: any[] = [...this.state.breadcrumb.historyOfStates];
    updatedHistory.push({ ...this.state });

    let breadcrumb = {
      ...this.state.breadcrumb,
      historyOfStates: updatedHistory,
      items: [
        ...this.state.breadcrumb.items,
        {
          key: `${this.state.breadcrumb.items.length}`,
          title: item.displayName,
        },
      ],
    };

    if (item.isLoaded) {
      this.setState({
        data: {
          ...this.state.data,
          driveItems: item.children.filter((child: IBaseItem) => {
            return isDriveItem(child) && child.isContainer;
          }),
        },
        activePanel: Panels.DRIVEITEMS,
        context: {
          ...this.state.context,
          selectedDriveItem: item,
        },
        breadcrumb: breadcrumb,
      });
    } else {
      this.toggleLoading();

      this.props.reloadItemRequestEvent(item).then((loadedItem: IBaseItem) => {
        this.setState({
          data: {
            ...this.state.data,
            driveItems: loadedItem.children.filter((child: IBaseItem) => {
              return isDriveItem(child) && child.isContainer;
            }),
          },
          activePanel: Panels.DRIVEITEMS,
          loading: {
            active: !this.state.loading.active,
          },
          context: {
            ...this.state.context,
            selectedDriveItem: loadedItem,
          },
          breadcrumb: breadcrumb,
        });
      });
    }
  }

  /**
   * Event handler for when the button is clicked.
   */
  private buttonDriveItemClicked() {
    const {
      currentItems: currentDriveItems,
      selectedDrive,
      selectedDriveItem,
    } = this.state.context;

    let targetContainer: any | undefined;

    // depending on wether the user selected a drive or a drive item
    if (typeof selectedDriveItem !== "undefined") {
      targetContainer = selectedDriveItem;
    } else if (typeof selectedDrive !== "undefined") {
      targetContainer = selectedDrive;
    }

    if (typeof targetContainer !== "undefined") {
      if (this.props.modificationType === ModificationType.MOVE) {
        this.props.onMoveItemClickedEvent(currentDriveItems, targetContainer);
      } else {
        this.props.onCopyItemClickedEvent(currentDriveItems, targetContainer);
      }

      this.onPanelClose();
    }
  }

  private breadcrumbNavigationChanged(navigationKey: string): void {
    let navigationIndex: number = Number(navigationKey);
    let restoredState: IPickDestinationDialogState =
      this.state.breadcrumb.historyOfStates[navigationIndex];

    if (typeof restoredState !== "undefined") {
      this.setState(restoredState);
    }
  }

  /**
   * Event handler for when the user clicks the onDismiss button of the panel
   */
  private onPanelClose() {
    this.props.onPanelCloseEvent();

    this.setState({
      showPanel: false,
    });
  }

  /**
   * render
   */
  render() {
    if (this.state.loading.active) {
      return (
        <BasePanel
          showPanel={this.state.showPanel}
          onDismiss={this.onPanelClose.bind(this)}
          breadcrumb={{ enable: true, items: this.state.breadcrumb.items }}
          breadcrumbNavigationChanged={this.breadcrumbNavigationChanged.bind(
            this
          )}
        >
          <LoadingIndicator message={this.state.loading.message} />
        </BasePanel>
      );
    }

    /*
      Panel containing (root)sites only
     */
    if (this.state.activePanel === Panels.SITES_AND_ONEDRIVE) {
      return (
        <BasePanel
          showPanel={this.state.showPanel}
          onDismiss={this.onPanelClose.bind(this)}
          breadcrumb={{ enable: true, items: this.state.breadcrumb.items }}
          breadcrumbNavigationChanged={this.breadcrumbNavigationChanged.bind(
            this
          )}
        >
          <div className="base-panel-content">
            <div className="action-button-list-group">
              <ActionButtonList
                title={this.props.oneDrives.root}
                iconName="OneDriveLogo"
                items={this.state.data.oneDrives.map((oneDrive: IBaseItem) => {
                  return {
                    id: oneDrive.id,
                    label: oneDrive.displayName,
                    instance: oneDrive,
                    iconName: "OneDriveLogo",
                  } as IActionButtonListItem<IBaseItem>;
                })}
                actionButtonItemClickedHandler={this.driveSelectedHandler.bind(
                  this
                )}
                className="mb-3 mt-2"
                classNameButtonIconColor="OneDriveColor"
              />
              <ActionButtonList
                title={this.props.sites.root}
                iconName="SharepointLogo"
                items={this.state.data.sites.map((site: IBaseItem) => {
                  return {
                    id: site.id,
                    label: site.displayName,
                    instance: site,
                    iconName: "SharepointLogo",
                  } as IActionButtonListItem<IBaseItem>;
                })}
                actionButtonItemClickedHandler={this.siteSelectedHandler.bind(
                  this
                )}
                classNameButtonIconColor="SharePointColor"
              />
            </div>
          </div>
        </BasePanel>
      );
    } else if (this.state.activePanel === Panels.SUBSITES_AND_DRIVES) {
      /*
      Panel containing subsites and drives of a selected site
     */
      return (
        <BasePanel
          showPanel={this.state.showPanel}
          onDismiss={this.onPanelClose.bind(this)}
          breadcrumb={{ enable: true, items: this.state.breadcrumb.items }}
          breadcrumbNavigationChanged={this.breadcrumbNavigationChanged.bind(
            this
          )}
        >
          <div className="base-panel-content">
            <div className="action-button-list-group">
              <ActionButtonList
                items={this.state.data.sites.map((site: IBaseItem) => {
                  return {
                    id: site.id,
                    label: site.displayName,
                    instance: site,
                    iconName: "SharepointLogo",
                  } as IActionButtonListItem<IBaseItem>;
                })}
                actionButtonItemClickedHandler={this.siteSelectedHandler.bind(
                  this
                )}
                classNameButtonIconColor="SharePointColor"
              />
              <ActionButtonList
                items={this.state.data.drives.map((drive: IBaseItem) => {
                  return {
                    id: drive.id,
                    label: drive.displayName,
                    instance: drive,
                    iconName: "DocLibrary",
                  } as IActionButtonListItem<IBaseItem>;
                })}
                actionButtonItemClickedHandler={this.driveSelectedHandler.bind(
                  this
                )}
                classNameButtonIconColor="DocLibraryColor"
              />
            </div>
          </div>
        </BasePanel>
      );
    } else if (this.state.activePanel === Panels.DRIVEITEMS) {
      /*
      Panel containing drive items of a selected drive
     */
      return (
        <BasePanel
          showPanel={this.state.showPanel}
          onDismiss={this.onPanelClose.bind(this)}
          breadcrumb={{ enable: true, items: this.state.breadcrumb.items }}
          breadcrumbNavigationChanged={this.breadcrumbNavigationChanged.bind(
            this
          )}
        >
          <div className="base-panel-content">
            {this.state.data.driveItems.length === 0 &&
              this.props.modificationType === ModificationType.MOVE && (
                <p className="mt-3 mb-4 font-weight-standard">
                  {this.props.i18n.emptyFolderMoveText}
                </p>
              )}
            {this.state.data.driveItems.length === 0 &&
              this.props.modificationType === ModificationType.COPY && (
                <p className="mt-3 mb-4 font-weight-standard">
                  {this.props.i18n.emptyFolderCopyText}
                </p>
              )}
            {this.state.data.driveItems.length > 0 && (
              <ActionButtonList
                items={this.state.data.driveItems.map(
                  (driveItem: IBaseItem) => {
                    return {
                      id: driveItem.id,
                      label: driveItem.displayName,
                      instance: driveItem,
                      iconName: "FolderHorizontal",
                    } as IActionButtonListItem<IBaseItem>;
                  }
                )}
                actionButtonItemClickedHandler={this.driveItemSelectedHandler.bind(
                  this
                )}
                classNameButtonIconColor="FolderHorizontalColor"
              />
            )}

            {this.props.modificationType === ModificationType.MOVE && (
              <PrimaryButton
                disabled={this.state.context.selectedDrive === undefined}
                text={this.props.i18n.moveToHereText}
                onClick={this.buttonDriveItemClicked.bind(this)}
                allowDisabledFocus={true}
                className="base-panel-button"
              />
            )}
            {this.props.modificationType === ModificationType.COPY && (
              <PrimaryButton
                disabled={this.state.context.selectedDrive === undefined}
                text={this.props.i18n.copyToHereText}
                onClick={this.buttonDriveItemClicked.bind(this)}
                allowDisabledFocus={true}
                className="base-panel-button"
              />
            )}
          </div>
        </BasePanel>
      );
    } else return <div />;
  }
}

export default Localization.translateComponent<
  IPickDestinationDialogProps,
  IPickDestinationDialogState
>("DestinationDialog")(PickDestinationDialog);
