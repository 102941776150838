import * as React from "react";

import { IError, IErrorResult } from "../data/interfaces";
import en, { getErrors_en, getError_en } from "../i18n/en";
import nl, { getErrors_nl, getError_nl } from "../i18n/nl";

import { LanguageContext } from "../i18n/context";
import AppSettingsProvider, { IAppSettings } from "../helpers/appSettings";

const languages: any = {
  en,
  nl,
};

export const DEFAULT_FALLBACK_LANGUAGE = "nl-NL";

const resolveUserPreferredLanguage = (): string => {
  if (!window.localStorage) return DEFAULT_FALLBACK_LANGUAGE;

  const userkey: string | null = window.localStorage.getItem(
    "iexpertsnl.fileexplorer.userid"
  );

  if (userkey) {
    const appSettings: IAppSettings = AppSettingsProvider.get(userkey);

    return appSettings.preferredLanguage;
  }

  return DEFAULT_FALLBACK_LANGUAGE;
};

/**
 * Returns a React Component which gets the localization strings inject via i18n property.
 * @param key String containing the type of the component used for identifying a subset of localization strings.
 */
export function translateComponent<P, S>(key: string) {
  return (Component: any) => {
    class TranslationComponent extends React.Component<P, S> {
      static contextType = LanguageContext;

      render() {
        let component: JSX.Element | undefined = undefined;
        let language: any;

        // Get the first to language characters to load the resource file.
        //const languageShort: string = this.context.substring(0, 2);

        const userPreferredLanguage: string =
          resolveUserPreferredLanguage().substring(0, 2);
        if (languages[userPreferredLanguage]) {
          language = languages[userPreferredLanguage];
        } else {
          // When an unsupported language is passed, use the fallback.
          language = languages[DEFAULT_FALLBACK_LANGUAGE];
        }

        component = (
          <Component {...this.props} {...this.state} i18n={language[key]} />
        );

        return component;
      }
    }

    return TranslationComponent;
  };
}

export const formatString = (
  str: string,
  ...values: Array<number | string>
) => {
  const templateSplit = new RegExp(/{(\d)}/g);
  const isNumber = new RegExp(/^\d+$/);
  const splitedText: string[] = str.split(templateSplit);
  let parameterIndex: number;

  return splitedText
    .map((sentence: string, index: number) => {
      if (isNumber.test(sentence)) {
        parameterIndex = Number(sentence);

        if (parameterIndex < values.length) {
          return values[parameterIndex];
        } else {
          return `{${parameterIndex - 1}}`;
        }
      }
      return sentence;
    })
    .join("");
};

export function getLanguages() {
  return languages;
}

export function getLanguagesErrors(languageCode: string) {
  switch (languageCode) {
    case "nl":
      return getErrors_nl();
    case "en":
      return getErrors_en();
    default:
      return getErrors_nl();
  }
}

export function getTranslation(
  componentName: string,
  keyName: string,
  language: string
): string {
  const languages: any = getLanguages();
  const languageCode: string = language.substring(0, 2);

  let languageResourceObject: any = languages[languageCode];
  let returnValue: string = "";

  // Check if the language exists as resource file
  if (languageResourceObject === undefined) {
    languageResourceObject = languages[DEFAULT_FALLBACK_LANGUAGE];
  }

  returnValue = languageResourceObject[componentName][keyName];

  return returnValue;
}

export function getError(
  languageCode: string,
  errorResult: IErrorResult
): IError {
  switch (languageCode) {
    case "nl-NL":
      return getError_nl(errorResult);
    case "en-US":
      return getError_en(errorResult);
    default:
      return getError_nl(errorResult);
  }
}
