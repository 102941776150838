import { IDriveItem, IBaseItem } from "../data/interfaces";
import { isSite, isOneDrive } from "./typeCheck";

export const ValidateName = (name: string): boolean => {
  if (name.length > 0) {
    const regEx = /^[^*":<>?/|\\\t\n\r]*$/;
    if (name.match(regEx)) return true;
  }
  return false;
};

export class Guid {
  public static newGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c: string
    ): string {
      var r: number = (Math.random() * 16) | 0,
        v: number = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

export const GetSiteOrOneDriveUrl = (item: IDriveItem): string => {
  let parentItem: IBaseItem = item.parentItem;
  let found: boolean = false;
  let siteOrOneDriveUrl: string = "";

  // Check whether it is OneDrive or a site
  if (item.driveIsOneDrive) {
    while (!found) {
      if (isOneDrive(parentItem)) {
        siteOrOneDriveUrl = parentItem.driveUrl.replace("/Documents", "");
        found = true;
      } else {
        parentItem = parentItem.parentItem;
      }
    }
    return siteOrOneDriveUrl;
  } else {
    while (!found) {
      if (isSite(parentItem)) {
        siteOrOneDriveUrl = parentItem.webUrl;
        found = true;
      } else {
        parentItem = parentItem.parentItem;
      }
    }

    return siteOrOneDriveUrl;
  }
};
