import { IBaseItem, IHierarchialDataset } from "./interfaces";
import cloneDeep from "lodash.clonedeep";
//import sortBy from "lodash.sortby";
import natsort from "natsort";

/**
 * Purpose of this class to manage a IHierarchialDataset instance by traversing this tree for adding, updating, removing and moving items within that tree.
 */
export default class HierarchialDataManager {
  /**
   * Searches for an IBaseItem instance with the specified id and returns it. If no item was found, undefined is returned.
   * @param data IHierarchialDataset instance describing the dataset
   * @param id String containing the id of the item to search for.
   */
  public findItem(
    data: IHierarchialDataset,
    predicate: (value: IBaseItem) => boolean
  ): IBaseItem | undefined {
    let children: IBaseItem[] = [];
    let currentItem: IBaseItem | undefined;

    for (let child in data.children) {
      children.push(data.children[child]);
    }

    while (children.length > 0) {
      currentItem = children.pop();

      if (typeof currentItem !== "undefined") {
        if (predicate.apply(this, [currentItem])) {
          return currentItem;
        } else {
          if (currentItem.isLoaded && currentItem.children.length > 0) {
            for (let x = 0; x < currentItem.children.length; x++) {
              children.push(currentItem.children[x]);
            }
          }
        }
      }
    }

    return undefined;
  }

  /**
   * Gets the IBaseItem instance using a path of indices by descending down the tree using these indices.
   * @param data IHierarchialDataset instance describing the dataset
   * @param indexPath Array of strings (integers in fact) describing the indices.
   */
  public getItem(
    data: IHierarchialDataset,
    indexPath: string[]
  ): IBaseItem | undefined {
    let currentIndexLevel: number = 0;
    let treeviewIndex: number = 0;
    let currentItem: IBaseItem | undefined;
    let children: IBaseItem[] = data.children;

    while (currentIndexLevel < indexPath.length) {
      treeviewIndex = Number(indexPath[currentIndexLevel]);

      if (treeviewIndex < children.length) {
        currentItem = children[treeviewIndex];

        if (currentItem.isLoaded) {
          children = currentItem.children;
        }

        currentIndexLevel++;
      } else {
        // This should not be possible
        break;
      }
    }

    return currentItem;
  }

  /**
   * Returns a array of IBaseItem instances representing the path of items to the target IBaseItem instance (starting from the root down the target item).
   * @param data IHierarchialDataset instance containing the items
   * @param item IBaseItem instance representing the item
   */
  public getItemPath(data: IHierarchialDataset, item: IBaseItem): IBaseItem[] {
    let currentParent: IBaseItem = item.parentItem;
    let trail: IBaseItem[] = [item];

    while (currentParent !== undefined) {
      trail.push(currentParent);
      currentParent = currentParent.parentItem;
    }

    return trail.reverse();
  }

  /**
   * Adds the specified item as a child item ans returns a shallow copy of the parent IBaseItem.
   * @param container IBaseItem instance representing the parent.
   * @param item New IBaseItem item instance
   */
  public addItemAsChild(
    container: IBaseItem,
    item: IBaseItem,
    children: IBaseItem[]
  ): IBaseItem {
    let shallowCopy: IBaseItem = cloneDeep(container);

    if (shallowCopy.isLoaded) {
      shallowCopy.children.push({
        ...item,
        parentItem: container,
        rootScope: container.rootScope,
        children: children,
      });

      // Sort commented, now the dialogbox is closed even after the last file
      // With this on sometimes the dialogbox is not closed after the last file
      //shallowCopy.children = this.sortItems(shallowCopy.children);

      let newIndex: number = shallowCopy.children.findIndex(
        (newChild: IBaseItem) => {
          return newChild.id === item.id;
        }
      );

      if (newIndex > -1) {
        shallowCopy.children[newIndex].indexPath = container.indexPath.concat([
          newIndex.toString(),
        ]);
      }
    }

    return shallowCopy;
  }

  public addItemAtRoot(
    data: IHierarchialDataset,
    item: IBaseItem
  ): IHierarchialDataset {
    let shallowCopy: IHierarchialDataset = cloneDeep(data);

    shallowCopy.children.push(item);
    shallowCopy.children = this.sortItems(shallowCopy.children);

    item.indexPath = [shallowCopy.children.indexOf(item).toString()];

    return shallowCopy;
  }

  /**
   * Performs a natural sort of the items in the specified array based on the expected results of a file/folder listing.
   * @param items Array containing IBaseItem instances.
   */
  private sortItems(items: IBaseItem[]): IBaseItem[] {
    const sorter = natsort({ insensitive: true });

    let containers: IBaseItem[] = items
      .filter((child: IBaseItem) => {
        return child.isContainer;
      })
      .sort((a: IBaseItem, b: IBaseItem) => {
        return sorter(a.displayName, b.displayName);
      });

    let children: IBaseItem[] = items
      .filter((child: IBaseItem) => {
        return !child.isContainer;
      })
      .sort((a: IBaseItem, b: IBaseItem) => {
        return sorter(a.displayName, b.displayName);
      });

    return [...containers, ...children];
  }

  /**
   * Adds the specified IBaseItem items as childrens to a cloned instance and returns an updated container.
   * @param container IBaseItem instance representing the parent of the new childrens.
   * @param children Array of IBaseItem instances representing the childrens.
   */
  public addItemsAsChildren(
    container: IBaseItem,
    children: IBaseItem[]
  ): IBaseItem {
    let shallowCopy: IBaseItem = cloneDeep(container);

    if (shallowCopy.isLoaded) {
      shallowCopy.children = shallowCopy.children.concat(children);
    }

    shallowCopy.children = this.sortItems(shallowCopy.children);

    children.forEach((newChild: IBaseItem, index: number) => {
      let newIndex: number = shallowCopy.children.findIndex(
        (currentChild: IBaseItem) => {
          if (
            currentChild.referenceId !== undefined &&
            newChild.referenceId !== undefined
          ) {
            return currentChild.referenceId === newChild.referenceId;
          } else {
            return currentChild.id === newChild.id;
          }
        }
      );

      if (newIndex > -1) {
        shallowCopy.children[newIndex].indexPath = container.indexPath.concat([
          newIndex.toString(),
        ]);
      }
    });

    return shallowCopy;
  }

  /**
   * Sets the IBaseItem instance in the IHierarchialDataset and returns a modified dataset instance with the updated item.
   * @param data IHierarchialDataset instance where the IBaseItem instance is located.
   * @param item IBaseItem instance
   */
  public setItem(
    data: IHierarchialDataset,
    item: IBaseItem
  ): IHierarchialDataset {
    let shallowCopy: IHierarchialDataset = cloneDeep(data);

    let currentIndexLevel: number = 0;
    let treeviewIndex: number = 0;
    let currentItem: IBaseItem | undefined;
    let children: IBaseItem[] = shallowCopy.children;

    while (currentIndexLevel < item.indexPath.length) {
      treeviewIndex = Number(item.indexPath[currentIndexLevel]);

      if (currentIndexLevel === item.indexPath.length - 1) {
        treeviewIndex = children.findIndex((baseItem: IBaseItem) => {
          if (
            baseItem.referenceId !== undefined &&
            baseItem.referenceId !== null &&
            item.referenceId !== undefined &&
            item.referenceId !== null
          ) {
            return baseItem.referenceId === item.referenceId;
          } else {
            return baseItem.id === item.id;
          }
        });

        if (treeviewIndex > -1) {
          children[treeviewIndex] = item;
        } else {
          console.warn(
            "Reached index level of item but the item at that level is different then the item we are actually searching for. Therefore the item cannot be set in the tree."
          );
        }
        break;
      } else {
        currentItem = children[treeviewIndex];

        // We can only descent down when then item is loaded (has children)
        if (currentItem.isLoaded) {
          children = currentItem.children;
        }

        currentIndexLevel++;
      }
    }

    return shallowCopy;
  }

  /**
   * Checks if the specified child is actually an child from the container.
   * @param container IBaseItem instance representing the container
   * @param child IBaseItem instance representing the child
   * @returns true if the child is a descendant, else false.
   */
  public itemDescendantOf(container: IBaseItem, child: IBaseItem): boolean {
    let itemIndex: number = 0;

    let stack: IBaseItem[] = [];
    let currentItem: IBaseItem | undefined;

    stack.push(container);

    while (stack.length > 0) {
      currentItem = stack.pop();

      if (!currentItem) return false;

      if (currentItem.id === child.id) {
        return true;
      } else if (currentItem.children && currentItem.children.length) {
        for (
          itemIndex = 0;
          itemIndex < currentItem.children.length;
          itemIndex += 1
        ) {
          stack.push(currentItem.children[itemIndex]);
        }
      }
    }

    return false;
  }

  /**
   * Resets the child in the specified container.
   * @param container IBaseItem instance representing the container
   * @param child IBaseItem instance representing the child
   * @returns Updated container instance
   */
  public resetItemInContainer(
    container: IBaseItem,
    child: IBaseItem
  ): IBaseItem {
    let shallowCopy: IBaseItem = cloneDeep(container);
    let itemIndex: number = 0;

    let stack: IBaseItem[] = [];
    let currentItem: IBaseItem | undefined;
    let currentChildren: IBaseItem[] = [];

    stack.push(shallowCopy);

    while (stack.length > 0) {
      currentItem = stack.pop();

      if (!currentItem) return shallowCopy;

      if (currentItem.id === child.id) {
        let childIndex: number = currentChildren.findIndex(
          (x) => x.id == child.id
        );

        currentChildren[childIndex] = {
          ...currentItem,
          isExpanded: false,
          isLoaded: false,
          children: [],
        };

        return shallowCopy;
      } else if (currentItem.children && currentItem.children.length) {
        currentChildren = currentItem.children;

        for (
          itemIndex = 0;
          itemIndex < currentItem.children.length;
          itemIndex += 1
        ) {
          stack.push(currentItem.children[itemIndex]);
        }
      }
    }

    return shallowCopy;
  }

  public resetItem(
    dataSet: IHierarchialDataset,
    item: IBaseItem | undefined
  ): IHierarchialDataset {
    if (!item) return dataSet;

    let shallowCopy: IHierarchialDataset = cloneDeep(dataSet);

    let currentIndexLevel: number = 0;
    let treeviewIndex: number = 0;
    let currentItem: IBaseItem | undefined;
    let children: IBaseItem[] = shallowCopy.children;
    let itemIsSet: boolean = false;

    while (currentIndexLevel < item.indexPath.length) {
      treeviewIndex = Number(item.indexPath[currentIndexLevel]);

      if (treeviewIndex < children.length) {
        if (currentIndexLevel === item.indexPath.length - 1) {
          children[treeviewIndex] = {
            ...item,
            isExpanded: false,
            isLoaded: false,
            children: [],
          };

          itemIsSet = true;
          break;
        } else {
          currentItem = children[treeviewIndex];

          // We can only descent down when then item is loaded (has children)
          if (currentItem.isLoaded) {
            children = currentItem.children;
          } else {
            // When the current item is not loaded, we cannot descent down to the children as they do not exists
            break;
          }

          currentIndexLevel++;
        }
      } else {
        // This should not be possible
        break;
      }
    }

    if (!itemIsSet) {
      console.warn(
        "WARNING: Item could not be set using HierachialDataManager.setItem() as the item was not found using the indexPath"
      );
    }

    return shallowCopy;
  }

  /**
   * Sets the item as a child of the specified IBaseItem container instance optionally using the predicate expression, else id-matching is performed.
   * @param container IBaseItem instance representing the container
   * @param child IBaseItem isntance representing the child
   * @param predicate Optional predicate to match the item to set.
   */
  public setItemAsChild(
    container: IBaseItem,
    child: IBaseItem,
    predicate?: (value: IBaseItem) => boolean
  ): IBaseItem {
    let shallowCopy: IBaseItem = cloneDeep(container);

    if (shallowCopy.isLoaded && shallowCopy.children.length > 0) {
      let itemIndex: number = shallowCopy.children.findIndex(
        (item: IBaseItem) => {
          if (predicate !== undefined) {
            return predicate.apply(this, [item]);
          } else {
            return item.id === child.id;
          }
        }
      );

      if (itemIndex > -1) {
        child = {
          ...child,
          parentItem: { ...shallowCopy, children: [] },
          indexPath: shallowCopy.indexPath.concat([itemIndex.toString()]),
        };

        shallowCopy.children[itemIndex] = child;
      }
    }

    return shallowCopy;
  }

  public setItemsAsChildren(
    container: IBaseItem,
    children: IBaseItem[],
    predicate?: (value: IBaseItem) => boolean
  ): IBaseItem {
    let shallowCopy: IBaseItem = cloneDeep(container);

    if (shallowCopy.isLoaded && shallowCopy.children.length > 0) {
      children.forEach((child: IBaseItem) => {
        let itemIndex: number = shallowCopy.children.findIndex(
          (item: IBaseItem) => {
            if (predicate !== undefined) {
              return predicate.apply(this, [item]);
            } else {
              return item.id === child.id;
            }
          }
        );

        if (itemIndex > -1) {
          child = {
            ...child,
            parentItem: shallowCopy,
            indexPath: shallowCopy.indexPath.concat([itemIndex.toString()]),
          };

          shallowCopy.children[itemIndex] = child;
        }
      });
    }

    return shallowCopy;
  }

  /**
   * Removes the IBaseItem instance in the IHierarchialDataset and returns a modified dataset instance with the updated item.
   * @param data IHierarchialDataset instance where the IBaseItem instance is located.
   * @param item IBaseItem instance
   */
  public removeItemFromRoot(
    data: IHierarchialDataset,
    item: IBaseItem
  ): IHierarchialDataset {
    let shallowCopy: IHierarchialDataset = cloneDeep(data);

    let currentIndexLevel: number = 0;
    let treeviewIndex: number = 0;
    let currentItem: IBaseItem | undefined;
    let children: IBaseItem[] = shallowCopy.children;

    while (currentIndexLevel < item.indexPath.length) {
      // Use the indexPath because real item index might be unknown on this level
      treeviewIndex = Number(item.indexPath[currentIndexLevel]);

      if (currentIndexLevel === item.indexPath.length - 1) {
        // Since we are on the right level, find real index of item and replace treeviewIndex
        // This is necessary because the indexPaths are not updated after an item removal
        treeviewIndex = children.findIndex((baseItem: IBaseItem) => {
          return baseItem.id === item.id;
        });

        if (treeviewIndex > -1) {
          children.splice(treeviewIndex, 1);
        } else {
          console.warn(
            "Reached index level of item but the item at that level is different then the item we are actually searching for. Therefore the item cannot be removed from the tree."
          );
        }
        break;
      } else {
        currentItem = children[treeviewIndex];

        // We can only descent down when then item is loaded (has children)
        if (currentItem.isLoaded) {
          children = currentItem.children;
        }

        currentIndexLevel++;
      }
    }

    return shallowCopy;
  }

  /**
   * Removes the specified child IBaseItem instance from the container optionally using the predicate expression, else id-matching is performed.
   * @param container IBaseItem instance representing the container containing the child
   * @param child IBaseItem isntance representing the child to remove
   * @param predicate Optional predicate to match the item to remove.
   */
  public removeItemAsChild(
    container: IBaseItem,
    child: IBaseItem,
    predicate?: (value: IBaseItem) => boolean
  ): IBaseItem {
    let shallowCopy: IBaseItem = cloneDeep(container);

    if (shallowCopy.isLoaded && shallowCopy.children.length > 0) {
      let itemIndex: number = shallowCopy.children.findIndex(
        (item: IBaseItem) => {
          if (predicate !== undefined) {
            return predicate.apply(this, [item]);
          } else {
            if (child.referenceId !== undefined && child.referenceId !== null) {
              return item.referenceId === child.referenceId;
            } else {
              // console.log("try on id", item.id, child.id);
              return item.id === child.id;
            }
          }
        }
      );

      if (itemIndex > -1) {
        shallowCopy.children.splice(itemIndex, 1);
      }
    }

    return shallowCopy;
  }

  /**
   *
   * @param data
   * @param previousParentId
   * @param newParentId
   */
  public moveItem(
    data: IHierarchialDataset,
    previousParentId: string,
    newParentId: string
  ): IHierarchialDataset {
    let clonedData: IHierarchialDataset = { ...data };

    // TODO

    return clonedData;
  }
}
