export enum ActionType {
  Add = 1,
  Update,
  Remove
}

export enum ActionScope {
  TreeviewOnly = 1,
  DriveItemsListOnly = 2,
  TreeviewAndDriveItemsList = 3,
}

export enum HierarchialDataScope {
  OneDrives,
  Favorites,
  AllSites,
}

export enum MessageBarType {
  Info = 1,
  Error,
  Success,
  Warning,
}

export enum MessageDialogType {
  Confirm = 1,
  Close,
}

export enum ErrorVisibility {
  None = 1,
  MessageBar,
  GlobalDialog,
}
