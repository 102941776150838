import { DEFAULT_FALLBACK_LANGUAGE } from "../components/Translate";

export interface IAppSettings {
  navigatorWidth: number;
  preferredLanguage: string;
}
const LEGACY_NAVIGATOR_SETTINGS = "iexpertsnl.fileexplorer.navigator";

export default class AppSettingsProvider {
  constructor() {}

  private static key(userKey: string): string {
    return `iexpertsnl.${userKey}.fileexplorer.settings`;
  }

  public static defaults(): IAppSettings {
    return {
      navigatorWidth: 300,
      preferredLanguage: DEFAULT_FALLBACK_LANGUAGE,
    };
  }

  private static migrate(userKey: string): IAppSettings {
    let legacyJson: string | null = window.localStorage.getItem(
      LEGACY_NAVIGATOR_SETTINGS
    );

    if (legacyJson) {
      try {
        const legacySettings: IAppSettings = JSON.parse(legacyJson);
        this.set(userKey, {
          navigatorWidth: (legacySettings as any).width,
          preferredLanguage: DEFAULT_FALLBACK_LANGUAGE,
        });

        window.localStorage.removeItem(LEGACY_NAVIGATOR_SETTINGS);

        return legacySettings;
      } catch {
        console.warn(
          "Could not parse navigator settings json from localstorage, returning default values"
        );

        const defaultSettings = AppSettingsProvider.defaults();
        this.set(userKey, defaultSettings);
        window.localStorage.removeItem(LEGACY_NAVIGATOR_SETTINGS);

        return defaultSettings;
      }
    }

    return AppSettingsProvider.defaults();
  }

  /**
   * Resolves app settings from the localstorage cache.
   */
  public static get(userKey: string): IAppSettings {
    if (!window.localStorage) return AppSettingsProvider.defaults();

    let json: string | null = window.localStorage.getItem(this.key(userKey));

    if (json) {
      try {
        const parsedSettings: IAppSettings = JSON.parse(json);
        return parsedSettings;
      } catch {
        console.warn(
          "Could not parse app settings json from localstorage, returning default values"
        );
        return AppSettingsProvider.defaults();
      }
    } else {
      return this.migrate(userKey);
    }
  }

  /**
   * Sets app settings in the localstorage cache
   */
  public static set(userKey: string, newSettings: IAppSettings): void {
    if (!window.localStorage) return;

    window.localStorage.setItem(this.key(userKey), JSON.stringify(newSettings));
    window.localStorage.setItem("iexpertsnl.fileexplorer.userid", userKey);
  }
}
