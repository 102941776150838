import React from "react";
import {
  TreeView,
  TreeViewItemClickEvent,
  TreeViewExpandChangeEvent,
} from "@progress/kendo-react-treeview";
import {
  IDriveItem,
  ITranslationProps,
  IHierarchialDataset,
  IBaseItem,
  IErrorResult,
  ISite,
} from "../data/interfaces";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Popup, Offset } from "@progress/kendo-react-popup";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { isSite, isDrive, isDriveItem, isOneDrive, isRecycleBin } from "../helpers/typeCheck";
import * as Localization from "./Translate";
import { HierarchialDataScope, MessageBarType } from "../data/enums";
import { Guid } from "../helpers/globalHelper";
import { ModificationType } from "./ItemModificationQueue";

export interface ITreeViewNavigatorProps extends ITranslationProps {
  loadedContainer?: IBaseItem;
  language: string;
  data: IHierarchialDataset;

  itemExpandedChangeEvent?(
    expandedItem: IBaseItem
  ): Promise<boolean | IErrorResult>;
  itemSelectedEvent?(selectedItem: IBaseItem): Promise<boolean | IErrorResult>;
  itemContextMenuClickedEvent?(item: IBaseItem, key: string): void;
  itemDroppedEvent?(sourceItems: IBaseItem[], targetContainer: IBaseItem): void;

  itemCreationEvent(formData: FormData): Promise<IDriveItem>;
  itemsCreatingEvent(item: IBaseItem[]): Promise<void>;
  itemCreatedEvent(item: IBaseItem): Promise<void>;

  globalErrorMessageEvent(error: IErrorResult): void;

  DummyFileRemoveEvent(
    item: IBaseItem,
    modificationType: ModificationType
  ): Promise<boolean>;

  showMessageBar(message: string, messageBarType: MessageBarType): void;

  enableContextMenu: boolean;
}

interface ITreeViewNavigatorState {
  treeViewItems: any[];
  contextMenu: {
    show: boolean;
    enabledKeys: string[];
  };
  isLoading: boolean;
}

class TreeViewNavigator extends React.Component<
  ITreeViewNavigatorProps,
  ITreeViewNavigatorState
> {
  private contextMenuOffset?: Offset;
  private contextMenuDataMember?: any;

  constructor(props: ITreeViewNavigatorProps) {
    super(props);

    this.state = {
      treeViewItems: [],
      contextMenu: {
        show: false,
        enabledKeys: [],
      },
      isLoading: true,
    };
  }

  /**
   * Called when the component is initialized.
   */
  public componentDidMount() {
    if (this.props.data.children.length > 0) {
      let newTreeViewItems: any[] = this.buildTreeView(
        this.props.data.children
      );

      this.setState({
        treeViewItems: newTreeViewItems,
        isLoading: false,
      });
    }

    document.addEventListener("click", () => {
      if (this.state.contextMenu.show)
        this.setState({ contextMenu: { show: false, enabledKeys: [] } });
    });
  }

  /**
   * Called when the component receives new prop values
   * @param prevProps ITreeViewNavigatorProps instance describing the previous prop value
   */
  public componentDidUpdate(prevProps: ITreeViewNavigatorProps) {
    // This is called when the dataset is updated via props
    // This happens when a user clicks a treeview node (site/list/folder) and the childrens from that level are returned from the api and need to be rendered in the tree

    if (this.props.data !== prevProps.data) {
      let newTreeViewItems: any[] = this.buildTreeView(
        this.props.data.children
      );

      this.setState({
        treeViewItems: newTreeViewItems,
        isLoading: false,
      });
    }
  }

  private getSkeletonInstance(parentItem: IBaseItem): IBaseItem {
    return {
      id: "",
      referenceId: Guid.newGuid(),
      displayName: "",
      parentItem: parentItem,
      rootScope: parentItem.rootScope,
      children: [],
      isAdding: true,
      isUpdating: false,
      isDeleting: false,
      isExpanded: false,
      isDirty: true,
      indexPath: [],
      isLoaded: false,
      isLoading: false,
      isContainer: false,
      isMoving: false,
    } as IBaseItem;
  }

  private getDummyItems(rawFiles: FileList): IBaseItem[] {
    let newItems: IBaseItem[] = [];

    if (this.props.loadedContainer !== undefined) {
      for (let index = 0; index < rawFiles.length; index++) {
        let dummyItem: IBaseItem = this.getSkeletonInstance(
          this.props.loadedContainer
        );

        newItems.push({
          ...dummyItem,
          name: rawFiles[index].name,
          displayName: rawFiles[index].name,
          isContainer: false,
          size: rawFiles[index].size,
        } as IDriveItem);
      }
    }

    return newItems;
  }

  /**
   * Builds a recursive list of tree nodes representing the hierarchial data set.
   * @param items Array of IBaseItem instances
   */
  private buildTreeView(items: IBaseItem[]): any[] {
    return items
      .filter((item: IBaseItem) => {
        // We only want container items in the treeview (Site, Drive, DriveItems which are folders)
        return item.isContainer;
      })
      .map((item: IBaseItem) => {
        let treeNode: any;

        if (isRecycleBin(item)){
          treeNode = {
            text: item.displayName,
            id: item.id,
            instance: item,
            expanded: item.isExpanded !== undefined ? item.isExpanded : false,
            hasChildren: false,
          };
        } else {
          treeNode = {
            text: item.displayName,
            id: item.id,
            instance: item,
            expanded: item.isExpanded !== undefined ? item.isExpanded : false,
            hasChildren: true,
          };

          if (item.isLoaded) {
            if (item.children !== undefined) {
              treeNode.hasChildren = item.children.some((child: IBaseItem) => {
                return child.isContainer;
              });
  
              treeNode = {
                ...treeNode,
                expanded: item.isExpanded !== undefined ? item.isExpanded : false,
                items: this.buildTreeView(item.children),
              };
            }
          }
        }

        return treeNode;
      });
  }

  /**
   * Resolves the root node icon based on the loaded dataset.
   */
  private resolveRootNodeIcon(): string {
    switch (this.props.data.scope) {
      case HierarchialDataScope.OneDrives:
        return "D365TalentHRCore";
      case HierarchialDataScope.Favorites:
        return "FavoriteList";
      case HierarchialDataScope.AllSites:
        return "SharepointLogoInverse";
      default:
        return "";
    }
  }

  /**
   * Returns a Office UI Fabric Icon name based on the treeview instance type.
   * @param treeViewItem TreeViewItem instance
   */
  private resolveTreeViewNodeIcon(treeViewItem: any): string {
    if (isDriveItem(treeViewItem.instance)) {
      if (
        typeof treeViewItem.expanded !== "undefined" &&
        treeViewItem.expanded
      ) {
        return "FabricOpenFolderHorizontal";
      } else {
        return "FolderHorizontal";
      }
    } else if (isSite(treeViewItem.instance)) {
      return "SharepointLogo";
    } else if (isDrive(treeViewItem.instance)) {
      if (isOneDrive(treeViewItem.instance)) {
        return "OneDriveLogo";
      } else {
        return "DocLibrary";
      }
    } else if (isRecycleBin(treeViewItem.instance)){
      return "RecycleBin";
    } else {
      return "";
    }
  }

  private resolveTreeViewTopNodeClass(): string {
    switch (this.props.data.scope) {
      case HierarchialDataScope.OneDrives:
        return "treeViewTopNode-item-OneDrive";
      case HierarchialDataScope.Favorites:
        return "treeViewTopNode-item-Favorites";
      case HierarchialDataScope.AllSites:
        return "treeViewTopNode-item-SharePoint";
      default:
        return "";
    }
  }

  /**
   * Returns the class name to use for the specified treeview item.
   * @param treeViewItem Treeview item instance
   */
  private resolveTreeViewNodeClass(treeViewItem: any): string {
    if (isDriveItem(treeViewItem.instance)) {
      return "treeViewNode-driveItem";
    } else if (isSite(treeViewItem.instance)) {
      return "treeViewNode-site";
    } else if (isDrive(treeViewItem.instance)) {
      if (isOneDrive(treeViewItem.instance)) {
        return "treeViewNode-oneDrive";
      } else {
        return "treeViewNode-drive";
      }
    } else {
      return "treeViewNode-recycleBin"
    }
  }

  private resolveTreeViewNodeItemClass(treeViewItem: any): string {
    if (
      this.props.loadedContainer !== undefined &&
      this.props.loadedContainer.id === treeViewItem.id
    ) {
      return "droptarget active";
    } else {
      return "droptarget";
    }
  }

  /**
   * Loads the child nodes of a tree node.
   * @param event TreeViewItemClickEvent or TreeViewExpandChangeEvent event
   */
  private loadChildNodes(
    event: TreeViewItemClickEvent | TreeViewExpandChangeEvent,
    expandingOnly: boolean
  ) {
    let item: IBaseItem = event.item.instance as IBaseItem;

    if (isSite(item) || isDrive(item) || isDriveItem(item) || isRecycleBin(item)) {
      if (expandingOnly) {
        event.item.expanded = !event.item.expanded;

        if (this.props.itemExpandedChangeEvent !== undefined) {
          this.props
            .itemExpandedChangeEvent(item)
            .then((hasChildren: boolean | IErrorResult) => {
              if (
                (hasChildren as IErrorResult).internalErrorCode !== undefined
              ) {
                let errorResult: IErrorResult = hasChildren as IErrorResult;

                // Show error
                this.props.globalErrorMessageEvent(errorResult);

                // Stop loading spinner
                event.item.hasChildren = false;

                return;
              }

              event.item.hasChildren = hasChildren as boolean;
            });
        }
      } else {
        if (!event.item.expanded) {
          event.item.expanded = true;
        }

        if (this.props.itemSelectedEvent !== undefined) {
          this.props
            .itemSelectedEvent(item)
            .then((hasChildren: boolean | IErrorResult) => {
              if (
                (hasChildren as IErrorResult).internalErrorCode !== undefined
              ) {
                let error = hasChildren as IErrorResult;
                this.props.globalErrorMessageEvent(error);
                event.item.hasChildren = false;

                // show error, eventually undo changes and do not process further

                return;
              }

              event.item.hasChildren = hasChildren as boolean;
            });
        }
      }
    }
  }

  ItemOnExpandChangeHandler = (event: TreeViewExpandChangeEvent) => {
    this.loadChildNodes(event, true);
  };

  ItemOnClickHandler = (event: TreeViewItemClickEvent) => {
    this.loadChildNodes(event, false);
  };

  ItemOnContextMenuHandler = (
    e: any,
    treeViewItem: any,
    enabledKeys: string[]
  ) => {
    this.contextMenuDataMember = treeViewItem;

    this.contextMenuOffset = {
      left: e.clientX,
      top: window.scrollY + e.clientY,
    };
    this.setState({
      contextMenu: {
        show: !this.state.contextMenu.show,
        enabledKeys: enabledKeys,
      },
    });
  };

  handleItemContextMenuOnSelect = (e: any) => {
    if (
      isSite(this.contextMenuDataMember.instance) ||
      isDrive(this.contextMenuDataMember.instance)
    ) {
      if (this.props.itemContextMenuClickedEvent !== undefined) {
        this.props.itemContextMenuClickedEvent(
          this.contextMenuDataMember.instance as IBaseItem,
          e.item.data
        );
      }
    }

    this.setState({ contextMenu: { show: false, enabledKeys: [] } });
  };

  // event on dragtarget
  onDragStartHandler = (
    event: React.DragEvent,
    sourceTreeviewNode: any
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer === null) {
      return;
    }

    event.dataTransfer.setData(
      "sourceItem",
      JSON.stringify(sourceTreeviewNode.instance)
    );
  };

  // event on droptarget
  onDropHandler(event: React.DragEvent, targetTreeviewNode: any): void {
    event.preventDefault();
    event.stopPropagation();
    event.persist();

    // event.dataTransfer.files.length is 0 when drag n drop from driveitems to treeview
    // event.dataTransfer.items.length has a length > 0 when drag n drop from driveitems to treeview
    const dataTransfer: DataTransfer = event.dataTransfer;

    // Some pre-checks.
    if (dataTransfer.files.length === 0) {
      // Drop from the driveitemslist.
      let sourceItem: IBaseItem = JSON.parse(
        dataTransfer.getData("sourceItem")
      );

      // Prevent driveItem to be moved to self
      if (
        (sourceItem as IDriveItem).driveId ===
          targetTreeviewNode.instance.driveId &&
        sourceItem.id === targetTreeviewNode.instance.id
      ) {
        return;
      }

      if (typeof this.props.itemDroppedEvent !== "undefined") {
        let array: IBaseItem[] = [sourceItem];
        this.props.itemDroppedEvent(array, targetTreeviewNode.instance);
      }
    } else {
      // Drop from outside the browser.
      // Handle possible multiple files.
      let isFolderIncluded: boolean = false;

      const items: DataTransferItemList = dataTransfer.items;

      // Several pre-checks (files-only and not files bigger than 4294967295 bytes)
      for (let index = 0; index < items.length; index++) {
        var entry: any = items[index].webkitGetAsEntry();
        if (entry.isFile) {
        } else if (entry.isDirectory) {
          isFolderIncluded = true;
          break;
        }
      }

      if (isFolderIncluded) {
        this.props.showMessageBar(
          this.props.i18n.folderInDragnDropSelectionMessage,
          MessageBarType.Warning
        );
        return;
      }

      let isFileTooBig: boolean = false;
      const files: FileList = dataTransfer.files;

      for (let index = 0; index < files.length; index++) {
        if (files[index].size > 4294967295) {
          isFileTooBig = true;
          break;
        }
      }

      if (isFileTooBig) {
        this.props.showMessageBar(
          this.props.i18n.fileTooLargeMessage,
          MessageBarType.Warning
        );
        return;
      }

      // Fill the sorted files list.
      let sortedFiles: any[] = [];
      for (let index = 0; index < files.length; index++) {
        sortedFiles.push(files[index]);
      }

      // Sort on name.
      sortedFiles.sort((a: File, b: File) => (a.name > b.name ? 1 : -1));

      if (
        sortedFiles &&
        sortedFiles.length > 0 &&
        this.props.loadedContainer !== undefined
      ) {
        this.setState({}, () => {
          let dummyItems: IBaseItem[] = this.getDummyItems(
            sortedFiles as unknown as FileList
          );
          // First trigger the event to create dummy items representing the new-files-to-be
          this.props.itemsCreatingEvent(dummyItems).then(async () => {
            if (this.props.loadedContainer !== undefined) {
              for (let index = 0; index < sortedFiles.length; index++) {
                // Now upload the actual file and get the returned IDriveItem instance
                await this.UploadDriveItem(
                  sortedFiles[index],
                  this.props.loadedContainer,
                  dummyItems[index],
                  dummyItems[index].referenceId
                ).catch(() => {
                  this.props.DummyFileRemoveEvent(
                    dummyItems[index],
                    ModificationType.ADD
                  );
                });
              }
            }
          });
          event.dataTransfer.clearData();
        });
      }
    }
  }

  /**
   * Handles uploading of files representing IDriveItem instances.
   * @param rawFile File instance representing the new file.
   * @param parentContainer IBaseItem instance representing the container where the new file will be uploaded to.
   * @param referenceId String containing a reference id used for identifying the new file while performing the upload action.
   */
  private UploadDriveItem(
    rawFile: File,
    parentContainer: IBaseItem,
    dummyItem: IBaseItem,
    referenceId?: string
  ): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      let formData = new FormData();
      formData.set("file", rawFile);

      formData.append(
        "DriveId",
        isDriveItem(parentContainer)
          ? (parentContainer as IDriveItem).driveId
          : parentContainer.id
      );
      formData.append("ParentId", parentContainer.id);
      formData.append(
        "FolderPath",
        parentContainer.path ? `/${parentContainer.path}` : ""
      );

      if (referenceId !== undefined)
        formData.append("ReferenceId", referenceId);

      if (parentContainer.rootScope === HierarchialDataScope.OneDrives)
        formData.append("DriveIsOneDrive", "true");
      else formData.append("DriveIsOneDrive", "false");

      formData.append("Overwrite", "false");

      this.props
        .itemCreationEvent(formData)
        .then((result: IDriveItem) => {
          this.props.itemCreatedEvent({
            ...result,
            displayName: result.name,
            rootScope: parentContainer.rootScope,
            isAdding: false,
            isContainer: false,
            path: result["folderPath"],
            parentItem: parentContainer,
          });
          resolve(result);
        })
        .catch(() => {
          reject();
        });

      /*
      return fetch(`${window.location.origin}/api/item/addFile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData
      })
        .then(async (response: Response) => {
          if (response.ok) {
            var data = response.json();

            await data.then((uploadedItem: IDriveItem) => {
              this.props.itemCreatedEvent({
                ...uploadedItem,
                displayName: uploadedItem.name,
                rootScope: parentContainer.rootScope,
                isAdding: false,
                isContainer: false,
                path: uploadedItem["folderPath"],
                parentItem: parentContainer
              });
            });

            resolve();
          } else {
            throw new Error(response.status.toString()); // Must he here otherwise an undefined item is pushed
          }
        })
        .catch((error: any) => {
          //For example in error: Error: 409

          const httpStatusCode: number = +error
            .toString()
            .replace("Error: ", "");
          const errorMesssage: string =
            getErrorDescription(httpStatusCode, this.props.language) +
            `"` +
            rawFile.name +
            `"`;

          this.props.showMessageBar(errorMesssage, MessageBarType.Error);

          this.props.DummyFileRemoveEvent(dummyItem, ModificationType.ADD);
          resolve();
        });
        */
    });
  }

  // event on droptarget
  onDragEnterHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let target = event.target as HTMLElement;
    if (
      target.classList.contains("droptarget") &&
      target.parentElement !== null
    ) {
      target.parentElement.style.border = "1px dotted black";
    }
  };

  // event on droptarget
  onDragLeaveHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    let target = event.target as HTMLElement;

    if (
      target.classList.contains("droptarget") &&
      target.parentElement !== null
    ) {
      target.parentElement.style.border = "1px solid rgba(0,0,0,0)";
    }
  };

  // event on droptarget
  onDragOverHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onRenderTreeViewItem = (item: any): JSX.Element => {
    // Rendering of Drive and DriveItem IBaseItem instances
    if (isDrive(item.instance) || isDriveItem(item.instance)) {
      return (
        <div
          className={this.resolveTreeViewNodeItemClass(item)}
          draggable={isDriveItem(item.instance)}
          onDragStart={(event: React.DragEvent) =>
            this.onDragStartHandler(event, item)
          }
          onDragEnter={(event: React.DragEvent) =>
            this.onDragEnterHandler(event)
          }
          onDragLeave={(event: React.DragEvent) =>
            this.onDragLeaveHandler(event)
          }
          onDragOver={(event: React.DragEvent) => this.onDragOverHandler(event)}
          onDrop={(event: React.DragEvent) => this.onDropHandler(event, item)}
          onContextMenu={(e: any) => {
            // For DriveItems we keep the default behavior for the context menu, only for Sites and Drives we have a custom context menu.
            if (!isDriveItem(item.instance)) {
              e.preventDefault();
              this.ItemOnContextMenuHandler(e, item, ["ManagePermissions"]);
            }
          }}
        >
          <Icon
            iconName={this.resolveTreeViewNodeIcon(item)}
            className={this.resolveTreeViewNodeClass(item)}
            style={{ marginRight: "5px" }}
          />
          <span className={this.resolveTreeViewNodeItemClass(item)}>
            {item.text}
          </span>
        </div>
      );
    } else if(isSite(item.instance)) {
      // Rendering of Site IBaseItem instances
      return (
        <div
          className={this.resolveTreeViewNodeItemClass(item)}
          onContextMenu={(e: any) => {
            if (isSite(item.instance)) {
              let site: ISite = item.instance as ISite;
              let enabledContextMenuKeys: string[] = ["ManagePermissions"];

              // Only enable the following context menu feature for root level sites
              if (site.indexPath.length === 1) {
                if (site.isFollowing) {
                  enabledContextMenuKeys.push("UnfollowSite");
                } else {
                  enabledContextMenuKeys.push("FollowSite");
                }
              }

              e.preventDefault();
              this.ItemOnContextMenuHandler(e, item, enabledContextMenuKeys);
            }
          }}
        >
          <Icon
            iconName={this.resolveTreeViewNodeIcon(item)}
            className={this.resolveTreeViewNodeClass(item)}
            style={{ marginRight: "5px" }}
          />
          <span className={this.resolveTreeViewNodeItemClass(item)}>
            {item.text}
          </span>
        </div>
      );
    } else {
      // Rendering of IRecyclebin instances
      return (
        <div
          className={this.resolveTreeViewNodeItemClass(item)}
        >
          <Icon
            iconName={this.resolveTreeViewNodeIcon(item)}
            className={this.resolveTreeViewNodeClass(item)}
            style={{ marginRight: "5px", color: "gray" }}
          />
          <span className={this.resolveTreeViewNodeItemClass(item)}>
            {item.text}
          </span>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="navigatorHeader">
        <div className="treeViewTopNode">
          <Icon
            iconName={this.resolveRootNodeIcon()}
            className={this.resolveTreeViewTopNodeClass()}
            style={{ marginRight: "5px" }}
          />
          <span>{this.props.data.root}</span>
        </div>
        {!this.state.isLoading && (
          <TreeView
            className="navigatorControl"
            data={this.state.treeViewItems}
            expandIcons={true}
            onExpandChange={this.ItemOnExpandChangeHandler}
            onItemClick={this.ItemOnClickHandler}
            aria-multiselectable={true}
            itemRender={(props) => this.onRenderTreeViewItem(props.item)}
          />
        )}
        {this.state.isLoading && (
          <div className="loading-treeview-children">
            <span className="k-icon k-i-loading" />
            <span className="loading-text">{this.props.i18n.loadingText}</span>
          </div>
        )}
        {this.props.enableContextMenu && (
          <Popup
            offset={this.contextMenuOffset}
            show={this.state.contextMenu.show}
            popupClass={"context-popup-menu"}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block" }}
              onSelect={this.handleItemContextMenuOnSelect.bind(this)}
            >
              {this.state.contextMenu.enabledKeys.indexOf("ManagePermissions") >
                -1 && (
                <MenuItem
                  text={this.props.i18n.permissionControlText}
                  data="ManagePermissions"
                  cssClass="context-popup-menuitem"
                />
              )}
              {this.state.contextMenu.enabledKeys.indexOf("FollowSite") >
                -1 && (
                <MenuItem
                  text={this.props.i18n.followSite}
                  data="FollowSite"
                  cssClass="context-popup-menuitem"
                />
              )}
              {this.state.contextMenu.enabledKeys.indexOf("UnfollowSite") >
                -1 && (
                <MenuItem
                  text={this.props.i18n.unfollowSite}
                  data="UnfollowSite"
                  cssClass="context-popup-menuitem"
                />
              )}
              {window.location.search.substring(1).startsWith("debug=true") && (
                <MenuItem
                  text={this.props.i18n.reloadItemText}
                  data="ReloadItem"
                />
              )}
            </Menu>
          </Popup>
        )}
      </div>
    );
  }
}

export default Localization.translateComponent<
  ITreeViewNavigatorProps,
  ITreeViewNavigatorState
>("TreeViewNavigator")(TreeViewNavigator);
