import * as React from "react";
import { ActionButton, Icon } from "office-ui-fabric-react";

export interface IActionButtonListProps<T> {
  items: IActionButtonListItem<T>[];
  actionButtonItemClickedHandler?(selectedItem: T): void;
  iconName?: string;
  title?: string;
  className?: string;
  classNameButtonIconColor: string;
}

export interface IActionButtonListItem<T> {
  label: string;
  id: string;
  instance: T;
  iconName: string;
}

class ActionButtonList<T> extends React.Component<
  IActionButtonListProps<T>,
  {}
> {
  constructor(props: IActionButtonListProps<T>) {
    super(props);

    this.state = {
      items: this.props.items,
      selectedItem: undefined
    };
  }

  private onItemActionClicked(item: IActionButtonListItem<T>): void {
    if (this.props.actionButtonItemClickedHandler !== undefined) {
      this.props.actionButtonItemClickedHandler(item.instance as T);
    }
  }

  render() {
    return (
      <div
        className={
          "action-button-list" +
          (this.props.className !== undefined ? " " + this.props.className : "")
        }
      >
        {this.props.title !== "" && (
          <div className="action-button-list-title ml-3">
            {this.props.iconName !== undefined && (
              <Icon
                iconName={this.props.iconName}
                style={{ marginRight: "5px" }}
                className={this.props.classNameButtonIconColor}
              />
            )}
            {this.props.title !== undefined && <span>{this.props.title}</span>}
          </div>
        )}
        <ul
          className={
            "no-bullet content-list p-0" +
            (this.props.title !== undefined && this.props.iconName !== undefined
              ? " ml-0"
              : "")
          }
        >
          {this.props.items.map(item => (
            <li key={item.id}>
              <ActionButton
                iconProps={{
                  iconName: item.iconName,
                  className: this.props.classNameButtonIconColor
                }}
                onClick={this.onItemActionClicked.bind(this, item)}
                className="action-button-list-item"
              >
                {item.label}
              </ActionButton>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ActionButtonList;
