import * as React from "react";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import BreadCrumbNavigation, {
  IBreadCrumbNavigationElement
} from "./BreadCrumbNavigation";
import { Fabric } from "office-ui-fabric-react";
import * as Localization from "./Translate";
import { ITranslationProps } from "../data/interfaces";

export interface IBasePanelProps extends ITranslationProps {
  headerText?: string;
  subText?: string;
  showPanel: boolean;

  breadcrumb?: {
    enable: boolean;
    items: IBreadCrumbNavigationElement[];
  };

  onDismiss(): void;
  breadcrumbNavigationChanged(key: string): void;
}

class BasePanel extends React.Component<IBasePanelProps> {
  private handleDismiss() {
    this.props.onDismiss();
  }

  private onBreadcrumbNavigationChanged(key: string): void {
    this.props.breadcrumbNavigationChanged(key);
  }

  private onRenderHeader = (props: any, defaultRenderer: any): JSX.Element => {
    return (
      <div>
        {this.props.breadcrumb === undefined ||
          (!this.props.breadcrumb.enable && defaultRenderer(props))}

        {this.props.breadcrumb !== undefined &&
          this.props.breadcrumb.enable && (
            <BreadCrumbNavigation
              elements={this.props.breadcrumb.items}
              navigationChanged={this.onBreadcrumbNavigationChanged.bind(this)}
            />
          )}
      </div>
    );
  };

  render() {
    return (
      <Fabric>
        <Panel
          isOpen={this.props.showPanel}
          type={PanelType.medium}
          onDismiss={this.handleDismiss.bind(this)}
          headerText={this.props.headerText}
          closeButtonAriaLabel={this.props.i18n.closeButtonAriaLabel}
          onRenderHeader={this.onRenderHeader}
          className="base-panel"
        >
          {this.props.subText !== undefined && <div>{this.props.subText}</div>}
          <div className="base-panel-children">{this.props.children}</div>
        </Panel>
      </Fabric>
    );
  }
}

export default Localization.translateComponent<IBasePanelProps, {}>(
  "BasePanel"
)(BasePanel);
