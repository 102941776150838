import * as React from "react";
import {
  Breadcrumb,
  IBreadcrumbItem
} from "office-ui-fabric-react/lib/Breadcrumb";

export interface IBreadCrumbNavigationProps {
  elements: IBreadCrumbNavigationElement[];
  navigationChanged(key: string): void;
}

export interface IBreadCrumbNavigationElement {
  title: string;
  key: string;
  static?: boolean;
}

class BreadCrumbNavigation extends React.Component<
  IBreadCrumbNavigationProps,
  { elements: IBreadCrumbNavigationElement[] }
> {
  /**
   * Constructor
   */
  constructor(props: IBreadCrumbNavigationProps) {
    super(props, {});

    this.state = {
      elements: this.props.elements
    };
  }

  public componentDidUpdate(prevProps: IBreadCrumbNavigationProps) {
    if (this.props.elements !== prevProps.elements) {
      this.setState({
        elements: this.props.elements
      });
    }
  }

  /**
   * Event called when the user clicks any of the rendered breadcrumb items.
   */
  private _onBreadcrumbItemClicked = (
    element: IBreadCrumbNavigationElement
  ): void => {
    if (typeof element.static === "undefined" || !element.static) {
      this.props.navigationChanged(element.key);
    }
  };

  /**
   * Render()
   */
  render() {
    let items: IBreadcrumbItem[] = [];

    if (this.state.elements !== null) {
      items = this.state.elements.map(
        (element: IBreadCrumbNavigationElement) => {
          if(element.key == undefined){
            element.key = "recyclebin";
          }
          let item: IBreadcrumbItem = {
            text: element.title,
            key: element.key.toString() 
          };

          // Static breadcrumb element should not have a onClick callback
          if (typeof element.static === "undefined" || !element.static) {
            item = {
              ...item,
              onClick: this._onBreadcrumbItemClicked.bind(this, element)
            };
          }

          return item;
        }
      );
    }

    return (
      <Breadcrumb
        className="breadCrumbNavigation"
        overflowIndex={0}
        items={items}
      >
        {this.props.children}
      </Breadcrumb>
    );
  }
}

export default BreadCrumbNavigation;
