import { IErrorResult, IError } from "../data/interfaces";
import { ErrorVisibility } from "../data/enums";

export default {
  ActionBar: {
    fileLabelRecycleBin: "Recycle bin",
    buttonFileText: "Upload",
    buttonMoveAllText: "Move",
    buttonCopyAllText: "Copy",
    buttonRefreshText: "Refresh",
    buttonDeleteText: "Delete",
    buttonDeleteAllText: "Empty recycle bin",
    buttonRestoreText: "Restore item(s)",
    buttonFolderText: "Folder",
    buttonFolderCreateText: "Create",
    buttonDocumentCreateText: "Create",
    buttonNewDocumentText: "New Document",
    newDocumentInputLabel: "Name",
    buttonEasyTemplate: "EasyTemplate",
    fileAddingFailedMessage: "The file cannot be added",
    fileTooLargeMessage:
      "Upload has been canceled because at least one file is larger than the maximum size of 4 GB.",
    folderNameInputLabel: "Your folder name:",
    folderCreatingFailedMessage: "The folder cannot be created",
    nameEmptyErrorMessage: "Name cannot be empty!",
    nameValidationErrorMessage: `Enter a name that does not include the following characters: " * : < > ? / \\ |`,
  },
  AlertDialog: {
    loadingIndicatorMessage: "Busy Moving...",
  },
  App: {
    errorDialogTitle: "Error",
    moveFileFailedMessage: "Moving Failed.",
    copyFileFailedMessage: "Copying Failed.",
    moveFileFailedMessageResult:
      "One or more items have not been moved. Maybe the file(s) are still in use or allready exist at the target location.",
    copyFileFailedMessageResult:
      "One or more items have not been copied. Maybe the file(s) allready exist at the target location.",
    noSitesFoundMessage: "No sites were found in",
    oneDrivesDatasetName: "Personal files",
    favoriteSitesDatasetName: "Favorite sites",
    sharePointSitesDatasetName: "All sites",
    noTreeviewItemSelectedMessage:
      "No OneDrive, site, library or folder has been selected in the tree structure on the left!",
    noContentsToShowForSites:
      "Click on a library or folder in the tree structure on the left to view it's contents!",
    moveFileMessageDialogTitle: "Moving item(s)",
    copyFileMessageDialogTitle: "Copying item(s)",
    moveFileMessageDialogSourceAndTargetAreEqualMessage:
      "Source and target location are the same. Item(s) cannot be moved.",
    copyFileMessageDialogSourceAndTargetAreEqualMessage:
      "Source and target location are the same. Item(s) cannot be copied.",
    tenantIsValidSPOFailedMessage:
      "This tenant does not have a valid license in Azure or has expired.",
    fileLabelRecycleBin: "Recycle bin",
  },
  BasePanel: {
    closeButtonAriaLabel: "Close",
  },
  CommandBar: {
    searchBoxPlaceHolderText: "Search in SharePoint",
    searchBoxTooltipText: "Submit search query",
  },
  CommandBarDropdown: {
    linkSignOutText: "Sign out",
    myAccountText: "My account",
  },
  DataLoadingSpinnner: {
    loadingIndicatorMessage: "Data is being loaded...",
  },
  DriveItemsList: {
    appNotAvailableMessage:
      "You are trying to open #fileName# in an app, however the required app #appName# is not installed on this computer. Or click 'Open #app#?' in the popup.",
    columnHeaderNameTitle: "Name",
    columnHeaderSizeTitle: "Size",
    columnHeaderModifiedTitle: "Last modified",
    columnHeaderOriginalLocationTitle: "Original location",
    columnHeaderDeletedByTitle: "Deleted by",
    columnHeaderDeletedOnTitle: "Deleted at",
    contextMenuItemRestoreText: "Restore",
    contextMenuItemOpenInBrowserText: "Open in browser",
    contextMenuItemOpenInAppText: "Open in app",
    contextMenuItemRenameText: "Rename",
    contextMenuItemCopyText: "Copy",
    contextMenuItemDownloadText: "Download",
    contextMenuItemRemoveText: "Remove",
    contextMenuItemMoveText: "Move to",
    contextMenuItemShareText: "Share",
    confirmDeletePreFixMessage: "Are you sure you want move",
    confirmDeletePostFixMessage: "to the recycle bin?",
    failedDeleteMessage: "Item cannot be deleted.",
    failedDeletePreFixMessage: "Item",
    failedDeletePostFixMessage: "cannot be deleted.",
    failedRenameMessage: "Item cannot be renamed.",
    messageDialogConfirmTitle: "Confirm delete",
    noDownloadAvailableMessage:
      "At this time it is not possible to download folders and all content.",
    noFilesOrFoldersMessage: "No folders/files available",
    fileLabelFile: "File",
    fileLabelRecycleBin: "Recycle bin",
    fileLabelOf: "of",
    fileAddingFailedMessage: "The file cannot be added",
    fileTooLargeMessage:
      "Upload has been canceled because at least one file is larger than the maximum size of 4 GB.",
    folderInDragnDropSelectionMessage:
      "Only select files please (folders cannot be dragged and dropped yet)",
    folderPostFixTitle: "folder",
  },
  ItemModificationQueue: {
    addMessage: "is being added...",
    uploadMessage: "is being uploaded...",
    removeMessage: "is being removed...",
    restoreMessage: "is being restored...",
    emptyMessage: "Recycle bin is being emptied...",
    updateMessage: "is being updated...",
    renameMessage: "is being renamed...",
    moveMessage: "is being moved...",
    copyMessage: "is being copied...",
    loadMessage: "is being loaded...",
    batchRemoveMessage: "items are being removed...",
    batchMoveMessage: "items are being moved...",
    batchCopyMessage: "items are being copied...",
    labelFile: "File",
    labelFolder: "Folder",
  },
  MessageDialog: {
    buttonNoText: "No",
    buttonYesText: "Yes",
    buttonCloseText: "Close",
  },
  DestinationDialog: {
    breadCrumbKey0Title: "Choose a location",
    loadingMessage: "Please wait...",
    loadingIndicatorMoveMessage: "Item is being moved...",
    emptyFolderMoveText:
      "This folder is empty. Click on the 'Move here' button to move the file to this folder.",
    loadingIndicatorCopyMessage: "Item is being copied...",
    emptyFolderCopyText:
      "This folder is empty. Click on the 'Copy here' button to copy the file to this folder.",
    moveToHereText: "Move here",
    copyToHereText: "Copy here",
  },
  CopyDialog: {
    breadCrumbKey0Title: "Choose a location",
    loadingMessage: "Please wait...",
    loadingIndicatorMessage: "Item is being copied...",
    emptyFolderText:
      "This folder is empty. Click on the 'Copy here' button to copy the file to this folder.",
    copyToHereText: "Copy here",
  },
  ProgressBar: {
    buttonCancelText: "Cancel upload",
    labelCancelText: "Canceling in progress...",
    labelInitText: "Upload in progress...",
    modalTitle: "Uploading files",
  },
  RenameDialog: {
    dialogTitle: "Modify the name",
    newFileNameInputText: "New file name",
    newFolderNameInputText: "New folder name",
    buttonSaveText: "Save",
    buttonCancelText: "Cancel",
    nameEmptyErrorMessage: "Name cannot be empty!",
    nameValidationErrorMessage: `Enter a name that does not include the following characters: " * : < > ? / \\ |`,
    nameLabel: "Name",
  },
  ShareDialog: {
    dialogTitle: "Copy Link",
    copyShareLinkToClipboardText: "Copy the link to the clipboard",
    creatingLinkMessage: "Link is being created...",
    createdLinkMessage: "Link has been copied",
  },
  TenantInvalidMessage: {
    licenseInValidMessage:
      "No active product license is configured this tenant.",
    signedInUserText:
      "You are currently signed in as '{0}'. You can try to sign-out and sign-in using a different account.",
    linkSignOutText: "Sign out",
    linkSignOutTooltipText: "Click here to sign out the current user",
    SPOInValidMessage:
      "This tenant has an invalid or expired license in Azure, the application cannot be started!",
  },
  TreeViewNavigator: {
    fileLabelRecycleBin: "Recycle bin",
    fileTooLargeMessage:
      "Upload has been canceled because at least one file is larger than the maximum size of 4 GB.",
    folderInDragnDropSelectionMessage:
      "Only select files please (folders cannot be dragged and dropped yet)",
    permissionControlText: "Manage permissions",
    reloadItemText: "Reload item",
    loadingText: "are loading...",
    followSite: "Follow site",
    unfollowSite: "Unfollow site",
  },
  upload: {
    cancel: "Cancel", //Cancel
    clearSelectedFiles: "Clear", //Clear
    dropFilesHere: "Drop files here to upload", //Drop files here to upload
    headerStatusUploaded: "Done", //Done
    headerStatusUploading: "Uploading...", //Uploading...
    invalidFileExtension: "File type not allowed.", //File type not allowed.
    invalidFiles: "Invalid file(s). Please check file upload requirements.", //Invalid file(s). Please check file upload requirements.
    invalidMaxFileSize: "File size too large.", //File size too large.
    invalidMinFileSize: "File size too small.", //File size too small.
    remove: "Remove", //Remove
    retry: "Retry", //Retry
    select: "Select files...", //Select files...
    uploadSelectedFiles: "Upload", //Upload
    total: "Total", //Total
    files: "files", //files
  },
  EasyTemplateDialog: {
    Title: "EasyTemplate",
    Description: "Create a new file from an existing template",
    Error: "Oops! Something went wrong",
    Close: "Close",
    Retry: "Try again",
    Back: "Back",
    Next: "Next",
    Unlicensed: "Your organization is not licensed for using this application.",

    Word: "Word",
    Excel: "Excel",
    PowerPoint: "PowerPoint",

    Site: "Site",
    Sites: "Sites",
    Library: "Library",
    Libraries: "Libraries",
    Folder: "Folder",
    Folders: "Folders",
    Templates: "Templates",

    Loading: "Loading {0}...",
    CreatingFile: "Creating file...",
    ForWhichApplication:
      "For which application would you like to create a file?",
    EnterMetadata: "Please enter document information",
    FilenameHelp: "Please enter a name for your file ",
    Preview: "Preview of template '{filename}'",
    CopySuccesfull: "File created succesfully.",
    NewFile: "You can find your new file <a href='{webURL}'>here!</a>",
    SelectTemplate: "Select a template",
    NoTemplatesFound: "No templates found",
    NoPreviewFound: "Preview not available",
    SearchText: "Find a template",
    ChooseLocation: "Where would you like to save your new file?",

    SitePages: "Site Pages",
    SelectTag: "Filter on tag...",
    NoDescriptionText: "",
    NoTagsText: ""
  },
};

const httpErrors = [
  {
    Code: "accessDenied",
    Description: "The caller doesn't have permission to perform the action.",
    HTTPstatuscode: 403,
  },
  {
    Code: "activityLimitReached",
    Description: "The app or user has been throttled.",
    HTTPstatuscode: 429,
  },
  {
    Code: "generalException",
    Description: "An unspecified error has occurred.",
    HTTPstatuscode: 500, // General error
  },
  {
    Code: "invalidRange",
    Description: "The specified byte range is invalid or unavailable.",
    HTTPstatuscode: 416,
  },
  {
    Code: "invalidRequest",
    Description: "The request is malformed or incorrect.",
    HTTPstatuscode: 400,
  },
  {
    Code: "itemNotFound",
    Description: "The resource could not be found.",
    HTTPstatuscode: 404,
  },
  {
    Code: "malwareDetected",
    Description: "Malware was detected in the requested resource.",
    HTTPstatuscode: 500, // General error
  },
  {
    Code: "nameAlreadyExists",
    Description: "The specified item name already exists.",
    HTTPstatuscode: 409,
  },
  {
    Code: "notAllowed",
    Description: "The action is not allowed by the system.",
    HTTPstatuscode: 405,
  },
  {
    Code: "notSupported",
    Description: "The request is not supported by the system.",
    HTTPstatuscode: 415,
  },
  {
    Code: "resourceModified",
    Description:
      "The resource being updated has changed since the caller last read it, usually an eTag mismatch.",
    HTTPstatuscode: 410,
  },
  {
    Code: "resyncRequired",
    Description:
      "The delta token is no longer valid, and the app must reset the sync state.",
    HTTPstatuscode: 500, // General error
  },
  {
    Code: "serviceNotAvailable",
    Description:
      "The service is not available. Try the request again after a delay.There may be a Retry-After header.",
    HTTPstatuscode: 503,
  },
  {
    Code: "quotaLimitReached",
    Description: "The user has reached their quota limit.",
    HTTPstatuscode: 507,
  },
  {
    Code: "unauthenticated",
    Description: "The caller is not authenticated.",
    HTTPstatuscode: 401,
  },

  // Custom statuscodes section
  {
    Code: "pathIsTooLongWhenRenaming",
    Description:
      "The specified file or folder name is too long. The URL path for all files and folders can contain 400 characters or less. Type a shorter file or folder name for: ",
    HTTPstatuscode: 475,
  },
  {
    Code: "pathIsTooLongWhenMoving",
    Description:
      "The total URL path in the new folder becomes too long. The URL path for all files and folders can contain 400 characters or less. First type a shorter file or folder name and try again for: ",
    HTTPstatuscode: 476,
  },
  {
    Code: "tenantNoValidSPOLicense",
    Description:
      "This tenant does not have a valid SPO license or has been expired.", //Tenant does not have a SPO license.
    HTTPstatuscode: 477,
  },
  {
    Code: "pathIsTooLongWhenAddingFolder",
    Description:
      "By creating the new folder, the total URL path becomes too long (due to this new folder and previous folders). The URL path for all files and folders can contain 400 characters or less. Type a shorter folder name and try again for the folder: ",
    HTTPstatuscode: 478,
  },
];

export function getErrors_en() {
  return httpErrors;
}

const errors: IError[] = [
  {
    internalErrorCode: 400,
    correlationId: "",
    message: "Something went wrong. Please report this to the service desk.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 401,
    correlationId: "",
    message:
      "You are not authorized to access the resources for using this application.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 420,
    correlationId: "",
    message: "Your organization is not licensed for using this application.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 421,
    correlationId: "",
    message: "The license for your organization is expired.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 422,
    correlationId: "",
    message: "Your organization could not be verified by Microsoft.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 500,
    correlationId: "",
    message:
      "An unknown error has occured. Please try again or contact the service desk.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 608,
    correlationId: "",
    message: "There is allready an item with the same name.",
    errorVisibility: ErrorVisibility.MessageBar,
  },
  {
    internalErrorCode: 710,
    correlationId: "",
    message: "The choosen name is too long.",
    errorVisibility: ErrorVisibility.MessageBar,
  },
  {
    internalErrorCode: 711,
    correlationId: "",
    message: "Moving of items to the other library has failed.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 720,
    correlationId: "",
    message: "It is not possible to upload an empty file.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 721,
    correlationId: "",
    message: "An error has occured while uploading the file. Please try again.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 723,
    correlationId: "",
    message: "Resolving the choosen file type has failed.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 724,
    correlationId: "",
    message: "An error has occured while creating the file. Please try again.",
    errorVisibility: ErrorVisibility.MessageBar,
  },
  {
    internalErrorCode: 725,
    correlationId: "",
    message:
      "A timeout has occured while moving the item(s). Please try again.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 726,
    correlationId: "",
    message: "The item is locked. Please try again.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 730,
    correlationId: "",
    message:
      "Sharing is not enabled for this item. To enable sharing, please contact your administrator.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 901,
    correlationId: "",
    message:
      "There is allready a file with the same name at the original location. <br /><br />To restore the file, rename the file at the orignal location first.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 902,
    correlationId: "",
    message: "The original location to restore the file has been removed",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 903,
    correlationId: "",
    message: "The file was not found in the recycle bin.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
];

export function getError_en(errorResult: IErrorResult): IError {
  let error: IError | undefined = errors.find((error: IError) => {
    return error.internalErrorCode === errorResult.internalErrorCode;
  });

  if (error === undefined) {
    return {
      internalErrorCode: 400,
      correlationId: "",
      message:
        "Unknown error occured. Please try again or contact the service desk.",
      errorVisibility: ErrorVisibility.GlobalDialog,
    };
  }

  error.correlationId = errorResult.correlationId;

  return error;
}
