import "@progress/kendo-theme-bootstrap/dist/all.css";
import { initializeIcons } from "@uifabric/icons";
import cloneDeep from "lodash.clonedeep";
import React from "react";
import ActionBar from "./components/ActionBar";
import CommandBar from "./components/CommandBar";
import DataLoadingSpinner from "./components/DataLoadingSpinner";
import DriveItemsList from "./components/DriveItemsList";
import ItemModificationQueue, {
  IBaseItemModification,
  //  IItemModification,
  // IModificationSummary,
  ModificationType,
} from "./components/ItemModificationQueue";
import LoadingIndicator from "./components/LoadingIndicator";
import MessageBar from "./components/MessageBar";
import MessageDialog from "./components/MessageDialog";
import PickDestinationDialog from "./components/PickDestinationDialog";
import TenantInvalidMessage from "./components/TenantInvalidMessage";
import TopNavigationBar, {
  ITopNavigationItem,
} from "./components/TopNavigationBar";
import * as Localization from "./components/Translate";
import TreeViewNavigator from "./components/TreeViewNavigator";
import {
  ErrorVisibility,
  HierarchialDataScope,
  MessageBarType,
  MessageDialogType,
} from "./data/enums";
import * as authContext from "./msal";
import HierarchialDataManager from "./data/HierarchialDataManager";
import {
  IAppContext,
  IBaseItem,
  IDrive,
  IDriveItem,
  IDriveItemResult,
  IError,
  IErrorResult,
  IHierarchialDataset,
  IHierarchialDataResult,
  IList,
  IMessageBarContext,
  IMessageDialogContext,
  IOrganizationData,
  IRecycleBin,
  IRootData,
  ISharingLinkData,
  ISite,
  ITranslationProps,
  IUser,
  IOperationStatus,
  IRecycleBinItem,
} from "./data/interfaces";
import ServiceClient from "./data/ServiceClient";

import {
  isDrive,
  isDriveItem,
  isRecycleBin,
  isSite,
} from "./helpers/typeCheck";
import { LanguageContext } from "./i18n/context";
import { Resizable } from "re-resizable";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import "./styles/App.scss";
import AppSettingsProvider, { IAppSettings } from "./helpers/appSettings";
import EasyTemplateDialog from "./components/EasyTemplateDialog";
import { Client } from "./data/EasyTemplateClient";

require("typeface-montserrat");

initializeIcons();

export interface IAppProps extends ITranslationProps {
  context: IAppContext;
}

interface IAppState {
  // Representing the available datasets shown in the treeview
  onedrives: IHierarchialDataset;
  favoriteSites: IHierarchialDataset;
  allSites: IHierarchialDataset;

  // Represents the IBaseItem instance currently loaded in the DriveItemsList
  loadedContainer: IBaseItem | undefined;
  isLoadingContainer: boolean;

  // Represents the IBaseItem instance currently in state of modification
  destinationContext: IBaseItem[] | undefined;
  copyItems: boolean;

  itemModifications: IBaseItemModification[];

  isInitialized: boolean;
  isProcessing: boolean;

  siteCollectionHostname: string;
  language: string;
  userPrincipalName: string;
  siteName: string;
  rootWebUrl: string;
  navigationStack: ITopNavigationItem[];

  messageBarContext?: IMessageBarContext;
  messageDialogContext?: IMessageDialogContext;

  testFailedMessages: boolean;

  user: IUser | undefined;

  organizationData: IOrganizationData | undefined;

  showModificationQueuePanel: boolean;

  itemsSelected: boolean;

  showEasyTemplateDialog: boolean;
  easyTemplateIsLicensed: boolean;
}

class App extends React.Component<IAppProps, IAppState> {
  private _serviceClient: ServiceClient;
  private _hierarchialDataManager: HierarchialDataManager;
  private _appSettings: IAppSettings | undefined;
  private _appInsights: ApplicationInsights;

  constructor(props: any) {
    super(props);

    const testFailedMessages: boolean =
      window.location.search.indexOf("testFailedMessages=true") > -1
        ? true
        : false;

    this.state = {
      onedrives: {
        scope: HierarchialDataScope.OneDrives,
        root: "",
        children: [],
      },
      favoriteSites: {
        scope: HierarchialDataScope.Favorites,
        root: "",
        children: [],
      },
      allSites: {
        scope: HierarchialDataScope.AllSites,
        root: "",
        children: [],
      },
      loadedContainer: undefined,
      isInitialized: false,
      isLoadingContainer: false,
      itemModifications: [],
      isProcessing: false,
      siteCollectionHostname: "",
      language: "",
      userPrincipalName: "",
      siteName: "",
      rootWebUrl: "",
      destinationContext: undefined,
      copyItems: false,
      navigationStack: [],
      messageBarContext: undefined,
      testFailedMessages: testFailedMessages,
      user: undefined,
      organizationData: undefined,
      showModificationQueuePanel: false,
      itemsSelected: false,
      showEasyTemplateDialog: false,
      easyTemplateIsLicensed: false,
    };

    this._serviceClient = new ServiceClient();
    this._hierarchialDataManager = new HierarchialDataManager();
    this._appInsights = this.ConfigureAppInsights(
      this.props.context.metadata.AppInsightsInstrumentationKey
    );
  }

  private ConfigureAppInsights(
    instrumentationKey: string
  ): ApplicationInsights {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: `InstrumentationKey=${instrumentationKey};IngestionEndpoint=https://westeurope-3.in.applicationinsights.azure.com/`,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAjaxErrorStatusText: true,
        enableUnhandledPromiseRejectionTracking: true,
        disableTelemetry: false,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
      },
    });

    const userName: string | undefined = authContext.getUsername();

    if (userName) {
      const validatedId: string = userName.replace(/[,;=| ]+/g, "_");
      appInsights.loadAppInsights();
      appInsights.setAuthenticatedUserContext(validatedId);
      appInsights.trackPageView();
    }

    return appInsights;
  }

  private DetermineUserLanguage(user: IUser): string {
    this._appSettings = AppSettingsProvider.get(user.id);

    let language: string = "";

    if (user.preferredLanguage) {
      language = user.preferredLanguage;
    }
    // When the preferred language is not set: use the language of the browser.
    else {
      language = window.navigator.language;
      console.info(
        "The preferred language is not set in Sharepoint, the language set in the browser (" +
          language +
          ") is used."
      );
    }

    this._appSettings.preferredLanguage = language;
    AppSettingsProvider.set(user.id, this._appSettings);

    return this._appSettings.preferredLanguage;
  }

  private CollectRootData(modifiedState: IAppState): Promise<IAppState> {
    return new Promise<IAppState>((resolve, reject) => {
      this._serviceClient.GetRootData().then(
        (rootData: IRootData) => {
          resolve({
            ...modifiedState,
            siteCollectionHostname: rootData.siteCollectionHostname,
            siteName: rootData.displayName,
            rootWebUrl: rootData.webUrl,
            onedrives: {
              root: Localization.getTranslation(
                "App",
                "oneDrivesDatasetName",
                modifiedState.language
              ),
              children: [],
              scope: HierarchialDataScope.OneDrives,
            },
            favoriteSites: {
              root: Localization.getTranslation(
                "App",
                "favoriteSitesDatasetName",
                modifiedState.language
              ),
              children: [],
              scope: HierarchialDataScope.Favorites,
            },
            allSites: {
              root: Localization.getTranslation(
                "App",
                "sharePointSitesDatasetName",
                modifiedState.language
              ),
              children: [],
              scope: HierarchialDataScope.AllSites,
            },
          });
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }
  
  private async CheckEasyTemplateLicense() {
    try {
      let easyTemplateClient = await Client.GetClient(this._serviceClient);
      let isLicensed = await easyTemplateClient.GetLicense();
      this.setState({ easyTemplateIsLicensed: isLicensed });
    } catch (err) {
      this.setState({ easyTemplateIsLicensed: false });
    }
  }

  private ResolveUserAndOrganization(): Promise<IAppState> {
    let modifiedState: IAppState = {
      ...this.state,
    };

    // In this case the GetUser and GetOrganizationData calls are made sequentially on purpose because we noticed this would cause parallel authentication flows in the backend when there is no token available for the user in the API for making the calls to Graph.
    return new Promise<IAppState>((resolve, reject) => {
      this._serviceClient
        .GetUser()
        .then((user: IUser) => {
          modifiedState = {
            ...modifiedState,
            user: user,
            language: this.DetermineUserLanguage(user),
          };

          this._serviceClient
            .GetOrganizationData()
            .then((organization: IOrganizationData) => {
              modifiedState = {
                ...modifiedState,
                organizationData: organization,
              };

              resolve(modifiedState);
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  /**
   * Called when the component is initialized.
   */
  public componentDidMount() {
    this.ResolveUserAndOrganization().then((modifiedState: IAppState) => {
      modifiedState = {
        ...modifiedState,
        isInitialized: true,
      };

      if (!this.props.context.hasProductLicense) {
        this._appInsights.trackEvent({ name: "InvalidProductLicense" });
        this.setState(modifiedState);
        return;
      }

      this.CollectRootData(modifiedState).then(
        (stateWithRootData: IAppState) => {
          this.setState(stateWithRootData, async () => {
            this.CheckEasyTemplateLicense();

            /**
             *  Applies the dataset resolve from the specified promise to the application state
             * */
            const applyDataSet = (
              dataSetResult: Promise<IErrorResult | IHierarchialDataResult>
            ): Promise<boolean> => {
              return new Promise<boolean>((resolve, reject) => {
                dataSetResult.then(
                  (result: IHierarchialDataResult | IErrorResult) => {
                    let error: IErrorResult | undefined = undefined;

                    if (
                      (result as IErrorResult).internalErrorCode !== undefined
                    ) {
                      error = result as IErrorResult;
                      reject(error);
                    } else {
                      let loadedDataSet: IHierarchialDataset | undefined = (
                        result as IHierarchialDataResult
                      ).updatedDataset;

                      if (typeof loadedDataSet !== "undefined") {
                        switch (loadedDataSet.scope) {
                          case HierarchialDataScope.OneDrives:
                            return this.setState(
                              { onedrives: loadedDataSet },
                              () => {
                                resolve(true);
                              }
                            );
                            break;
                          case HierarchialDataScope.Favorites:
                            return this.setState(
                              { favoriteSites: loadedDataSet },
                              () => {
                                resolve(true);
                              }
                            );
                            break;
                          case HierarchialDataScope.AllSites:
                            return this.setState(
                              { allSites: loadedDataSet },
                              () => {
                                resolve(true);
                              }
                            );
                        }
                        return resolve(true);
                      } else {
                        reject((result as IHierarchialDataResult).error);
                      }
                    }
                  }
                );
              });
            };

            await applyDataSet(
              this._serviceClient.loadDataSetAsync(
                HierarchialDataScope.OneDrives,
                this.state.onedrives.root,
                this.state.siteName
              )
            );

            await applyDataSet(
              this._serviceClient.loadDataSetAsync(
                HierarchialDataScope.Favorites,
                this.state.favoriteSites.root,
                this.state.siteCollectionHostname
              )
            );

            await applyDataSet(
              this._serviceClient.loadDataSetAsync(
                HierarchialDataScope.AllSites,
                this.state.allSites.root,
                this.state.siteCollectionHostname
              )
            );
          });
        }
      );
    });
  }

  private GetDataSetOfItem(item: IBaseItem | IRecycleBin): IHierarchialDataset {
    switch (item.rootScope) {
      case HierarchialDataScope.OneDrives:
        return this.state.onedrives;
      case HierarchialDataScope.Favorites:
        return this.state.favoriteSites;
      case HierarchialDataScope.AllSites:
        return this.state.allSites;
    }
  }

  /**
   * Event called when an item in the treeview (Site, Drive or DriveItem) is expanded.
   * @param dataset IHierarchialDataset instance in which the user is navigating.
   * @param item IBaseItem instance describing the item which is expanding.
   */
  private treeViewItemExpandedEvent(
    item: IBaseItem
  ): Promise<boolean | IErrorResult> {
    return new Promise<boolean | IErrorResult>((resolve, reject) => {
      let currentDataSet: IHierarchialDataset = this.GetDataSetOfItem(item);
      let promise: Promise<IBaseItem | IRecycleBin | IErrorResult>;

      if (!item.isLoaded) {
        promise = this._serviceClient.loadItemAsync(
          currentDataSet,
          item,
          this.props.i18n.fileLabelRecycleBin
        );
      } else {
        promise = Promise.resolve(item);
      }

      promise.then((refreshedItem: IBaseItem | IRecycleBin | IErrorResult) => {
        if ((refreshedItem as IErrorResult).internalErrorCode !== undefined) {
          this.setState({ isLoadingContainer: false });
          resolve(refreshedItem as IErrorResult);
        } else {
          (refreshedItem as IBaseItem).isExpanded = !(
            refreshedItem as IBaseItem
          ).isExpanded;

          let modifiedState: IAppState = {
            ...this.state,
          };

          // Depending on the scope of the dataset, we need to updated different state members
          if (item.rootScope === HierarchialDataScope.OneDrives) {
            modifiedState = {
              ...modifiedState,
              onedrives: this._hierarchialDataManager.setItem(
                this.state.onedrives,
                refreshedItem as IBaseItem
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.Favorites) {
            modifiedState = {
              ...modifiedState,
              favoriteSites: this._hierarchialDataManager.setItem(
                this.state.favoriteSites,
                refreshedItem as IBaseItem
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.AllSites) {
            modifiedState = {
              ...modifiedState,
              allSites: this._hierarchialDataManager.setItem(
                this.state.allSites,
                refreshedItem as IBaseItem
              ),
            };
          }

          this.setState(modifiedState, () => {
            resolve((refreshedItem as IBaseItem).children.length > 0);
          });
        }
      });
    });
  }

  /**
   * Event called when an item in the treeview (Site, Drive or DriveItem) is selected.
   * @param dataset IHierarchialDataset instance in which the user is navigating.
   * @param item IBaseItem instance describing the item which is selected.
   */
  private treeViewItemSelectedEvent(
    item: IBaseItem,
    skipNavigationRebuild?: boolean
  ): Promise<boolean | IErrorResult> {
    let currentDataSet: IHierarchialDataset = this.GetDataSetOfItem(item);
    let modifiedState: IAppState = {
      ...this.state,
    };

    if (skipNavigationRebuild === undefined || !skipNavigationRebuild) {
      modifiedState = {
        ...modifiedState,
        navigationStack: this.buildNavigationStack(currentDataSet, item),
      };
    }

    return new Promise<boolean | IErrorResult>((resolve, reject) => {
      let promise: Promise<IBaseItem | IRecycleBin | IErrorResult>;

      if (!item.isLoaded) {
        if (!isSite(item)) {
          this.setState({
            isLoadingContainer: true,
          });
        }
        promise = this._serviceClient.loadItemAsync(
          currentDataSet,
          item,
          this.props.i18n.fileLabelRecycleBin
        );
      } else {
        promise = Promise.resolve({ ...item, isLoading: true });
      }

      promise.then((refreshedItem: IBaseItem | IRecycleBin | IErrorResult) => {
        if ((refreshedItem as IErrorResult).internalErrorCode !== undefined) {
          this.setState({ isLoadingContainer: false });
          resolve(refreshedItem as IErrorResult);
        } else {
          if (!item.isExpanded) {
            (refreshedItem as IBaseItem).isExpanded = true;
          }

          // Depending on the scope of the dataset, we need to updated different state members
          if (item.rootScope === HierarchialDataScope.OneDrives) {
            modifiedState = {
              ...modifiedState,
              onedrives: this._hierarchialDataManager.setItem(
                this.state.onedrives,
                refreshedItem as IBaseItem
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.Favorites) {
            modifiedState = {
              ...modifiedState,
              favoriteSites: this._hierarchialDataManager.setItem(
                this.state.favoriteSites,
                refreshedItem as IBaseItem
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.AllSites) {
            modifiedState = {
              ...modifiedState,
              allSites: this._hierarchialDataManager.setItem(
                this.state.allSites,
                refreshedItem as IBaseItem
              ),
            };
          }

          modifiedState = {
            ...modifiedState,
            loadedContainer: refreshedItem as IBaseItem,
            isLoadingContainer: false,
            messageBarContext: undefined,
            itemsSelected: false,
          };

          this.setState(modifiedState, () => {
            resolve((refreshedItem as IBaseItem).children.length > 0);
          });
        }
      });
    });
  }

  private enqueueItemModification(
    currentModifications: IBaseItemModification[],
    items: IBaseItem[],
    type: ModificationType
    //urls?: string[]
  ): IBaseItemModification[] {
    let modifications: IBaseItemModification[] =
      cloneDeep(currentModifications);

    let itemsModification: IBaseItemModification = {
      items: items,
      type: type,
      monitorUrls: undefined,
    };
    modifications.push(itemsModification);

    return modifications;
  }

  private setMonitorUrlsForItemModifications(
    currentModifications: IBaseItemModification[],
    items: number,
    type: ModificationType,
    urls: string[]
  ): IBaseItemModification[] {
    let modifications: IBaseItemModification[] =
      cloneDeep(currentModifications);

    //TODO Use map instead
    modifications.forEach((modification) => {
      if (modification.items.length === items && modification.type === type) {
        console.log(urls);
        modification.monitorUrls = urls;
      }
    });

    return modifications;
  }

  // private isModificationSummary(modification: IBaseItemModification) {
  //   let itemModification = modification as IModificationSummary;
  //   return itemModification.quantity !== undefined;
  // }

  private dequeueItemModification(
    currentModifications: IBaseItemModification[],
    items: IBaseItem[],
    type: ModificationType,
    predicate?: (value: IBaseItem) => boolean
  ): IBaseItemModification[] {
    let modifications: IBaseItemModification[] =
      cloneDeep(currentModifications);

    let index: number = modifications.findIndex(
      (modification: IBaseItemModification) => {
        if (items.length === 1) {
          if (predicate !== undefined) {
            return (
              predicate.apply(this, [items[0]]) && modification.type === type
            );
          } else {
            if (modification.items[0].referenceId !== undefined)
              return (
                modification.items[0].referenceId === items[0].referenceId &&
                modification.type === type
              );
            else
              return (
                modification.items[0].id === items[0].id &&
                modification.type === type
              );
          }
        } else {
          return (
            modification.items.length === items.length &&
            modification.type === type
          );
        }
      }
    );

    if (index > -1) {
      modifications.splice(index, 1);
    } else {
      if (items.length === 1) {
        console.warn(
          `No item modification found for ${items[0].displayName} of type ${type}`
        );
      } else {
        console.warn(
          `No item modification found for ${items.length} items of type ${type}`
        );
      }
    }

    return modifications;
  }

  /**
   * Event called when the user clicks a context menu item in the treeview
   * @param dataset
   * @param item
   * @param key
   */
  private treeViewItemContextMenuClickedEvent(
    item: IBaseItem,
    key: string
  ): void {
    switch (key) {
      case "ManagePermissions":
        if (isSite(item)) {
          let site: ISite = item as ISite;
          window.open(`${site.webUrl}/_layouts/15/user.aspx`);
        } else if (isDrive(item)) {
          this.setState({ isProcessing: true });

          let drive: IDrive = item as IDrive;
          let parentSite: ISite = drive.parentItem as ISite;
          this._serviceClient
            .ResolveSPList(parentSite.id, drive.driveUrl)
            .then((list: IList) => {
              const url: string = `${
                parentSite.webUrl
              }/_layouts/15/user.aspx?obj=${this.encodeGuid(
                list.id
              )},doclib&List=${this.encodeGuid(list.id)}`;
              window.open(url);
              this.setState({ isProcessing: false });
            })
            .catch((error: IErrorResult) => {
              this.globalErrorMessageHandler(error);
            });
        }
        break;
      case "FollowSite":
        if (isSite(item)) {
          let site: ISite = cloneDeep(item);

          this.setState(
            {
              favoriteSites: this._hierarchialDataManager.addItemAtRoot(
                this.state.favoriteSites,
                {
                  ...site,
                  isFollowing: true,
                  rootScope: HierarchialDataScope.Favorites,
                } as ISite
              ),
              allSites: this._hierarchialDataManager.setItem(
                this.state.allSites,
                {
                  ...site,
                  isFollowing: true,
                  rootScope: HierarchialDataScope.AllSites,
                } as ISite
              ),
            },
            () => {
              this._serviceClient.FollowSite(item.id).then(() => {
                // Site is now followed
              });
            }
          );
        }

        break;
      case "UnfollowSite":
        if (isSite(item)) {
          let site: ISite = cloneDeep(item);

          this.setState(
            {
              favoriteSites: this._hierarchialDataManager.removeItemFromRoot(
                this.state.favoriteSites,
                item
              ),
              allSites: this._hierarchialDataManager.setItem(
                this.state.allSites,
                {
                  ...site,
                  isFollowing: false,
                  rootScope: HierarchialDataScope.AllSites,
                } as ISite
              ),
            },
            () => {
              this._serviceClient.UnfollowSite(item.id).then(() => {
                // Site is now unfollowed
              });
            }
          );
        }

        break;
      case "ReloadItem":
        let modifiedState: IAppState = {
          ...this.state,
        };

        if (item.rootScope === HierarchialDataScope.OneDrives) {
          modifiedState = {
            ...modifiedState,
            onedrives: this._hierarchialDataManager.resetItem(
              this.state.onedrives,
              item
            ),
          };
        } else if (item.rootScope === HierarchialDataScope.Favorites) {
          modifiedState = {
            ...modifiedState,
            favoriteSites: this._hierarchialDataManager.resetItem(
              this.state.favoriteSites,
              item
            ),
          };
        } else if (item.rootScope === HierarchialDataScope.AllSites) {
          modifiedState = {
            ...modifiedState,
            allSites: this._hierarchialDataManager.resetItem(
              this.state.allSites,
              item
            ),
          };
        }

        this.setState(modifiedState, () => {
          let currentDataSet: IHierarchialDataset = this.GetDataSetOfItem(item);
          this._serviceClient
            .loadItemAsync(currentDataSet, {
              ...item,
              isLoading: true,
            })
            .then((refreshedItem: IBaseItem | IRecycleBin | IErrorResult) => {
              // Depending on the scope of the dataset, we need to updated different state members
              if (item.rootScope === HierarchialDataScope.OneDrives) {
                modifiedState = {
                  ...modifiedState,
                  onedrives: this._hierarchialDataManager.setItem(
                    this.state.onedrives,
                    {
                      ...(refreshedItem as IBaseItem),
                      isLoading: false,
                      isExpanded: true,
                    }
                  ),
                };
              } else if (item.rootScope === HierarchialDataScope.Favorites) {
                modifiedState = {
                  ...modifiedState,
                  favoriteSites: this._hierarchialDataManager.setItem(
                    this.state.favoriteSites,
                    {
                      ...(refreshedItem as IBaseItem),
                      isLoading: false,
                      isExpanded: item.isExpanded,
                    }
                  ),
                };
              } else if (item.rootScope === HierarchialDataScope.AllSites) {
                modifiedState = {
                  ...modifiedState,
                  allSites: this._hierarchialDataManager.setItem(
                    this.state.allSites,
                    {
                      ...(refreshedItem as IBaseItem),
                      isLoading: false,
                      isExpanded: item.isExpanded,
                    }
                  ),
                };
              }

              this.setState(modifiedState);
            });
        });

        break;
      default:
        break;
    }
  }

  /**
   * Returns a URI encoded string of the specified guid string
   * @param guid String containing a guid value
   */
  private encodeGuid(guid: string): string {
    return encodeURIComponent(`{${guid}}`).replace(/-/g, "%2D");
  }

  /**
   * Adds the specified IBaseItem instance to the navigation stack and returns new stack.
   * @param item IBaseItem instance to add.
   */
  private addToNavigationStack(item: IBaseItem): ITopNavigationItem[] {
    let currentStack: ITopNavigationItem[] = [...this.state.navigationStack];

    currentStack.push({
      key: item.id,
      title: item.displayName,
      static: false,
      instance: item,
    });

    return currentStack;
  }

  /**
   * Removes the item at the specified index (and everything after the item) from the navigation stack.
   * @param index Index of the item to remove.
   */
  private removeFromNavigationStack(index: number): ITopNavigationItem[] {
    let currentStack: ITopNavigationItem[] = [...this.state.navigationStack];

    for (let x: number = currentStack.length - 1; x >= 0; x--) {
      if (x > index) {
        currentStack.splice(x, 1);
      }
    }
    return currentStack;
  }

  /**
   * Event handler for when the user clicks an item in the top navigation bar.
   * @param key String containing the key of the clicked item.
   */
  private navigationStackClicked(
    key: string,
    index: number,
    instance: any
  ): void {
    if (instance !== undefined) {
      let item: IBaseItem | undefined = this._hierarchialDataManager.findItem(
        this.GetDataSetOfItem(instance),
        (x: IBaseItem) => {
          return x.id === instance.id;
        }
      );

      if (item !== undefined) {
        this.setState(
          {
            navigationStack: this.removeFromNavigationStack(index),
          },
          () => {
            this.treeViewItemSelectedEvent(item as IBaseItem, true);
          }
        );
      }
    }
  }

  /**
   * Returns an array containing ITopNavigationItem instances describing the breadcrumb path.
   * @param selectedItem Currently selected item (IDrive or IDriveItem).
   */
  private buildNavigationStack(
    dataset: IHierarchialDataset,
    selectedItem: IBaseItem
  ): ITopNavigationItem[] {
    let navigationStack: ITopNavigationItem[] = [];

    navigationStack.push({
      key: "",
      title: dataset.root,
      static: true,
    });

    let trail: IBaseItem[] = this._hierarchialDataManager.getItemPath(
      dataset,
      selectedItem
    );

    trail.forEach((item: IBaseItem) => {
      navigationStack.push({
        key: item.id,
        title: item.displayName,
        static: isRecycleBin(item) || (isSite(item) && !isDriveItem(item)), // Sites are not clickable at the moment
        instance: item,
      });
    });

    return navigationStack;
  }

  /**
   * Event called when the user clicks a item in the DriveItemList component.
   * @param item IBaseItem instance representing the clicked item.
   */
  private DriveItemListFolderClickedHandler(item: IBaseItem): void {
    this.treeViewItemSelectedEvent(item as IBaseItem);
  }

  /**
   * Event called when the user submits the rename dialog from the DriveItemList component.
   * @param item IBaseItem instance representing the item to rename
   * @param newName String containing the new name of the item
   */
  private DriveItemListItemRenameHandler(
    item: IBaseItem,
    newName: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.state.loadedContainer !== undefined) {
        // First update the currentItem state to ensure the isUpdating flag is set
        this.setState({
          loadedContainer: this._hierarchialDataManager.setItemAsChild(
            this.state.loadedContainer,
            {
              ...item,
              displayName: newName,
              isUpdating: true,
            }
          ),
          itemModifications: this.enqueueItemModification(
            this.state.itemModifications,
            [item],
            ModificationType.RENAME
          ),
          showModificationQueuePanel: true,
        });

        this._serviceClient
          .RenameItemAsync(item, newName)
          .then((renamedItemX: IBaseItem | void) => {
            if (this.state.loadedContainer === undefined) {
              // This should not be possible
              return;
            }

            let renamedItem: IBaseItem = renamedItemX as IBaseItem;

            // Create a new state
            let modifiedState: IAppState = {
              ...this.state,
              loadedContainer: this._hierarchialDataManager.setItemAsChild(
                this.state.loadedContainer,
                renamedItem
              ),
              itemModifications: this.dequeueItemModification(
                this.state.itemModifications,
                [item],
                ModificationType.RENAME
              ),
            };

            // Depending on the scope of the dataset, we need to updated different state members
            if (renamedItem.rootScope === HierarchialDataScope.OneDrives) {
              modifiedState = {
                ...modifiedState,
                onedrives: this._hierarchialDataManager.setItem(
                  this.state.onedrives,
                  renamedItem
                ),
              };
            } else if (
              renamedItem.rootScope === HierarchialDataScope.Favorites
            ) {
              modifiedState = {
                ...modifiedState,
                favoriteSites: this._hierarchialDataManager.setItem(
                  this.state.favoriteSites,
                  renamedItem
                ),
              };
            } else if (
              renamedItem.rootScope === HierarchialDataScope.AllSites
            ) {
              modifiedState = {
                ...modifiedState,
                allSites: this._hierarchialDataManager.setItem(
                  this.state.allSites,
                  renamedItem
                ),
              };
            }

            this.setState(modifiedState, () => {
              resolve();
            });
          })
          .catch((error: IErrorResult) => {
            this.globalErrorMessageHandler(error);

            // Update the state.
            if (this.state.loadedContainer !== undefined) {
              let modifiedState: IAppState = {
                ...this.state,
                loadedContainer: this._hierarchialDataManager.setItemAsChild(
                  this.state.loadedContainer,
                  { ...item, displayName: item.displayName, isUpdating: false }
                ),
                itemModifications: this.dequeueItemModification(
                  this.state.itemModifications,
                  [item],
                  ModificationType.RENAME
                ),
              };

              this.setState(modifiedState, () => {
                resolve();
              });
            }
          });
      }
    });
  }

  /**
   * Finds the drive or drive item instance in the IHierarchialDataset instance.
   * @param dataSet IHierarchialDataset instance
   * @param itemReference reference to the item
   * @returns IBaseItem instance resolved from the IHierarchialDataset instance.
   */
  private FindDriveOrDriveItem(
    dataSet: IHierarchialDataset,
    itemReference: IBaseItem | string | undefined
  ): IBaseItem | undefined {
    let itemUrl: string = "";

    if (typeof itemReference === "object") {
      itemUrl = isDrive(itemReference)
        ? (itemReference as IDrive).driveUrl
        : (itemReference as IDriveItem).webUrl;
    }

    if (typeof itemReference === "string") {
      itemUrl = itemReference;
    }

    let item: IBaseItem | undefined = this._hierarchialDataManager.findItem(
      dataSet,
      (x: IBaseItem) => {
        let drive: IDrive = x as IDrive;
        return drive && drive.driveUrl == itemUrl;
      }
    );

    if (!item) {
      item = this._hierarchialDataManager.findItem(dataSet, (x: IBaseItem) => {
        let driveItem: IDriveItem = x as IDriveItem;

        return driveItem && driveItem.webUrl == itemUrl;
      });
    }

    return item;
  }

  /**
   * Finds the parent site instance in the IHierarchialDataset instance.
   * @param dataSet IHierarchialDataset isntance
   * @param item IBaseItem instance
   * @returns IBaseItem instance representing the parent site.
   */
  private FindParentSite(
    dataSet: IHierarchialDataset,
    item: IBaseItem | undefined
  ): IBaseItem | undefined {
    if (!item) return undefined;

    if (item.parentItem) {
      let siteFound: boolean = false;
      let currentParent: IBaseItem | undefined = item.parentItem;

      while (!siteFound) {
        if (!currentParent) break;

        siteFound = isSite(currentParent);

        if (!siteFound) {
          currentParent = currentParent.parentItem;
        }
      }

      if (siteFound) {
        return this._hierarchialDataManager.findItem(
          dataSet,
          (x: IBaseItem) => {
            let siteItem: ISite = x as ISite;
            if (currentParent) {
              return siteItem && siteItem.id === currentParent.id;
            }
            return false;
          }
        );
      }
    }

    let itemUrl: string = "";

    if (isDrive(item)) {
      itemUrl = (item as IDrive).driveUrl;
    } else if (isDriveItem(item)) {
      itemUrl = (item as IDriveItem).webUrl;
    }

    if (itemUrl === "") return undefined;

    return this._hierarchialDataManager.findItem(dataSet, (x: IBaseItem) => {
      let siteItem: ISite = x as ISite;
      const webUrl: string | undefined = siteItem.webUrl;

      if (webUrl) {
        return itemUrl.indexOf(webUrl) >= 0;
      }

      return false;
    });
  }

  /**
   * Returns the RecycleBin from the site.
   * @param siteItem IBaseItem instance representing the site.
   * @returns IBaseItem instance if recycle bin was found, else undefined.
   */
  private GetRecycleBin(siteItem: IBaseItem): IBaseItem | undefined {
    if (siteItem && siteItem.children) {
      const itemIndex: number = siteItem.children.findIndex(
        (child: IBaseItem) => child.id.startsWith("rb")
      );

      return siteItem.children[itemIndex];
    }

    return undefined;
  }

  /**
   * Event called when the user submits the delete dialog  from the DriveItemList component.
   * @param item IBaseItem instance representing the item to remove
   */
  private DriveItemListItemRestoreHandler(
    item: IRecycleBinItem
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.state.loadedContainer !== undefined) {
        // First update the currentItem state to ensure the isUpdating flag is set
        this.setState({
          loadedContainer: this._hierarchialDataManager.setItemAsChild(
            this.state.loadedContainer,
            {
              ...item,
              isDeleting: true,
            }
          ),
          itemModifications: this.enqueueItemModification(
            this.state.itemModifications,
            [item],
            ModificationType.RESTORE
          ),
          showModificationQueuePanel: true,
        });

        this._serviceClient
          .RestoreItemAsync(item.webUrl, item.id)
          .then((error: IErrorResult | void) => {
            if (this.state.loadedContainer === undefined) {
              // This should not be possible
              return;
            }

            if (error) {
              let modifiedState: IAppState = {
                ...this.state,
                loadedContainer: this._hierarchialDataManager.setItemAsChild(
                  this.state.loadedContainer,
                  { ...item, isDeleting: false }
                ),
                itemModifications: this.dequeueItemModification(
                  this.state.itemModifications,
                  [item],
                  ModificationType.RESTORE
                ),
              };

              this.setState(modifiedState, () => {
                resolve();
              });

              this.globalErrorMessageHandler(error as IErrorResult);
            } else {
              let modifiedState: IAppState = {
                ...this.state,
                loadedContainer: this._hierarchialDataManager.removeItemAsChild(
                  this.state.loadedContainer,
                  item
                ),
                itemModifications: this.dequeueItemModification(
                  this.state.itemModifications,
                  [item],
                  ModificationType.RESTORE
                ),
              };

              // Depending on the scope of the dataset, we need to updated different state members
              if (item.rootScope === HierarchialDataScope.OneDrives) {
                modifiedState = {
                  ...modifiedState,
                  onedrives: this._hierarchialDataManager.removeItemFromRoot(
                    this.state.onedrives,
                    item
                  ),
                };
              } else if (
                item.rootScope === HierarchialDataScope.Favorites ||
                item.rootScope === HierarchialDataScope.AllSites
              ) {
                let currentDataSet: IHierarchialDataset =
                  this.GetDataSetOfItem(item);
                let sisterDataSet: IHierarchialDataset =
                  currentDataSet.scope === HierarchialDataScope.Favorites
                    ? this.state.allSites
                    : this.state.favoriteSites;

                const encodedLocationUri: string = encodeURI(
                  `${new URL(item.webUrl).origin}/${item.originalLocation}`
                );

                let parentLocation: IBaseItem | undefined =
                  this.FindDriveOrDriveItem(currentDataSet, encodedLocationUri);
                let parentSisterLocation: IBaseItem | undefined =
                  this.FindDriveOrDriveItem(sisterDataSet, encodedLocationUri);

                let sisterParentSite: IBaseItem | undefined;

                if (parentSisterLocation) {
                  sisterParentSite = this.FindParentSite(
                    sisterDataSet,
                    parentLocation
                  );
                }

                currentDataSet = this._hierarchialDataManager.resetItem(
                  currentDataSet,
                  parentLocation
                );

                if (sisterParentSite) {
                  if (parentSisterLocation && parentSisterLocation.isLoaded) {
                    sisterDataSet = this._hierarchialDataManager.resetItem(
                      sisterDataSet,
                      parentSisterLocation
                    );
                  }

                  const recycleBin: IBaseItem | undefined =
                    this.GetRecycleBin(sisterParentSite);

                  if (recycleBin && recycleBin.isLoaded) {
                    sisterDataSet = this._hierarchialDataManager.resetItem(
                      sisterDataSet,
                      recycleBin
                    );
                  }
                }

                if (item.rootScope === HierarchialDataScope.Favorites) {
                  modifiedState = {
                    ...modifiedState,
                    favoriteSites: currentDataSet,
                    allSites: sisterDataSet,
                  };
                } else if (item.rootScope === HierarchialDataScope.AllSites) {
                  modifiedState = {
                    ...modifiedState,
                    favoriteSites: sisterDataSet,
                    allSites: currentDataSet,
                  };
                }
              }

              this.setState(modifiedState, () => {
                resolve();
              });
            }
          });
      }
    });
  }

  /**
   * Event called when the user submits the delete dialog  from the DriveItemList component.
   * @param item IBaseItem instance representing the item to remove
   */
  private DriveItemListItemDeleteHandler(item: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.state.loadedContainer !== undefined) {
        // First update the currentItem state to ensure the isUpdating flag is set
        this.setState({
          loadedContainer: this._hierarchialDataManager.setItemAsChild(
            this.state.loadedContainer,
            {
              ...item,
              isDeleting: true,
            }
          ),
          itemModifications: this.enqueueItemModification(
            this.state.itemModifications,
            [item],
            ModificationType.REMOVE
          ),
          showModificationQueuePanel: true,
        });

        this._serviceClient
          .DeleteRecycleBinItemAsync(item.driveId)
          .then((error: IErrorResult | void) => {
            if (this.state.loadedContainer === undefined) {
              // This should not be possible
              return;
            }

            if (error !== undefined) {
              let modifiedState: IAppState = {
                ...this.state,
                loadedContainer: this._hierarchialDataManager.setItemAsChild(
                  this.state.loadedContainer,
                  { ...item, isDeleting: false }
                ),
                itemModifications: this.dequeueItemModification(
                  this.state.itemModifications,
                  [item],
                  ModificationType.REMOVE
                ),
              };

              this.setState(modifiedState, () => {
                resolve();
              });

              this.globalErrorMessageHandler(error as IErrorResult);
            } else {
              let modifiedState: IAppState = {
                ...this.state,
                loadedContainer: this._hierarchialDataManager.removeItemAsChild(
                  this.state.loadedContainer,
                  item
                ),
                itemModifications: this.dequeueItemModification(
                  this.state.itemModifications,
                  [item],
                  ModificationType.REMOVE
                ),
              };

              // Depending on the scope of the dataset, we need to updated different state members
              if (item.rootScope === HierarchialDataScope.OneDrives) {
                modifiedState = {
                  ...modifiedState,
                  onedrives: this._hierarchialDataManager.removeItemFromRoot(
                    this.state.onedrives,
                    item
                  ),
                };
              } else if (item.rootScope === HierarchialDataScope.Favorites) {
                modifiedState = {
                  ...modifiedState,
                  favoriteSites:
                    this._hierarchialDataManager.removeItemFromRoot(
                      this.state.favoriteSites,
                      item
                    ),
                };
              } else if (item.rootScope === HierarchialDataScope.AllSites) {
                modifiedState = {
                  ...modifiedState,
                  allSites: this._hierarchialDataManager.removeItemFromRoot(
                    this.state.allSites,
                    item
                  ),
                };
              }

              this.setState(modifiedState, () => {
                resolve();
              });
            }
          });
      }
    });
  }

  /**
   * Event called when an error occurs during the api call via the service client.
   * This function deletes the created dummy item in the driveitemslist and removes
   * the entry from the modification queue
   * @param item IBaseItem instance representing the dummy item
   * @param modificationType Modification type of action for example: ADD
   */
  private DummyItemRemoveHandler(
    item: IBaseItem,
    modificationType: ModificationType
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.state.loadedContainer !== undefined) {
        //console.warn("DummyItem will be removed: ", item);

        if (this.state.loadedContainer !== undefined) {
          let modifiedState: IAppState = {
            ...this.state,
            loadedContainer: this._hierarchialDataManager.removeItemAsChild(
              this.state.loadedContainer,
              item
            ),
            itemModifications: this.dequeueItemModification(
              this.state.itemModifications,
              [item],
              modificationType
            ),
          };

          if (item.rootScope === HierarchialDataScope.OneDrives) {
            modifiedState = {
              ...modifiedState,
              onedrives: this._hierarchialDataManager.removeItemFromRoot(
                this.state.onedrives,
                item
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.Favorites) {
            modifiedState = {
              ...modifiedState,
              favoriteSites: this._hierarchialDataManager.removeItemFromRoot(
                this.state.favoriteSites,
                item
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.AllSites) {
            modifiedState = {
              ...modifiedState,
              allSites: this._hierarchialDataManager.removeItemFromRoot(
                this.state.allSites,
                item
              ),
            };
          }

          this.setState(modifiedState, () => {
            resolve(true);
          });
        }
      }
    });
  }

  /**
   * Event called when the user selects the move file action from the drive item list.
   * @param item IBaseItem instance to move
   */
  private DriveItemListItemMoveHandler(items: IBaseItem[]): void {
    this.setState({
      destinationContext: items,
      copyItems: false,
    });
  }

  /**
   * Event called when the user selects the copy file action from the drive item list.
   * @param item IBaseItem instance to copy
   */
  private DriveItemListItemCopyHandler(items: IBaseItem[]): void {
    this.setState({
      destinationContext: items,
      copyItems: true,
    });
  }

  /**
   * Event called when the user closes the destination dialog
   */
  private DestinationDialogCloseHandler(): void {
    this.setState({
      destinationContext: undefined,
    });
  }

  /**
   * Event called when a IBaseItem needs to be reloaded as requested by the MoveDialog component.
   * @param item IBaseItem to reload
   */
  private ReloadItemRequestHandler(item: IBaseItem): Promise<IBaseItem> {
    return new Promise<IBaseItem>((resolve, reject) => {
      let currentDataSet: IHierarchialDataset = this.GetDataSetOfItem(item);

      this._serviceClient
        .loadItemAsync(currentDataSet, item)
        .then((loadedItem: IBaseItem | IRecycleBin | IErrorResult) => {
          let modifiedState: IAppState = {
            ...this.state,
          };

          // Depending on the scope of the dataset, we need to updated different state members
          if (item.rootScope === HierarchialDataScope.OneDrives) {
            modifiedState = {
              ...modifiedState,
              onedrives: this._hierarchialDataManager.setItem(
                currentDataSet,
                loadedItem as IBaseItem
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.Favorites) {
            modifiedState = {
              ...modifiedState,
              favoriteSites: this._hierarchialDataManager.setItem(
                currentDataSet,
                loadedItem as IBaseItem
              ),
            };
          } else if (item.rootScope === HierarchialDataScope.AllSites) {
            modifiedState = {
              ...modifiedState,
              allSites: this._hierarchialDataManager.setItem(
                currentDataSet,
                loadedItem as IBaseItem
              ),
            };
          }

          this.setState(modifiedState, () => {
            resolve(loadedItem as IBaseItem);
          });
        });
    });
  }

  /**
   * Event called when the user submits the MoveDialog component to actually move the file.
   * @param sourceItem IBaseItem instance representing the item to move.
   * @param targetContainer IBaseItem instance representing the container the item will be moved to.
   */
  private MoveDialogItemClickedHandler(
    sourceItems: IBaseItem[],
    targetContainer: IBaseItem
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.MoveItems(sourceItems, targetContainer).then(() => {
        resolve();
      });
    });
  }

  /**
   * Event called when the user submits the CopyDialog component to actually copy the file.
   * @param sourceItem IBaseItem instance representing the item to copy.
   * @param targetContainer IBaseItem instance representing the container the item will be copied to.
   */
  private CopyDialogItemClickedHandler(
    sourceItems: IBaseItem[],
    targetContainer: IBaseItem
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.CopyItems(sourceItems, targetContainer).then(() => {
        resolve();
      });
    });
  }

  private ActionBarItemCreatingHandler(item: IBaseItem): void {
    this.ActionBarItemsCreatingHandler([item]);
  }

  /**
   * Event called when an one or more items are being created inside the currently loaded container.
   * @param item IBaseItem instance of the new item
   */
  private ActionBarItemsCreatingHandler(items: IBaseItem[]): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.state.loadedContainer === undefined) {
        console.warn(
          "Loaded container is different than the parent of the new item. Possibly the user selected a different item while creating the item very quickly :)."
        );
        return;
      }

      let modifiedState: IAppState = {
        ...this.state,
        isProcessing: true,
        loadedContainer: this._hierarchialDataManager.addItemsAsChildren(
          this.state.loadedContainer,
          items
        ),
        itemModifications: this.enqueueItemModification(
          this.state.itemModifications,
          items,
          ModificationType.ADD
        ),
        showModificationQueuePanel: true,
      };

      if (
        modifiedState.loadedContainer !== undefined &&
        modifiedState.loadedContainer.rootScope ===
          HierarchialDataScope.OneDrives
      ) {
        // Depending on the scope of the dataset, we need to updated different state members
        modifiedState = {
          ...modifiedState,
          onedrives: this._hierarchialDataManager.setItem(
            modifiedState.onedrives,
            modifiedState.loadedContainer as IBaseItem
          ),
        };
      } else if (
        modifiedState.loadedContainer !== undefined &&
        modifiedState.loadedContainer.rootScope ===
          HierarchialDataScope.Favorites
      ) {
        modifiedState = {
          ...modifiedState,
          favoriteSites: this._hierarchialDataManager.setItem(
            modifiedState.favoriteSites,
            modifiedState.loadedContainer as IBaseItem
          ),
        };
      } else if (
        modifiedState.loadedContainer !== undefined &&
        modifiedState.loadedContainer.rootScope ===
          HierarchialDataScope.AllSites
      ) {
        modifiedState = {
          ...modifiedState,
          allSites: this._hierarchialDataManager.setItem(
            modifiedState.allSites,
            modifiedState.loadedContainer as IBaseItem
          ),
        };
      }

      this.setState(modifiedState, () => {
        resolve();
      });
    });
  }

  /**
   * Event called when one or more items are created in the remote endpoint.
   * @param item IBaseItem instance of the new item
   */
  private ActionBarItemCreatedHandler(item: IBaseItem): Promise<void> {
    return new Promise<void>((resolve) => {
      let modifiedState: IAppState = {
        ...this.state,
        isProcessing: false,
        itemModifications: this.dequeueItemModification(
          this.state.itemModifications,
          [item],
          ModificationType.ADD,
          (x: IBaseItem) => {
            return x.referenceId === item.referenceId;
          }
        ),
      };

      if (
        this.state.loadedContainer !== undefined &&
        this.state.loadedContainer.id === item.parentItem.id
      ) {
        modifiedState = {
          ...modifiedState,
          loadedContainer: this._hierarchialDataManager.setItemAsChild(
            this.state.loadedContainer,
            item,
            (x: IBaseItem) => {
              return x.referenceId === item.referenceId;
            }
          ),
        };
      }

      let treeItem: IBaseItem | undefined =
        this._hierarchialDataManager.findItem(
          this.GetDataSetOfItem(item),
          (x: IBaseItem) => {
            return x.referenceId === item.referenceId;
          }
        );

      if (treeItem !== undefined) {
        // Depending on the scope of the dataset, we need to updated different state members
        if (item.rootScope === HierarchialDataScope.OneDrives) {
          modifiedState = {
            ...modifiedState,
            onedrives: this._hierarchialDataManager.setItem(
              this.state.onedrives,
              {
                ...treeItem,
                ...item,
              }
            ),
          };
        } else if (item.rootScope === HierarchialDataScope.Favorites) {
          modifiedState = {
            ...modifiedState,
            favoriteSites: this._hierarchialDataManager.setItem(
              this.state.favoriteSites,
              {
                ...treeItem,
                ...item,
              }
            ),
          };
        } else if (item.rootScope === HierarchialDataScope.AllSites) {
          modifiedState = {
            ...modifiedState,
            allSites: this._hierarchialDataManager.setItem(
              this.state.allSites,
              {
                ...treeItem,
                ...item,
              }
            ),
          };
        }

        this.setState(modifiedState, () => {
          resolve();
        });
      }
    });
  }

  /**
   * Event called when user selects an item in the drive item list
   */
  private DriveItemListItemSelectedHandler(selected: boolean): void {
    if (this.state.loadedContainer === undefined) {
      // this should never happen
      return;
    }

    this.setState({ itemsSelected: selected });
  }

  /**
   * Event called when user pressed refresh button for item list.
   */
  private ActionBarRefreshItemListHandler(): void {
    if (this.state.loadedContainer === undefined) {
      // this should never happen
      return;
    }

    this.treeViewItemSelectedEvent({
      ...this.state.loadedContainer,
      isLoaded: false,
      isExpanded: false,
    });
  }

  /**
   * Event called when user pressed the empty recycle bin action.
   */
  private EmptyRecycleBinHandler(): void {
    if (this.state.loadedContainer !== undefined) {
      this.setState({
        itemModifications: this.enqueueItemModification(
          this.state.itemModifications,
          [this.state.loadedContainer],
          ModificationType.EMPTY
        ),
        showModificationQueuePanel: true,
      });

      const parentItem: ISite = this.state.loadedContainer.parentItem as ISite;

      if (!parentItem) return;

      this._serviceClient
        .EmptyRecycleBinAsync(parentItem.webUrl)
        .then((error: IErrorResult | void) => {
          if (this.state.loadedContainer === undefined) {
            // This should not be possible
            return;
          }

          if (error !== undefined) {
            this.globalErrorMessageHandler(error as IErrorResult);
          } else {
            let currentDataSet: IHierarchialDataset = this.GetDataSetOfItem(
              this.state.loadedContainer
            );

            currentDataSet = this._hierarchialDataManager.resetItem(
              currentDataSet,
              this.state.loadedContainer
            );

            let sisterDataSet: IHierarchialDataset;

            if (currentDataSet.scope === HierarchialDataScope.AllSites) {
              sisterDataSet = this.state.favoriteSites;
            } else {
              sisterDataSet = this.state.allSites;
            }

            let sisterContainer: IBaseItem | undefined =
              this.FindDriveOrDriveItem(
                sisterDataSet,
                this.state.loadedContainer
              );

            if (sisterContainer && sisterContainer.isLoaded) {
              sisterDataSet = this._hierarchialDataManager.resetItem(
                sisterDataSet,
                sisterContainer
              );
            }

            let modifiedState: IAppState = {
              ...this.state,
              itemModifications: this.dequeueItemModification(
                this.state.itemModifications,
                [this.state.loadedContainer],
                ModificationType.EMPTY
              ),
              loadedContainer: {
                ...this.state.loadedContainer,
                children: [],
              },
              showModificationQueuePanel: false,
            };

            if (currentDataSet.scope === HierarchialDataScope.Favorites) {
              modifiedState = {
                ...modifiedState,
                favoriteSites: currentDataSet,
                allSites: sisterDataSet,
              };
            } else if (currentDataSet.scope === HierarchialDataScope.AllSites) {
              modifiedState = {
                ...modifiedState,
                favoriteSites: sisterDataSet,
                allSites: currentDataSet,
              };
            }

            this.setState(modifiedState);
          }
        });
    }
  }

  /**
   * Event called when users restores items from the recylce bin.
   */
  private RestoreRecycleBinItemHandler(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.state.loadedContainer) {
        // this should never happen
        return;
      }

      let selectedItems: IBaseItem[] =
        this.state.loadedContainer.children.filter((item) => {
          return item.isSelected;
        });

      selectedItems.forEach((x: IBaseItem) => {
        x.isDeleting = true;
      });

      // First update the currentItem state to ensure the isUpdating flag is set
      this.setState(
        {
          loadedContainer: this._hierarchialDataManager.setItemsAsChildren(
            this.state.loadedContainer,
            selectedItems
          ),
          itemModifications: this.enqueueItemModification(
            this.state.itemModifications,
            selectedItems,
            ModificationType.RESTORE
          ),
          showModificationQueuePanel: true,
        },
        async () => {
          let modifiedState: IAppState = {
            ...this.state,
          };

          if (!this.state.loadedContainer) {
            return;
          }

          for (
            let taskIndex: number = 0;
            taskIndex < selectedItems.length;
            taskIndex++
          ) {
            let taskError: IErrorResult | any | undefined;

            const taskResult: void | IErrorResult = await this._serviceClient
              .RestoreItemAsync(
                (selectedItems[taskIndex] as IRecycleBinItem).webUrl,
                selectedItems[taskIndex].id
              )
              .catch((exception) => {
                taskError = exception;
              });

            if (taskResult) {
              taskError = taskResult;
            }

            // Endpoint returned an error (IErrorResult) or an unhandled error occured (catch)
            if (taskError) {
              selectedItems = selectedItems.map((x: IBaseItem) => {
                return {
                  ...x,
                  isDeleting: false,
                };
              });

              modifiedState = {
                ...modifiedState,
                loadedContainer:
                  this._hierarchialDataManager.setItemsAsChildren(
                    this.state.loadedContainer,
                    selectedItems
                  ),
                itemModifications: this.dequeueItemModification(
                  modifiedState.itemModifications,
                  selectedItems,
                  ModificationType.RESTORE
                ),
              };

              modifiedState = this.ResetRestoredItemInState(
                modifiedState,
                selectedItems[taskIndex]
              );

              this.setState(modifiedState, () => {
                this.globalErrorMessageHandler(taskError as IErrorResult);
              });

              break; // Don't continue with the other restore actions....
            } else {
              modifiedState = {
                ...modifiedState,
                loadedContainer: this._hierarchialDataManager.removeItemAsChild(
                  this.state.loadedContainer,
                  selectedItems[taskIndex]
                ),
                itemModifications: this.dequeueItemModification(
                  modifiedState.itemModifications,
                  [selectedItems[taskIndex]],
                  ModificationType.RESTORE
                ),
              };

              modifiedState = this.ResetRestoredItemInState(
                modifiedState,
                selectedItems[taskIndex]
              );
            }

            this.setState(modifiedState);
          }
        }
      );
    });
  }

  /**
   * Resets the item when the restore action was performed (or when restore action failed). This to make sure the state allways represents the actual sitation.
   * @param appState Current IAppState instance
   * @param item IBaseItem instance
   * @returns Updated IAppState instance
   */
  private ResetRestoredItemInState(
    appState: IAppState,
    item: IBaseItem
  ): IAppState {
    if (!appState.loadedContainer) return appState;

    let modifiedState: IAppState = {
      ...appState,
    };

    const currentItem: IRecycleBinItem = item as IRecycleBinItem;

    if (
      item.rootScope === HierarchialDataScope.Favorites ||
      item.rootScope === HierarchialDataScope.AllSites
    ) {
      let currentDataSet: IHierarchialDataset = this.GetDataSetOfItem(item);
      let sisterDataSet: IHierarchialDataset =
        currentDataSet.scope === HierarchialDataScope.Favorites
          ? this.state.allSites
          : this.state.favoriteSites;
      const encodedLocationUri: string = encodeURI(
        `${new URL(currentItem.webUrl).origin}/${currentItem.originalLocation}`
      );
      let parentLocation: IBaseItem | undefined = this.FindDriveOrDriveItem(
        currentDataSet,
        encodedLocationUri
      );

      currentDataSet = this.ResetItemInDataSet(parentLocation, currentDataSet);

      let parentSite: IBaseItem | undefined = this.FindParentSite(
        currentDataSet,
        parentLocation
      );

      currentDataSet = this.ResetRecycleBinOfSite(parentSite, currentDataSet);

      let parentSisterLocation: IBaseItem | undefined =
        this.FindDriveOrDriveItem(sisterDataSet, encodedLocationUri);
      let sisterParentSite: IBaseItem | undefined;
      if (parentSisterLocation) {
        sisterParentSite = this.FindParentSite(sisterDataSet, parentLocation);
      }

      sisterDataSet = this.ResetItemInDataSet(
        parentSisterLocation,
        sisterDataSet
      );

      sisterDataSet = this.ResetRecycleBinOfSite(
        sisterParentSite,
        sisterDataSet
      );

      if (currentItem.rootScope === HierarchialDataScope.Favorites) {
        modifiedState = {
          ...modifiedState,
          favoriteSites: currentDataSet,
          allSites: sisterDataSet,
        };
      } else if (currentItem.rootScope === HierarchialDataScope.AllSites) {
        modifiedState = {
          ...modifiedState,
          favoriteSites: sisterDataSet,
          allSites: currentDataSet,
        };
      }
    }

    return modifiedState;
  }

  /**
   * Resets the item in in the dataset if the item was loaded.
   * @param item IBaseItem instance representing the item.
   * @param dataSet IHierarchialDataset instance
   * @returns Updated IHierarchialDataset instance if the item was loaded, else the unchanged IHierarchialDataset instance is returned.
   */
  private ResetItemInDataSet(
    item: IBaseItem | undefined,
    dataSet: IHierarchialDataset
  ): IHierarchialDataset {
    if (item && item.isLoaded) {
      return this._hierarchialDataManager.resetItem(dataSet, item);
    }

    return dataSet;
  }

  /**
   * Resets the recycle bin of the specified site.
   * @param site IBaseItem instance representing the site.
   * @param dataSet IHierarchialDataset instance
   * @returns Updated IHierarchialDataset instance if the recycle bin was loaded, else the unchanged IHierarchialDataset instance is returned.
   */
  private ResetRecycleBinOfSite(
    site: IBaseItem | undefined,
    dataSet: IHierarchialDataset
  ): IHierarchialDataset {
    if (site) {
      const recycleBin: IBaseItem | undefined = this.GetRecycleBin(site);
      if (recycleBin && recycleBin.isLoaded) {
        return this._hierarchialDataManager.resetItem(dataSet, recycleBin);
      }
    }

    return dataSet;
  }

  /**
   * Event called when user pressed delete items button
   */
  private ActionBarDeleteSelectedItemsHandler(
    items?: IBaseItem[]
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.state.loadedContainer === undefined) {
        // this should never happen
        return;
      }

      let selectedItems: IBaseItem[] = [];
      if (items === undefined) {
        selectedItems = this.state.loadedContainer.children.filter((item) => {
          return item.isSelected;
        });
      } else {
        selectedItems = items;
      }

      // First update the currentItem state to ensure the isUpdating flag is set
      this.setState({
        loadedContainer: this._hierarchialDataManager.setItemAsChild(
          this.state.loadedContainer,
          {
            ...selectedItems[0],
            isDeleting: true,
          }
        ),
        itemModifications: this.enqueueItemModification(
          this.state.itemModifications,
          selectedItems,
          ModificationType.REMOVE
        ),
        showModificationQueuePanel: true,
      });

      this._serviceClient
        .DeleteItemsAsync(selectedItems)
        .then((error: IErrorResult | void) => {
          if (this.state.loadedContainer === undefined) {
            // this should never happen
            return;
          }

          if (error) {
            let modifiedState: IAppState = {
              ...this.state,
              loadedContainer: this._hierarchialDataManager.setItemAsChild(
                this.state.loadedContainer,
                { ...selectedItems[0], isDeleting: false }
              ),
              itemModifications: this.dequeueItemModification(
                this.state.itemModifications,
                selectedItems,
                ModificationType.REMOVE
              ),
            };

            this.setState(modifiedState, () => {
              resolve();
            });

            this.globalErrorMessageHandler(error as IErrorResult);
          } else {
            let modifiedState: IAppState = {
              ...this.state,
            };

            selectedItems.forEach((item) => {
              modifiedState = {
                ...modifiedState,
                loadedContainer: this._hierarchialDataManager.removeItemAsChild(
                  modifiedState.loadedContainer as IBaseItem,
                  item
                ),
              };
            });

            modifiedState = {
              ...modifiedState,
              itemModifications: this.dequeueItemModification(
                modifiedState.itemModifications,
                selectedItems,
                ModificationType.REMOVE
              ),
              itemsSelected: false,
            };

            let currentDataSet: IHierarchialDataset = this.GetDataSetOfItem(
              selectedItems[0]
            );
            let sisterDataSet: IHierarchialDataset =
              currentDataSet.scope === HierarchialDataScope.Favorites
                ? this.state.allSites
                : this.state.favoriteSites;

            selectedItems.forEach((item) => {
              currentDataSet = this._hierarchialDataManager.removeItemFromRoot(
                currentDataSet,
                item
              );
            });

            if (
              currentDataSet.scope === HierarchialDataScope.Favorites ||
              currentDataSet.scope === HierarchialDataScope.AllSites
            ) {
              let parentSite: IBaseItem | undefined = this.FindParentSite(
                currentDataSet,
                selectedItems[0]
              );

              let sisterParentSite: IBaseItem | undefined = this.FindParentSite(
                sisterDataSet,
                selectedItems[0]
              );

              if (parentSite) {
                let recycleBin: IBaseItem | undefined =
                  this.GetRecycleBin(parentSite);

                if (recycleBin && recycleBin.isLoaded) {
                  currentDataSet = this._hierarchialDataManager.resetItem(
                    currentDataSet,
                    recycleBin
                  );
                }

                if (sisterParentSite) {
                  let sisterParentLocation: IBaseItem | undefined =
                    this.FindDriveOrDriveItem(
                      sisterDataSet,
                      selectedItems[0].parentItem
                    );

                  if (sisterParentLocation && sisterParentLocation.isLoaded) {
                    sisterDataSet = this._hierarchialDataManager.resetItem(
                      sisterDataSet,
                      sisterParentLocation
                    );
                  }

                  let sisterRecycleBin: IBaseItem | undefined =
                    this.GetRecycleBin(sisterParentSite);

                  if (sisterRecycleBin && sisterRecycleBin.isLoaded) {
                    sisterDataSet = this._hierarchialDataManager.resetItem(
                      sisterDataSet,
                      sisterRecycleBin
                    );
                  }
                }
              }
            }

            switch (currentDataSet.scope) {
              case HierarchialDataScope.OneDrives:
                modifiedState = {
                  ...modifiedState,
                  onedrives: currentDataSet,
                };
                break;

              case HierarchialDataScope.Favorites:
                modifiedState = {
                  ...modifiedState,
                  favoriteSites: currentDataSet,
                  allSites: sisterDataSet,
                };
                break;

              case HierarchialDataScope.AllSites:
                modifiedState = {
                  ...modifiedState,
                  favoriteSites: sisterDataSet,
                  allSites: currentDataSet,
                };
                break;
            }

            this.setState(modifiedState, () => {
              resolve();
            });
          }
        });
    });
  }

  /**
   * Event called when user pressed move items button
   */
  private ActionBarMoveSelectedItemsHandler(): void {
    if (this.state.loadedContainer === undefined) {
      // this should never happen
      return;
    }

    let selectedItems = this.state.loadedContainer.children.filter((item) => {
      return item.isSelected;
    });

    this.DriveItemListItemMoveHandler(selectedItems);
  }

  /**
   * Event called when user pressed copy items button
   */
  private ActionBarCopySelectedItemsHandler(): void {
    if (this.state.loadedContainer === undefined) {
      // this should never happen
      return;
    }

    let selectedItems = this.state.loadedContainer.children.filter((item) => {
      return item.isSelected;
    });

    this.DriveItemListItemCopyHandler(selectedItems);
  }

  /**
   * Event called when an (document) item is created in the remote endpoint.
   * @param item IBaseItem instance of the new (document) item
   */
  private ActionBarDocumentCreatedHandler(item: IBaseItem): void {
    this.ActionBarItemCreatedHandler(item);

    let driveItem: IDriveItem = item as IDriveItem;
    window.open(driveItem.webUrl, "_blank");
  }

  /**
   * Event called when the user submits a keyword in the searchbox
   * @param keyword String containing the search keyword
   */
  private SearchBoxItemChangedHandler(keyword: string): void {
    // Root web url like: https://iexpertsnl.sharepoint.com
    const rootWebUrl: string = this.state.rootWebUrl;
    if (rootWebUrl.length > 0) {
      const url: string = `${rootWebUrl}/_layouts/15/sharepoint.aspx?q=${keyword}&v=%2Fsearch%2Ffiles`;
      window.open(url);
    } else console.error("Invalid root web url (empty string)");
  }

  /**
   * Moves the passed source IBaseItems to the new parent IBaseItem.
   * @param sourceItems Source IBaseItem instance
   * @param targetContainer Target IBaseItem instance
   */
  private MoveItems(
    sourceItems: IBaseItem[],
    targetContainer: IBaseItem
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let moveAction: Promise<IDriveItemResult[]>;

      if (this.state.loadedContainer === undefined) return;

      if (sourceItems[0].parentItem.id === targetContainer.id) {
        const dialogTitle: string = Localization.getTranslation(
          "App",
          "moveFileMessageDialogTitle",
          this.state.language
        );

        const dialogMessage: string = Localization.getTranslation(
          "App",
          "moveFileMessageDialogSourceAndTargetAreEqualMessage",
          this.state.language
        );

        const messageDialogContext: IMessageDialogContext = {
          dialogTitle: dialogTitle,
          message: dialogMessage,
          dialogType: MessageDialogType.Close,
        };

        this.showMessageDialog(messageDialogContext);

        resolve();
        return;
      }

      // Mark item as being moved and populate the queue
      let modifiedState: IAppState = {
        ...this.state,
      };

      sourceItems.forEach((item) => {
        modifiedState = {
          ...modifiedState,
          loadedContainer: this._hierarchialDataManager.setItemAsChild(
            modifiedState.loadedContainer as IBaseItem,
            {
              ...item,
              isMoving: true,
            }
          ),
        };
      });

      modifiedState = {
        ...modifiedState,
        itemModifications: this.enqueueItemModification(
          modifiedState.itemModifications,
          sourceItems,
          ModificationType.MOVE
        ),
        showModificationQueuePanel: true,
      };

      // Move item
      this.setState(modifiedState, () => {
        // When a folder is moved to the root, the targetItem doesn't contain a driveId member (because it is a IDrive instance instead of a IDriveItem).
        // In this case we need to specify different parameter values for the move action

        let destinationDriveId: string;
        let destinationItemId: string;

        if (isDrive(targetContainer)) {
          destinationDriveId = targetContainer.id;
          destinationItemId = "";
        } else {
          destinationDriveId = (targetContainer as IDriveItem).driveId;
          destinationItemId = targetContainer.id;
        }

        moveAction = this._serviceClient.MoveDriveItems(
          sourceItems[0] as IDriveItem,
          sourceItems.map((x) => {
            return x.id;
          }),
          sourceItems.map((x) => {
            return x.displayName;
          }),
          sourceItems.map((x) => {
            return x.referenceId as string;
          }),
          destinationDriveId,
          destinationItemId,
          targetContainer.path ? targetContainer.path : "",
          targetContainer.rootScope === HierarchialDataScope.OneDrives
        );

        // Now actually move the file using the remove endpoint
        moveAction
          .then((itemResult: IDriveItemResult[]): void => {
            // Check if there are any errors
            const errorItems: IDriveItemResult[] = itemResult.filter(
              (x) => x.copyAndDeleteResult === false
            );

            let sourceDataSet: IHierarchialDataset = this.GetDataSetOfItem(
              sourceItems[0]
            );

            // There are no errors, removed the source item from the grid and hierarchy
            if (errorItems.length === 0) {
              sourceItems.forEach((sourceItem) => {
                if (this.state.loadedContainer) {
                  modifiedState = {
                    ...modifiedState,
                    loadedContainer:
                      this._hierarchialDataManager.removeItemAsChild(
                        {
                          ...(modifiedState.loadedContainer as IBaseItem),
                          isLoading: false,
                        },
                        sourceItem
                      ),
                    destinationContext: undefined,
                    itemsSelected: false,
                  };
                }

                sourceDataSet = this._hierarchialDataManager.removeItemFromRoot(
                  sourceDataSet,
                  sourceItem
                );

                // First remove the source item from the tree and call setState to update components
                if (sourceDataSet.scope === HierarchialDataScope.OneDrives) {
                  modifiedState = {
                    ...modifiedState,
                    onedrives: sourceDataSet,
                  };
                } else if (
                  sourceDataSet.scope == HierarchialDataScope.Favorites ||
                  sourceDataSet.scope == HierarchialDataScope.AllSites
                ) {
                  let sisterDataSet: IHierarchialDataset =
                    sourceItem.rootScope === HierarchialDataScope.AllSites
                      ? modifiedState.favoriteSites
                      : modifiedState.allSites;

                  let sisterSourceContainer: IBaseItem | undefined =
                    this.FindDriveOrDriveItem(
                      sisterDataSet,
                      sourceItem.parentItem
                    );
                  if (sisterSourceContainer && sisterSourceContainer.isLoaded) {
                    sisterDataSet = this._hierarchialDataManager.resetItem(
                      sisterDataSet,
                      sisterSourceContainer
                    );
                  }

                  if (sourceItem.rootScope === HierarchialDataScope.Favorites) {
                    modifiedState = {
                      ...modifiedState,
                      favoriteSites: sourceDataSet,
                      allSites: sisterDataSet,
                    };
                  } else if (
                    sourceItem.rootScope === HierarchialDataScope.AllSites
                  ) {
                    modifiedState = {
                      ...modifiedState,
                      favoriteSites: sisterDataSet,
                      allSites: sourceDataSet,
                    };
                  }
                }
              });

              modifiedState = {
                ...modifiedState,
                itemModifications: this.dequeueItemModification(
                  modifiedState.itemModifications,
                  sourceItems,
                  ModificationType.MOVE
                ),
              };
            } else {
              // There are errors
              const message: string = Localization.getTranslation(
                "App",
                "moveFileFailedMessageResult",
                this.state.language
              );

              const messageBarContext: IMessageBarContext = {
                message: message,
                type: MessageBarType.Warning,
              };

              // Revert the situation in the source container
              sourceItems.forEach((sourceItem) => {
                if (this.state.loadedContainer !== undefined) {
                  modifiedState = {
                    ...modifiedState,
                    loadedContainer:
                      this._hierarchialDataManager.setItemAsChild(
                        modifiedState.loadedContainer as IBaseItem,
                        { ...sourceItem, isMoving: false }
                      ),
                    messageBarContext: messageBarContext,
                  };
                }
              });

              modifiedState = {
                ...modifiedState,
                itemModifications: this.dequeueItemModification(
                  modifiedState.itemModifications,
                  sourceItems,
                  ModificationType.MOVE
                ),
                showModificationQueuePanel: false,
                destinationContext: undefined,
              };
            }

            this.setState(modifiedState, () => {
              let targetDataSet: IHierarchialDataset =
                this.GetDataSetOfItem(targetContainer);

              targetDataSet = this._hierarchialDataManager.resetItem(
                targetDataSet,
                targetContainer
              );

              let sisterDataSet: IHierarchialDataset | undefined;

              // Note; target dataset is allways the AllSites, as the move/copy panel only offers the AllSites dataset to pick from
              if (targetDataSet.scope === HierarchialDataScope.AllSites) {
                sisterDataSet = this.state.favoriteSites;

                let sisterContainer: IBaseItem | undefined =
                  this.FindDriveOrDriveItem(sisterDataSet, targetContainer);

                if (sisterContainer && sisterContainer.isLoaded) {
                  sisterDataSet = this._hierarchialDataManager.resetItem(
                    sisterDataSet,
                    sisterContainer
                  );
                }
              }

              modifiedState = {
                ...this.state,
              };

              if (
                this.state.loadedContainer &&
                this._hierarchialDataManager.itemDescendantOf(
                  this.state.loadedContainer,
                  targetContainer
                )
              ) {
                modifiedState = {
                  ...modifiedState,
                  loadedContainer:
                    this._hierarchialDataManager.resetItemInContainer(
                      this.state.loadedContainer,
                      targetContainer
                    ),
                };
              }

              switch (targetDataSet.scope) {
                case HierarchialDataScope.OneDrives:
                  modifiedState = {
                    ...modifiedState,
                    onedrives: targetDataSet,
                  };
                  break;
                case HierarchialDataScope.AllSites:
                  if (sisterDataSet) {
                    modifiedState = {
                      ...modifiedState,
                      favoriteSites: sisterDataSet,
                    };
                  }

                  modifiedState = {
                    ...modifiedState,
                    allSites: targetDataSet,
                  };
                  break;
              }

              this.setState(modifiedState, () => {
                resolve();
              });
            });
          })
          .catch((error: IErrorResult): void => {
            this.globalErrorMessageHandler(error);

            // Revert the situation.
            let modifiedState: IAppState = {
              ...this.state,
            };
            sourceItems.forEach((item) => {
              if (this.state.loadedContainer !== undefined) {
                modifiedState = {
                  ...modifiedState,
                  loadedContainer: this._hierarchialDataManager.setItemAsChild(
                    this.state.loadedContainer,
                    { ...item, isMoving: false }
                  ),
                };

                let itemName: string = item.displayName;
                const length: number = itemName.length;

                if (length > 64) {
                  itemName =
                    itemName.substr(0, 32) +
                    " ... " +
                    itemName.substring(length - 32);
                }

                this.setState(modifiedState, () => {
                  resolve();
                });
              }
            });

            modifiedState = {
              ...modifiedState,
              itemModifications: this.dequeueItemModification(
                modifiedState.itemModifications,
                sourceItems,
                ModificationType.MOVE
              ),
            };

            modifiedState.showModificationQueuePanel =
              modifiedState.itemModifications.length >= 1;

            this.setState(modifiedState);
          });
      });
    });
  }

  /**
   * Copies the passed source IBaseItem to the new parent IBaseItem.
   * @param sourceItems Source IBaseItem instance
   * @param targetContainer Target IBaseItem instance
   */
  private CopyItems(
    sourceItems: IBaseItem[],
    targetContainer: IBaseItem
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let copyAction: Promise<string[]>;

      if (this.state.loadedContainer === undefined) return;

      if (sourceItems[0].parentItem.id === targetContainer.id) {
        const dialogTitle: string = Localization.getTranslation(
          "App",
          "copyFileMessageDialogTitle",
          this.state.language
        );

        const dialogMessage: string = Localization.getTranslation(
          "App",
          "copyFileMessageDialogSourceAndTargetAreEqualMessage",
          this.state.language
        );

        const messageDialogContext: IMessageDialogContext = {
          dialogTitle: dialogTitle,
          message: dialogMessage,
          dialogType: MessageDialogType.Close,
        };

        this.showMessageDialog(messageDialogContext);

        resolve();
        return;
      }

      // Mark item as being copied and populate the queue
      let modifiedState: IAppState = {
        ...this.state,
        showModificationQueuePanel: true,
        itemModifications: this.enqueueItemModification(
          this.state.itemModifications,
          sourceItems,
          ModificationType.COPY
        ),
      };

      sourceItems.forEach((item) => {
        modifiedState = {
          ...modifiedState,
          loadedContainer: this._hierarchialDataManager.setItemAsChild(
            modifiedState.loadedContainer as IBaseItem,
            {
              ...item,
              isMoving: false,
            }
          ),
          showModificationQueuePanel: true,
        };
      });

      // modifiedState = {
      //   ...modifiedState,
      //   showModificationQueuePanel: true,
      //   itemModifications: this.enqueueItemModification(
      //     modifiedState.itemModifications,
      //     sourceItems,
      //     ModificationType.COPY
      //   ),
      // };

      // Copy item
      this.setState(modifiedState, () => {
        // When a folder is copied to the root, the targetItem doesn't contain a driveId member (because it is a IDrive instance instead of a IDriveItem).
        // In this case we need to specify different parameter values for the copy action
        let destinationDriveId: string;
        let destinationItemId: string;

        if (isDrive(targetContainer)) {
          destinationDriveId = targetContainer.id;
          destinationItemId = "";
        } else {
          destinationDriveId = (targetContainer as IDriveItem).driveId;
          destinationItemId = targetContainer.id;
        }

        copyAction = this._serviceClient.CopyDriveItems(
          sourceItems[0] as IDriveItem,
          sourceItems.map((x) => {
            return x.id;
          }),
          sourceItems.map((x) => {
            return x.displayName;
          }),
          destinationDriveId,
          destinationItemId,
          targetContainer.path ? targetContainer.path : "",
          targetContainer.rootScope === HierarchialDataScope.OneDrives
        );

        // Now actually copy the file using the remove endpoint
        copyAction
          .then((urls: string[]): void => {
            modifiedState = {
              ...modifiedState,
              itemModifications: this.setMonitorUrlsForItemModifications(
                modifiedState.itemModifications,
                sourceItems.length,
                ModificationType.COPY,
                urls
              ),
              destinationContext: undefined,
              itemsSelected: false,
            };

            this.setState(modifiedState, () => {
              let targetDataSet: IHierarchialDataset =
                this.GetDataSetOfItem(targetContainer);

              targetDataSet = this._hierarchialDataManager.resetItem(
                targetDataSet,
                targetContainer
              );

              let sisterDataSet: IHierarchialDataset | undefined;

              // Note; target dataset is allways the AllSites, as the move/copy panel only offers the AllSites dataset to pick from
              if (targetDataSet.scope === HierarchialDataScope.AllSites) {
                sisterDataSet = this.state.favoriteSites;

                let sisterContainer: IBaseItem | undefined =
                  this.FindDriveOrDriveItem(sisterDataSet, targetContainer);

                if (sisterContainer && sisterContainer.isLoaded) {
                  sisterDataSet = this._hierarchialDataManager.resetItem(
                    sisterDataSet,
                    sisterContainer
                  );
                }
              }

              modifiedState = {
                ...this.state,
              };

              modifiedState = {
                ...this.state,
              };

              if (
                this.state.loadedContainer &&
                this._hierarchialDataManager.itemDescendantOf(
                  this.state.loadedContainer,
                  targetContainer
                )
              ) {
                modifiedState = {
                  ...modifiedState,
                  loadedContainer:
                    this._hierarchialDataManager.resetItemInContainer(
                      this.state.loadedContainer,
                      targetContainer
                    ),
                };
              }

              switch (targetDataSet.scope) {
                case HierarchialDataScope.OneDrives:
                  modifiedState = {
                    ...modifiedState,
                    onedrives: targetDataSet,
                  };
                  break;
                case HierarchialDataScope.AllSites:
                  if (sisterDataSet) {
                    modifiedState = {
                      ...modifiedState,
                      favoriteSites: sisterDataSet,
                    };
                  }

                  modifiedState = {
                    ...modifiedState,
                    allSites: targetDataSet,
                  };
                  break;
              }

              this.setState(modifiedState, () => {
                resolve();
              });
            });
          })
          .catch((error: IErrorResult): void => {
            this.globalErrorMessageHandler(error);

            // Revert the situation.
            let modifiedState: IAppState = {
              ...this.state,
            };

            sourceItems.forEach((item) => {
              if (this.state.loadedContainer !== undefined) {
                modifiedState = {
                  ...modifiedState,
                  loadedContainer: this._hierarchialDataManager.setItemAsChild(
                    this.state.loadedContainer,
                    { ...item, isMoving: false }
                  ),
                };

                let itemName: string = item.displayName;
                const length: number = itemName.length;

                if (length > 64) {
                  itemName =
                    itemName.substr(0, 32) +
                    " ... " +
                    itemName.substring(length - 32);
                }

                this.setState(modifiedState, () => {
                  resolve();
                });
              }
            });

            modifiedState = {
              ...modifiedState,
              itemModifications: this.dequeueItemModification(
                modifiedState.itemModifications,
                sourceItems,
                ModificationType.COPY
              ),
            };
          });
      });
    });
  }

  private ActionBarCreateDocumentHandler(body: any): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      return this._serviceClient
        .CreateDocument(body)
        .then((result: IDriveItem) => {
          resolve(result);
        })
        .catch((error: IErrorResult) => {
          this.globalErrorMessageHandler(error);
          reject();
        });
    });
  }

  private ActionBarCreateFolderHandler(body: any): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      return this._serviceClient
        .CreateFolder(body)
        .then((result: IDriveItem) => {
          resolve(result);
        })
        .catch((error: IErrorResult) => {
          this.globalErrorMessageHandler(error);
          reject();
        });
    });
  }

  private ActionBarCreateFileHandler(formData: FormData): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      return this._serviceClient
        .CreateFile(formData)
        .then((result: IDriveItem) => {
          resolve(result);
        })
        .catch((error: IErrorResult) => {
          this.globalErrorMessageHandler(error);
          reject();
        });
    });
  }

  private treeViewItemDroppedHandler(
    sourceItems: IBaseItem[],
    targetContainer: IBaseItem
  ): void {
    this.MoveItems(sourceItems, targetContainer);
  }

  private logoutButtonClickedHandler(e: any) {
    e.preventDefault();
    authContext.signOut();
  }

  private showMessageBar(message: string, messageBarType: MessageBarType) {
    this.setState({
      messageBarContext: {
        message: message,
        type: messageBarType,
      },
    });
  }

  private showMessageBarJSXFormed(
    message: JSX.Element,
    messageBarType: MessageBarType
  ) {
    this.setState({
      messageBarContext: {
        messageJSXFormed: message,
        type: messageBarType,
      },
    });
  }

  private showMessageDialog(messageDialogContext: IMessageDialogContext) {
    this.setState({
      messageDialogContext: messageDialogContext,
    });
  }

  private handleCloseMessageBar() {
    this.setState({ messageBarContext: undefined });
  }

  private handleCloseMessageDialog() {
    this.setState({ messageDialogContext: undefined });
  }

  private handleConfirmMessageDialog() {
    // Nothing yet to handle
  }

  private globalErrorMessageHandler(errorResult: IErrorResult): void {
    let error: IError = Localization.getError(this.state.language, errorResult);

    switch (error.errorVisibility) {
      case ErrorVisibility.GlobalDialog:
        let messageDialogContext: IMessageDialogContext = {
          dialogTitle: this.props.i18n.errorDialogTitle,
          dialogType: MessageDialogType.Close,
          message: `${error.message}<br /><br />${error.correlationId}`,
        };
        this.setState({ messageDialogContext: messageDialogContext });
        break;

      case ErrorVisibility.MessageBar:
        let messageBarContext: IMessageBarContext = {
          message: error.message,
          type: MessageBarType.Error,
        };
        this.setState({ messageBarContext: messageBarContext });
        break;

      default:
        break;
    }
  }

  private handleItemModificationQueueClose = () => {
    this.setState({ showModificationQueuePanel: false });
  };

  private handleMonitorProgress(monitorUrl: string): Promise<IOperationStatus> {
    return new Promise<IOperationStatus>((resolve, reject) => {
      return this._serviceClient
        .CheckOperationStatusAsync(monitorUrl)
        .then((status: IOperationStatus) => {
          resolve(status);
        })
        .catch((error: IErrorResult) => {
          this.globalErrorMessageHandler(error);
          reject();
        });
    });
  }

  private handleStatusComplete(item: any): void {
    this.setState({
      itemModifications: this.dequeueItemModification(
        this.state.itemModifications,
        item.items,
        item.type
      ),
    });
  }

  private CreateShareLinkHandler(
    item: IDriveItem,
    siteOrOneDriveUrl: string,
    driveIsOneDrive: boolean
  ): Promise<ISharingLinkData> {
    return new Promise<ISharingLinkData>((resolve, reject) => {
      return this._serviceClient
        .CreateShareLink(item, siteOrOneDriveUrl, driveIsOneDrive)
        .then((result: ISharingLinkData) => {
          resolve(result);
        })
        .catch((error: IErrorResult) => {
          this.globalErrorMessageHandler(error);
          reject();
        });
    });
  }

  private OpenEasyTemplate() {
    this.setState({ showEasyTemplateDialog: true });
  }

  private CloseEasyTemplate() {
    this.setState(
      { showEasyTemplateDialog: false },
      this.ActionBarRefreshItemListHandler.bind(this)
    );
  }

  public render() {
    if (!this.state.isInitialized) {
      return <LoadingIndicator message="" />;
    }

    if (!this.props.context.hasProductLicense) {
      return (
        <LanguageContext.Provider value={this.state.language}>
          <TenantInvalidMessage
            organizationData={this.state.organizationData}
            activeUser={this.state.user}
            onSignOut={this.logoutButtonClickedHandler.bind(this)}
          />
        </LanguageContext.Provider>
      );
    }

    let contents: JSX.Element = <div></div>;

    if (this.state.isLoadingContainer) {
      contents = <DataLoadingSpinner />;
    } else if (
      this.state.loadedContainer === undefined ||
      isSite(this.state.loadedContainer)
    ) {
      let message: string | undefined = undefined,
        messageType: MessageBarType | undefined = undefined;

      // Depending on the state value, we show different messages using the MessageBar component
      if (this.state.loadedContainer === undefined) {
        message = Localization.getTranslation(
          "App",
          "noTreeviewItemSelectedMessage",
          this.state.language
        );
        messageType = MessageBarType.Warning;
      } else if (isSite(this.state.loadedContainer)) {
        message = Localization.getTranslation(
          "App",
          "noContentsToShowForSites",
          this.state.language
        );
        messageType = MessageBarType.Info;
      }

      if (message !== undefined && messageType !== undefined) {
        contents = (
          <div className="mt-2 mb-2">
            <MessageBar
              messageContext={{
                message: message,
                type: messageType,
              }}
            />
          </div>
        );
      }
    } else {
      contents = (
        <div>
          <ActionBar
            loadedContainer={this.state.loadedContainer}
            itemsSelected={this.state.itemsSelected}
            language={this.state.language}
            RefreshItemListEvent={this.ActionBarRefreshItemListHandler.bind(
              this
            )}
            DeleteSelectedItemsEvent={this.ActionBarDeleteSelectedItemsHandler.bind(
              this
            )}
            EmptyRecycleBinEvent={this.EmptyRecycleBinHandler.bind(this)}
            RestoreRecycleBinItemEvent={this.RestoreRecycleBinItemHandler.bind(
              this
            )}
            MoveSelectedItemsEvent={this.ActionBarMoveSelectedItemsHandler.bind(
              this
            )}
            CopySelectedItemsEvent={this.ActionBarCopySelectedItemsHandler.bind(
              this
            )}
            DocumentCreationEvent={this.ActionBarCreateDocumentHandler.bind(
              this
            )}
            DocumentCreatingEvent={this.ActionBarItemCreatingHandler.bind(this)}
            DocumentCreatedEvent={this.ActionBarDocumentCreatedHandler.bind(
              this
            )}
            FolderCreationEvent={this.ActionBarCreateFolderHandler.bind(this)}
            FolderCreatingEvent={this.ActionBarItemCreatingHandler.bind(this)}
            FolderCreatedEvent={this.ActionBarItemCreatedHandler.bind(this)}
            FileCreationEvent={this.ActionBarCreateFileHandler.bind(this)}
            FilesAddingEvent={this.ActionBarItemsCreatingHandler.bind(this)}
            FileAddedEvent={this.ActionBarItemCreatedHandler.bind(this)}
            DummyFileRemoveEvent={this.DummyItemRemoveHandler.bind(this)}
            showMessageBar={this.showMessageBar.bind(this)}
            closeMessageBar={this.handleCloseMessageBar.bind(this)}
            EasyTemplateOpenEvent={this.OpenEasyTemplate.bind(this)}
            allowShowEasyTemplate={this.state.easyTemplateIsLicensed}
          />
          {this.state.messageBarContext !== undefined && (
            <MessageBar
              messageContext={this.state.messageBarContext}
              onClose={this.handleCloseMessageBar.bind(this)}
            />
          )}
          {this.state.loadedContainer !== undefined &&
            !isSite(this.state.loadedContainer) && (
              <DriveItemsList
                loadedContainer={this.state.loadedContainer}
                language={this.state.language}
                itemClickedEvent={this.DriveItemListFolderClickedHandler.bind(
                  this
                )}
                itemRemoveEvent={this.ActionBarDeleteSelectedItemsHandler.bind(
                  this
                )}
                itemRestoreEvent={this.DriveItemListItemRestoreHandler.bind(
                  this
                )}
                itemDeleteEvent={this.DriveItemListItemDeleteHandler.bind(this)}
                itemRenameEvent={this.DriveItemListItemRenameHandler.bind(this)}
                itemMoveEvent={this.DriveItemListItemMoveHandler.bind(this)}
                itemCopyEvent={this.DriveItemListItemCopyHandler.bind(this)}
                itemDroppedEvent={this.treeViewItemDroppedHandler.bind(this)}
                itemCreationEvent={this.ActionBarCreateFileHandler.bind(this)}
                itemsCreatingEvent={this.ActionBarItemsCreatingHandler.bind(
                  this
                )}
                itemCreatedEvent={this.ActionBarItemCreatedHandler.bind(this)}
                itemsSelectedEvent={this.DriveItemListItemSelectedHandler.bind(
                  this
                )}
                DummyFileRemoveEvent={this.DummyItemRemoveHandler.bind(this)}
                showMessageBar={this.showMessageBar.bind(this)}
                showMessageBarJSXFormed={this.showMessageBarJSXFormed.bind(
                  this
                )}
                closeMessageBar={this.handleCloseMessageBar.bind(this)}
                createShareLink={this.CreateShareLinkHandler.bind(this)}
              />
            )}
          {this.state.copyItems === false && this.state.destinationContext && (
            <PickDestinationDialog
              oneDrives={this.state.onedrives}
              sites={this.state.allSites}
              items={this.state.destinationContext}
              modificationType={ModificationType.MOVE}
              onMoveItemClickedEvent={this.MoveDialogItemClickedHandler.bind(
                this
              )}
              onCopyItemClickedEvent={this.CopyDialogItemClickedHandler.bind(
                this
              )}
              reloadItemRequestEvent={this.ReloadItemRequestHandler.bind(this)}
              onPanelCloseEvent={this.DestinationDialogCloseHandler.bind(this)}
            />
          )}
          {this.state.copyItems === true && this.state.destinationContext && (
            <PickDestinationDialog
              oneDrives={this.state.onedrives}
              sites={this.state.allSites}
              items={this.state.destinationContext}
              modificationType={ModificationType.COPY}
              onMoveItemClickedEvent={this.MoveDialogItemClickedHandler.bind(
                this
              )}
              onCopyItemClickedEvent={this.CopyDialogItemClickedHandler.bind(
                this
              )}
              reloadItemRequestEvent={this.ReloadItemRequestHandler.bind(this)}
              onPanelCloseEvent={this.DestinationDialogCloseHandler.bind(this)}
            />
          )}
        </div>
      );
    }

    return (
      <>
        {this.state.isInitialized && (
          <LanguageContext.Provider value={this.state.language}>
            <div
              className={this.state.isProcessing ? "App is-processing" : "App"}
            >
              {this.state.showModificationQueuePanel &&
                this.state.itemModifications.length > 0 && (
                  <ItemModificationQueue
                    modifications={this.state.itemModifications}
                    actionCompletedEvent={() => {}}
                    actionFailedEvent={() => {}}
                    onClose={this.handleItemModificationQueueClose.bind(this)}
                    onMonitorProgressEvent={this.handleMonitorProgress.bind(
                      this
                    )}
                    onStatusCompleteEvent={this.handleStatusComplete.bind(this)}
                  />
                )}

              <div className="app-container">
                <div className="header">
                  <div className="top bg-greenGradient">
                    <div className="app-title text-white text-left align-self-center">
                      File Explorer
                    </div>
                    <div className="app-menu pr-0">
                      <CommandBar
                        user={this.state.user}
                        language={this.state.language}
                        onSignOut={this.logoutButtonClickedHandler.bind(this)}
                        onSearch={this.SearchBoxItemChangedHandler.bind(this)}
                        showMessageDialog={this.showMessageDialog.bind(this)}
                      ></CommandBar>
                    </div>
                  </div>
                  <div className="navigation">
                    <div className="row">
                      <div className="col topNavigation">
                        <TopNavigationBar
                          items={this.state.navigationStack}
                          navigationChanged={this.navigationStackClicked.bind(
                            this
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main">
                  <Resizable
                    className="navigator"
                    defaultSize={{
                      width: AppSettingsProvider.defaults().navigatorWidth,
                      height: "100%",
                    }}
                    enable={{ right: true }}
                    bounds="parent"
                    onResizeStop={(
                      event: MouseEvent | TouchEvent,
                      direction,
                      elementRef,
                      delta
                    ) => {
                      if (this.state.user && this._appSettings) {
                        AppSettingsProvider.set(this.state.user.id, {
                          ...this._appSettings,
                          navigatorWidth:
                            this._appSettings.navigatorWidth + delta.width,
                        });
                      }
                    }}
                  >
                    <div className="navigator-container">
                      {this.state.onedrives.children.length !== 0 && (
                        <TreeViewNavigator
                          loadedContainer={this.state.loadedContainer}
                          language={this.state.language}
                          data={this.state.onedrives}
                          enableContextMenu={window.location.search
                            .substring(1)
                            .startsWith("debug=true")}
                          itemExpandedChangeEvent={this.treeViewItemExpandedEvent.bind(
                            this
                          )}
                          itemSelectedEvent={this.treeViewItemSelectedEvent.bind(
                            this
                          )}
                          itemContextMenuClickedEvent={this.treeViewItemContextMenuClickedEvent.bind(
                            this
                          )}
                          itemDroppedEvent={this.treeViewItemDroppedHandler.bind(
                            this
                          )}
                          itemCreationEvent={this.ActionBarCreateFileHandler.bind(
                            this
                          )}
                          itemsCreatingEvent={this.ActionBarItemsCreatingHandler.bind(
                            this
                          )}
                          itemCreatedEvent={this.ActionBarItemCreatedHandler.bind(
                            this
                          )}
                          DummyFileRemoveEvent={this.DummyItemRemoveHandler.bind(
                            this
                          )}
                          showMessageBar={this.showMessageBar.bind(this)}
                          globalErrorMessageEvent={this.globalErrorMessageHandler.bind(
                            this
                          )}
                        />
                      )}
                      <TreeViewNavigator
                        loadedContainer={this.state.loadedContainer}
                        language={this.state.language}
                        data={this.state.favoriteSites}
                        enableContextMenu={true}
                        itemExpandedChangeEvent={this.treeViewItemExpandedEvent.bind(
                          this
                        )}
                        itemSelectedEvent={this.treeViewItemSelectedEvent.bind(
                          this
                        )}
                        itemContextMenuClickedEvent={this.treeViewItemContextMenuClickedEvent.bind(
                          this
                        )}
                        itemDroppedEvent={this.treeViewItemDroppedHandler.bind(
                          this
                        )}
                        itemCreationEvent={this.ActionBarCreateFileHandler.bind(
                          this
                        )}
                        itemsCreatingEvent={this.ActionBarItemsCreatingHandler.bind(
                          this
                        )}
                        itemCreatedEvent={this.ActionBarItemCreatedHandler.bind(
                          this
                        )}
                        DummyFileRemoveEvent={this.DummyItemRemoveHandler.bind(
                          this
                        )}
                        showMessageBar={this.showMessageBar.bind(this)}
                        globalErrorMessageEvent={this.globalErrorMessageHandler.bind(
                          this
                        )}
                      />
                      <TreeViewNavigator
                        loadedContainer={this.state.loadedContainer}
                        language={this.state.language}
                        data={this.state.allSites}
                        enableContextMenu={true}
                        itemExpandedChangeEvent={this.treeViewItemExpandedEvent.bind(
                          this
                        )}
                        itemSelectedEvent={this.treeViewItemSelectedEvent.bind(
                          this
                        )}
                        itemContextMenuClickedEvent={this.treeViewItemContextMenuClickedEvent.bind(
                          this
                        )}
                        itemDroppedEvent={this.treeViewItemDroppedHandler.bind(
                          this
                        )}
                        itemCreationEvent={this.ActionBarCreateFileHandler.bind(
                          this
                        )}
                        itemsCreatingEvent={this.ActionBarItemsCreatingHandler.bind(
                          this
                        )}
                        itemCreatedEvent={this.ActionBarItemCreatedHandler.bind(
                          this
                        )}
                        DummyFileRemoveEvent={this.DummyItemRemoveHandler.bind(
                          this
                        )}
                        showMessageBar={this.showMessageBar.bind(this)}
                        globalErrorMessageEvent={this.globalErrorMessageHandler.bind(
                          this
                        )}
                      />
                    </div>
                  </Resizable>

                  <div className="content">{contents}</div>
                </div>
                <div className="footer bg-greenGradient">
                  <div className="row">
                    <div className="col text-left text-white align-self-center">
                      {this.props.context.metadata.Version}{" "}
                      {this.props.context.metadata.Sprint}.
                      {this.props.context.metadata.Build}
                    </div>
                    <div className="col text-right text-white align-self-center text-nowrap">
                      <span className="mr-4">&copy; 2021 File Explorer</span>
                      <span className="mr-2">Powered by</span>
                      <a
                        href="https://www.i-experts.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="images/logo-white.png" height="35" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.messageDialogContext !== undefined && (
              <MessageDialog
                showDialog={true}
                messageContext={this.state.messageDialogContext}
                onCloseEvent={this.handleCloseMessageDialog.bind(this)}
                onButtonConfirmClickedEvent={this.handleConfirmMessageDialog.bind(
                  this
                )}
              />
            )}
            {this.state.showEasyTemplateDialog && (
              <EasyTemplateDialog
                serviceClient={this._serviceClient}
                onCloseEvent={this.CloseEasyTemplate.bind(this)}
                activeItem={this.state.loadedContainer}
              />
            )}
          </LanguageContext.Provider>
        )}
      </>
    );
  }
}

export default Localization.translateComponent<IAppProps, IAppState>("App")(
  App
);
