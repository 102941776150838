import * as msal from "@azure/msal-browser";

const defaults: msal.Configuration = {
  auth: {
    clientId: "", // This needs to be set before creating the msal application
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const getConfig = (clientId: string): msal.Configuration => {
  return {
    ...defaults,
    auth: {
      clientId: clientId,
    },
  };
};
