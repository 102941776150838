import * as React from "react";
import LoadingIndicator from "./LoadingIndicator";
import * as Localization from "./Translate";
import { ITranslationProps } from "../data/interfaces";

export interface IDataLoadingSpinnnerProps extends ITranslationProps {}

export interface IDataLoadingSpinnnerState {}

class DataLoadingSpinnner extends React.Component<
  IDataLoadingSpinnnerProps,
  IDataLoadingSpinnnerState
> {
  render() {
    return (
      <LoadingIndicator message={this.props.i18n.loadingIndicatorMessage} />
    );
  }
}

export default Localization.translateComponent<
  IDataLoadingSpinnnerProps,
  IDataLoadingSpinnnerState
>("DataLoadingSpinnner")(DataLoadingSpinnner);
