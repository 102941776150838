import {
  ISite,
  IDrive,
  IDriveItem,
  IUser,
  IList,
  IRootData,
  IConfigSetting,
  IHierarchialDataset,
  IHierarchialDataResult,
  IBaseItem,
  IOrganizationData,
  IErrorResult,
  IDriveItemResult,
  ISharingLinkData,
  IOperationStatus,
  IRecycleBin,
  IRecycleBinItem,
} from "./interfaces";
import { HierarchialDataScope } from "./enums";
import {
  isSite,
  isDrive,
  isDriveItem,
  isRootDataServiceClient,
  isRecycleBin,
} from "../helpers/typeCheck";
import cloneDeep from "lodash.clonedeep";
import { getErrorDescription } from "../helpers/errorHelper";
import { apiFetch } from "../msal";
import { request } from "http";

/**
 * Proxy class used for communicating with the web api service.
 */
export default class ServiceClient {
  private _baseUrl: string = "";

  constructor() {
    this._baseUrl = `${window.location.origin}/api`;
  }

  /**
   * Helper method to perform HTTP POST request using the ApiFetch wrapper.
   * @param action Action method to execute
   * @param body Optional body to post.
   */
  private ApiRequest<T>(
    action: string,
    body?: any,
    formData?: FormData
  ): Promise<T> {
    let options: any = {
      headers: {
        accept: "application/json;odata=verbose",
        "Content-Type": "application/json",
      },
    };

    // Remove content type header if FormData is sent
    if (typeof formData !== "undefined") {
      delete options.headers["Content-Type"];
    }

    // Extend the object if there is a body specified
    if (typeof body !== "undefined" || typeof formData !== "undefined") {
      options = {
        ...options,
        method: "POST",
        body: formData !== undefined ? formData : JSON.stringify(body),
      };
    }

    // Construct endpoint url
    const endpoint: string = `${this._baseUrl}/${action}`;

    // Return a promise for this request
    return new Promise((resolve, reject) => {
      apiFetch(endpoint, options)
        .then((response) => {
          const contentType: string | null =
            response.headers.get("content-type");
          const isJson: boolean | "" | null =
            contentType && contentType.indexOf("application/json") !== -1;

          if (response.ok) {
            if (isJson) {
              if (response.status !== 204) {
                return response.json();
              }
            } else {
              //only works with an empty resolve
              Promise.resolve();
            }
          } else {
            if (isJson) {
              // TODO: handle internet/browser issues where no IErrorResult is returned
              return response.json(); // IErrorResult
            } else {
              reject();
            }
          }
        })
        .then((json) => {
          resolve(json as T);
        });
    });
  }

  /**
   * Helper method to perform an anonymous HTTP POST (without the ApiFetch wrapper)
   * @param action Action method to execute
   * @param body Optional body to post.
   */
  private ApiRequestWithoutToken<T>(action: string, body?: any): Promise<T> {
    // Construct endpoint url
    const endpoint: string = `${this._baseUrl}/${action}`;

    // Return a promise for this request
    return new Promise((resolve) => {
      fetch(endpoint, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.status; // For example 400
          }
        })
        .then((json) => {
          resolve(json as T);
        });
    });
  }

  public async Get<T>(action: string): Promise<T> {
    let result: any = await this.ApiRequest(action);
    return result;
  }

  public async Post<T>(action: string, body?: any): Promise<T> {
    let result: any = this.ApiRequest(action, body);
    return result;
  }

  /**
   * Loads a dataset based on the specified HierarchialDataScope type
   * @param scope HierarchialDataScope type describing the type of dataset
   * @param dataSetName Name of the dataset
   * @param siteCollectionHostname Site collection host name to return the sites from
   */
  public loadDataSetAsync(
    scope: HierarchialDataScope,
    dataSetName: string,
    siteCollectionHostname: string
  ): Promise<IHierarchialDataResult | IErrorResult> {
    return new Promise<IHierarchialDataResult | IErrorResult>(
      (resolve, reject) => {
        let dataSet: IHierarchialDataset = {
          root: dataSetName,
          scope: scope,
          children: [],
        };

        // Depending on the scope, we have different endpoints to call
        if (scope === HierarchialDataScope.Favorites) {
          this.GetFollowedSites(siteCollectionHostname).then(
            (sites: ISite[] | IErrorResult) => {
              if ((sites as IErrorResult).internalErrorCode !== undefined) {
                resolve(sites as IErrorResult);
              } else {
                dataSet = {
                  ...dataSet,
                  children: (sites as ISite[]).map(
                    (site: ISite, index: number) => {
                      return {
                        ...site,
                        rootScope: scope,
                        children: [],
                        isContainer: true,
                        isExpanded: false,
                        isLoading: false,
                        indexPath: [index.toString()],
                      };
                    }
                  ),
                };

                resolve({
                  updatedDataset: dataSet,
                });
              }
            }
          );
        } else if (scope === HierarchialDataScope.AllSites) {
          this.GetSites(undefined, siteCollectionHostname).then(
            (sites: ISite[] | IErrorResult) => {
              if ((sites as IErrorResult).internalErrorCode !== undefined) {
                resolve(sites as IErrorResult);
              } else {
                dataSet = {
                  ...dataSet,
                  children: (sites as ISite[]).map(
                    (site: ISite, index: number) => {
                      return {
                        ...site,
                        rootScope: scope,
                        children: [],
                        isContainer: true,
                        isExpanded: false,
                        isLoading: false,
                        indexPath: [index.toString()],
                      };
                    }
                  ),
                };

                resolve({
                  updatedDataset: dataSet,
                });
              }
            }
          );
        } else if (scope === HierarchialDataScope.OneDrives) {
          this.GetOneDriveDrives().then((drives: IDrive[] | IErrorResult) => {
            if ((drives as IErrorResult).internalErrorCode !== undefined) {
              resolve(drives as IErrorResult);
            } else {
              dataSet = {
                ...dataSet,
                children: (drives as IDrive[]).map(
                  (drive: IDrive, index: number) => {
                    return {
                      ...drive,
                      rootScope: scope,
                      displayName: drive.name,
                      children: [],
                      isContainer: true,
                      isExpanded: false,
                      isLoading: false,
                      indexPath: [index.toString()],
                    };
                  }
                ),
              };

              resolve({
                updatedDataset: dataSet,
              });
            }
          });
        }
      }
    );
  }

  /**
   * Loads the specified IBaseItem instance and returns a modified IHierarchialDataset instance which can be used for a setState call.
   * @param data IHierarchialDataset instance containing the IBaseItem instance which is to be loaded.
   * @param item IBaseItem instance wich can be a ISite, IDrive or IDriveItem which have the isContainer flag set.
   */
  public loadItemAsync(
    data: IHierarchialDataset,
    item: IBaseItem | IRecycleBin,
    recycleBinLabel?: string
  ): Promise<IBaseItem | IRecycleBin | IErrorResult> {
    return new Promise<IBaseItem | IRecycleBin | IErrorResult>(
      (resolve, reject) => {
        let requests: Promise<IBaseItem[] | IRecycleBin | IErrorResult>[] = [];

        if (!item.isContainer) {
          reject("Only IBaseItem which are containers can be loaded");
          return;
        }

        if (isDriveItem(item)) {
          let driveItem: IDriveItem = item as IDriveItem;
          requests = [
            this.GetDriveItems(
              driveItem.driveId,
              item.id,
              data.scope === HierarchialDataScope.OneDrives,
              driveItem.path
            ),
          ];
        } else if (isDrive(item)) {
          let drive: IDrive = item as IDrive;
          requests = [
            this.GetDriveItems(
              drive.id,
              drive.id,
              data.scope === HierarchialDataScope.OneDrives
            ),
          ];
        } else if (isSite(item)) {
          requests = [this.GetSites(item.id), this.GetDrives(item.id)];

          if (recycleBinLabel) {
            requests.push(this.GetRecycleBin(item, recycleBinLabel));
          }
        } else if (isRecycleBin(item)) {
          const parentItem: ISite = item.parentItem as ISite;

          requests = [this.GetRecycleBinItems(parentItem.webUrl)];
        }

        Promise.all(requests).then(
          (results: (IBaseItem[] | IRecycleBin | IErrorResult)[]) => {
            if (
              (results as IErrorResult[])[0].internalErrorCode !== undefined
            ) {
              resolve((results as IErrorResult[])[0]);
            } else if (
              (results as IErrorResult[])[results.length - 1]
                .internalErrorCode !== undefined
            ) {
              resolve((results as IErrorResult[])[results.length - 1]);
            } else {
              let shallowItem: IBaseItem = cloneDeep(item as IBaseItem);

              shallowItem = {
                ...shallowItem,
                children: [],
                isLoaded: true,
                isLoading: false,
                isExpanded: false,
              };

              (results as IBaseItem[][]).forEach(
                (newChildrens: IBaseItem[], index: number) => {
                  shallowItem = {
                    ...shallowItem,
                    children: [
                      ...shallowItem.children.concat(newChildrens),
                    ].map((newChild: IBaseItem, index: number) => {
                      return {
                        ...newChild,
                        rootScope: shallowItem.rootScope,
                        parentItem: shallowItem,
                        indexPath:
                          shallowItem.indexPath &&
                          shallowItem.indexPath.concat([index.toString()]),
                        isExpanded: false,
                        isLoading: false,
                      };
                    }),
                  };
                }
              );

              resolve(shallowItem);
            }
          }
        );
      }
    );
  }

  /**
   * Returns the recyclebin retreived from the specified site.
   * @param siteId String containing the id of the site to get the lists from.
   */
  private GetRecycleBin(
    site: IBaseItem,
    label: string
  ): Promise<IRecycleBin | IErrorResult> {
    return new Promise<IRecycleBin | IErrorResult>((resolve, reject) => {
      resolve({
        displayName: label,
        id: "rb" + site.displayName,
        children: [],
        parentItem: site,
        rootScope: site.rootScope,
        isContainer: true,
        isLoading: false,
        isLoaded: false,
        isExpanded: false,
        indexPath: [],
        size: 0,
      } as IRecycleBin);
    });
  }

  /**
   * Returns a list of OneDrives scope to the specified site name.
   * @param siteName String containing the name of the site.
   */
  private GetOneDriveDrives(): Promise<IDrive[] | IErrorResult> {
    return new Promise<IDrive[] | IErrorResult>((resolve, reject) => {
      this.ApiRequest<IDrive[] | IErrorResult>("oneDriveDrives").then(
        (items: IDrive[] | IErrorResult) => {
          resolve(items);
        }
      );
    });
  }

  /**
   * Returns a list of ISite instances describing the user's favorite sites.
   */
  private GetFollowedSites(
    siteCollectionHostname?: string
  ): Promise<ISite[] | IErrorResult> {
    return new Promise<ISite[] | IErrorResult>((resolve, reject) => {
      let body: any = undefined;

      // When the sitecollection hostname is specified we have to get the rootsites and exclude the sites when needed
      // Excluded sites are stored in Azure and are excluded in the Api
      if (typeof siteCollectionHostname !== "undefined") {
        body = { siteCollectionHostname: siteCollectionHostname };
      }

      this.ApiRequest<ISite[]>("followedsites", body).then(
        (sites: ISite[] | IErrorResult) => {
          if ((sites as IErrorResult).internalErrorCode !== undefined) {
            resolve(sites as IErrorResult);
          } else {
            resolve(
              (sites as ISite[]).map((site: ISite, index: number) => {
                return {
                  ...site,
                  isContainer: true,
                  index: index,
                };
              })
            );
          }
        }
      );
    });
  }

  /**
   * Returns a list of ISite instances describing the sites retreived from the specified (optional) site level.
   * @param siteId (Optional) string containing the id of the parent site
   * @param siteCollectionHostname (Optional) string containing the tenantname to exclude sites
   */
  private GetSites(
    siteId?: string,
    siteCollectionHostname?: string
  ): Promise<ISite[] | IErrorResult> {
    return new Promise<ISite[] | IErrorResult>((resolve, reject) => {
      let actionName: string = "";
      let body: any = undefined;

      // When the sitecollection hostname is specified we have to get the rootsites and exclude the sites when needed
      // Excluded sites are stored in Azure and are excluded in the Api
      if (typeof siteCollectionHostname !== "undefined") {
        actionName = "rootsites";
        body = { siteCollectionHostname: siteCollectionHostname };
      }

      // When a siteId is specified, we need to get the subsites from it using a different action.
      if (typeof siteId !== "undefined") {
        actionName = "subsites";
        body = { siteId: siteId };
      }

      this.ApiRequest<ISite[]>(actionName, body).then(
        (sites: ISite[] | IErrorResult) => {
          if ((sites as IErrorResult).internalErrorCode !== undefined) {
            resolve(sites as IErrorResult);
          } else {
            resolve(
              (sites as ISite[]).map((site: ISite, index: number) => {
                return {
                  ...site,
                  isContainer: true,
                  index: index,
                };
              })
            );
          }
        }
      );
    });
  }

  /**
   * Returns a list of IDrive instances describing the SharePoint lists retreived from the specified site.
   * @param siteId String containing the id of the site to get the lists from.
   */
  private GetDrives(siteId: string): Promise<IDrive[] | IErrorResult> {
    return new Promise<IDrive[] | IErrorResult>((resolve, reject) => {
      this.ApiRequest<IDrive[] | IErrorResult>("drives", {
        siteId: siteId,
      }).then((drives: IDrive[] | IErrorResult) => {
        if ((drives as IErrorResult).internalErrorCode !== undefined) {
          resolve(drives as IErrorResult);
        } else {
          resolve(
            (drives as IDrive[]).map((drive: IDrive, index: number) => {
              return {
                ...drive,
                displayName: drive.name,
                isContainer: true,
                index: index,
              } as IDrive;
            })
          );
        }
      });
    });
  }

  /**
   * Returns a list of IDriveItem instances describing the SharePoint drive items inside a drive.
   * @param driveId String containing the id of the drive to return the items from
   * @param parentId String containing the id of the parent drive item to return the items from
   * @param folderPath (Optional) String to return folders from a nested folder
   */
  private GetDriveItems(
    driveId: string,
    parentId: string,
    driveIsOneDrive: boolean,
    folderPath?: string
  ): Promise<IDriveItem[] | IErrorResult> {
    return new Promise<IDriveItem[] | IErrorResult>((resolve, reject) => {
      this.ApiRequest<IDriveItem[]>("items", {
        driveId: driveId,
        parentId: parentId,
        Path: folderPath,
        DriveIsOneDrive: driveIsOneDrive,
      }).then((driveItems: IDriveItem[] | IErrorResult) => {
        // If error then return the error model
        if ((driveItems as IErrorResult).internalErrorCode !== undefined) {
          return resolve(driveItems);
        }
        resolve(
          (driveItems as IDriveItem[]).map(
            (driveItem: IDriveItem, index: number) => {
              return {
                ...driveItem,
                name: driveItem.name,
                displayName: driveItem.name,
                isContainer: driveItem.isFolder,
                isSelected: false,
                index: index,
                path:
                  folderPath !== undefined
                    ? `${folderPath}/${driveItem.name}`
                    : `${driveItem.name}`,
              };
            }
          )
        );
      });
    });
  }

  /**
   * Returns a list of IDriveItem instances describing the SharePoint drive items inside the RecycleBin
   * @param driveId String containing the id of the drive to return the items from
   * @param parentId String containing the id of the parent drive item to return the items from
   * @param folderPath (Optional) String to return folders from a nested folder
   */
  private GetRecycleBinItems(
    siteUrl: string,
    folderPath?: string
  ): Promise<IRecycleBinItem[] | IErrorResult> {
    return new Promise<IRecycleBinItem[] | IErrorResult>((resolve, reject) => {
      this.ApiRequest<IRecycleBinItem[]>("recycleBinItems", {
        siteUrl: siteUrl,
        Path: folderPath,
      }).then((driveItems: IRecycleBinItem[] | IErrorResult) => {
        // If error then return the error model
        if ((driveItems as IErrorResult).internalErrorCode !== undefined) {
          return resolve(driveItems);
        }

        resolve(
          (driveItems as IRecycleBinItem[]).map(
            (driveItem: IRecycleBinItem, index: number) => {
              return {
                ...driveItem,
                name: driveItem.name,
                displayName: driveItem.name,
                isContainer: driveItem.isFolder,
                index: index,
                path:
                  folderPath !== undefined
                    ? `${folderPath}/${driveItem.name}`
                    : `${driveItem.name}`,
              };
            }
          )
        );
      });
    });
  }

  public DeleteItemsAsync(items: IBaseItem[]): Promise<IErrorResult | void> {
    return new Promise<IErrorResult | void>((resolve, reject) => {
      let driveItems: IDriveItem[] = items as IDriveItem[];
      this.ApiRequest<void | IErrorResult>("items/remove", {
        driveId: driveItems[0].driveId,
        itemIds: driveItems.map((item) => {
          return item.id;
        }),
      }).then((error: IErrorResult | void) => {
        if (error !== undefined) {
          resolve(error as IErrorResult);
        } else {
          resolve();
        }
      });
    });
  }

  public CheckOperationStatusAsync(
    monitorUrl: string
  ): Promise<IOperationStatus> {
    return this.ApiRequest<IOperationStatus>("checkOperationStatus", {
      WebUrl: monitorUrl,
    });
  }

  public DeleteRecycleBinItemAsync(
    driveId: string
  ): Promise<IErrorResult | void> {
    return new Promise<IErrorResult | void>((resolve, reject) => {
      this.ApiRequest<void | IErrorResult>("item/deleteRecycleBinItem", {
        driveId: driveId,
      }).then((error: IErrorResult | void) => {
        if (error !== undefined) {
          resolve(error as IErrorResult);
        } else {
          resolve();
        }
      });
    });
  }

  public EmptyRecycleBinAsync(webUrl: string): Promise<IErrorResult | void> {
    return new Promise<IErrorResult | void>((resolve, reject) => {
      this.ApiRequest<void | IErrorResult>("item/emptyRecycleBin", {
        webUrl: webUrl,
      }).then((error: IErrorResult | void) => {
        if (error !== undefined) {
          resolve(error as IErrorResult);
        } else {
          resolve();
        }
      });
    });
  }

  public RestoreItemAsync(
    webUrl: string,
    itemId: string
  ): Promise<IErrorResult | void> {
    return new Promise<IErrorResult | void>((resolve, reject) => {
      this.ApiRequest<void | IErrorResult>("item/restore", {
        webUrl: webUrl,
        itemId: itemId,
      }).then((error: IErrorResult | void) => {
        if (error !== undefined) {
          resolve(error as IErrorResult);
        } else {
          resolve();
        }
      });
    });
  }

  public RenameItemAsync(
    item: IBaseItem,
    name: string
  ): Promise<IBaseItem | void> {
    return new Promise<IBaseItem>((resolve, reject) => {
      let driveItem: IDriveItem = item as IDriveItem;

      this.ApiRequest<IDriveItem>("item/rename", {
        driveId: driveItem.driveId,
        itemId: driveItem.id,
        parentId: driveItem.parentItem.id,
        name: name,
        driveIsOneDrive: driveItem.driveIsOneDrive,
        //filePath: driveItem.parentItem.path
        webUrl: driveItem.webUrl,
      }).then((result: IDriveItem | IErrorResult) => {
        if ((result as IErrorResult).internalErrorCode !== undefined) {
          reject(result);
        } else {
          let driveItemResult = result as IDriveItem;
          resolve({
            ...item,
            name: driveItemResult.name,
            displayName: driveItemResult.name,
            path: item.parentItem.path + "/" + driveItemResult.name,
            isUpdating: false,
          } as IDriveItem);
        }
      });
    });
  }

  // To do: return http status: 200 OK with driveItem (success) or other (fail)
  // The above refactor is important to let the user know whether his action was successful or not (!)
  public MoveDriveItems(
    sourceDriveItem: IDriveItem,
    sourceDriveItemIds: string[],
    sourceDriveItemNames: string[],
    sourceDriveItemReferences: string[],
    destinationDriveId: string,
    destinationItemId: string,
    destinationFolderPath: string,
    destinationIsOneDrive: boolean
  ): Promise<IDriveItemResult[]> {
    return new Promise<IDriveItemResult[]>((resolve, reject) => {
      this.ApiRequest<IDriveItem>("items/move", {
        DriveId: sourceDriveItem.driveId,
        ItemIds: sourceDriveItemIds,
        ItemNames: sourceDriveItemNames,
        DriveIsOneDrive: sourceDriveItem.driveIsOneDrive,
        ItemReferenceIds: sourceDriveItemReferences,
        destinationDriveId: destinationDriveId,
        destinationItemId: destinationItemId,
        destinationIsOneDrive: destinationIsOneDrive,
        destinationPath: `${
          typeof destinationFolderPath !== "undefined"
            ? destinationFolderPath
            : ""
        }/${sourceDriveItem.displayName}`,
      }).then((result: any | IErrorResult) => {
        if ((result as IErrorResult).internalErrorCode !== undefined) {
          reject(result);
        } else {
          resolve(result);
        }
      });
    });
  }

  // To do: return http status: 200 OK with driveItem (success) or other (fail)
  // The above refactor is important to let the user know whether his action was successful or not (!)
  public CopyDriveItems(
    sourceDriveItem: IDriveItem,
    sourceDriveItemIds: string[],
    sourceDriveItemNames: string[],
    destinationDriveId: string,
    destinationItemId: string,
    destinationFolderPath: string,
    destinationIsOneDrive: boolean
  ): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.ApiRequest<string[]>("items/copy", {
        DriveId: sourceDriveItem.driveId,
        ItemIds: sourceDriveItemIds,
        ItemNames: sourceDriveItemNames,
        DriveIsOneDrive: sourceDriveItem.driveIsOneDrive,
        destinationDriveId: destinationDriveId,
        destinationItemId: destinationItemId,
        destinationIsOneDrive: destinationIsOneDrive,
        destinationPath: `${
          typeof destinationFolderPath !== "undefined"
            ? destinationFolderPath
            : ""
        }/${sourceDriveItem.displayName}`,
      }).then((result: string[] | IErrorResult) => {
        if ((result as IErrorResult).internalErrorCode !== undefined) {
          reject(result);
        } else {
          resolve(result as string[]);
        }
      });
    });
  }

  public GetUser(): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.ApiRequest<IUser>("user", {})
        .then((user: IUser) => {
          resolve(user);
        })
        .catch((reason: any) => {
          console.error("Exception in GetUser:", reason);
          reject (reason);
        });
    });
  }

  public GetConfigSetting(): Promise<IConfigSetting> {
    return new Promise<IConfigSetting>((resolve, reject) => {
      this.ApiRequestWithoutToken<IConfigSetting>("config", {}).then(
        (config: IConfigSetting) => {
          resolve(config);
        }
      );
    });
  }

  public GetRootData(): Promise<IRootData> {
    return new Promise<IRootData>((resolve, reject) => {
      this.ApiRequest<IRootData>("rootdata", {})
        .then((rootdata: IRootData) => {
          const isRootData: boolean = isRootDataServiceClient(rootdata);
          if (isRootData) resolve(rootdata);
          else {
            const errorCode: string = rootdata as unknown as string;
            throw new Error(errorCode);
          }
        })
        .catch((reason: string) => {
          const httpStatusCode: number = +reason
            .toString()
            .replace("Error: ", "");

          const errorDescription: string = getErrorDescription(
            httpStatusCode,
            "en"
          );

          console.warn("Warning in GetRootData: ", reason, errorDescription);
          reject(reason); // Catch of parent method is executed.
        });
    });
  }

  public ResolveSPList(siteId: string, driveUrl: string): Promise<IList> {
    return new Promise<IList>((resolve, reject) => {
      this.ApiRequest<IList>("list", {
        siteId: siteId,
        webUrl: driveUrl,
      }).then((list: IList | IErrorResult) => {
        if ((list as IErrorResult).internalErrorCode !== undefined) {
          reject(list as IErrorResult);
        } else {
          resolve(list as IList);
        }
      });
    });
  }

  public FollowSite(siteId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.ApiRequest<boolean>("follow", siteId).then(
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  public UnfollowSite(siteId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.ApiRequest<boolean>("unfollow", siteId).then(
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  public IsTenantLicenseValid(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.ApiRequest<boolean>("isTenantLicenseValid", {}).then(
        (result: boolean) => {
          resolve(result);
        }
      );
    });
  }

  public GetOrganizationData(): Promise<IOrganizationData> {
    return new Promise<IOrganizationData>((resolve, reject) => {
      this.ApiRequest<IOrganizationData>("organizationData", {}).then(
        (organizationData: IOrganizationData) => {
          resolve(organizationData);
        }
      );
    });
  }

  public CreateDocument(body: any): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      this.ApiRequest<IDriveItem>("item/newDocument", body).then(
        (result: IDriveItem | IErrorResult) => {
          if ((result as IErrorResult).internalErrorCode !== undefined) {
            reject(result as IErrorResult);
          } else {
            resolve(result as IDriveItem);
          }
        }
      );
    });
  }

  public CreateFolder(body: any): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      this.ApiRequest<IDriveItem>("item/addFolder", body).then(
        (result: IDriveItem | IErrorResult) => {
          if ((result as IErrorResult).internalErrorCode !== undefined) {
            reject(result as IErrorResult);
          } else {
            resolve(result as IDriveItem);
          }
        }
      );
    });
  }

  public CreateFile(formData: any): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      this.ApiRequest<IDriveItem>("item/addFile", undefined, formData).then(
        (result: IDriveItem | IErrorResult) => {
          if ((result as IErrorResult).internalErrorCode !== undefined) {
            reject(result as IErrorResult);
          } else {
            resolve(result as IDriveItem);
          }
        }
      );
    });
  }

  public CreateShareLink(
    item: IDriveItem,
    siteOrOneDriveUrl: string,
    driveIsOneDrive: boolean
  ): Promise<ISharingLinkData> {
    return new Promise<ISharingLinkData>((resolve, reject) => {
      this.ApiRequest<ISharingLinkData>("item/createShareLink", {
        driveId: item.driveId,
        itemId: item.id,
        siteOrOneDriveUrl: siteOrOneDriveUrl,
        driveIsOneDrive: driveIsOneDrive,
      }).then((result: ISharingLinkData | IErrorResult) => {
        if ((result as IErrorResult).internalErrorCode !== undefined) {
          reject(result as IErrorResult);
        } else {
          resolve(result as ISharingLinkData);
        }
      });
    });
  }
}
