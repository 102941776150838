import React from "react";
import {
  IDriveItem,
  ITranslationProps,
  IBaseItem,
  IMessageDialogContext,
  ISite,
  IDrive,
  IShareDialogContext,
  ISharingLinkData,
} from "../data/interfaces";
import {
  Grid,
  GridColumn as Column,
  GridCellProps,
  GridNoRecords,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { Icon, IconType } from "office-ui-fabric-react/lib/Icon";
import { Popup, Offset } from "@progress/kendo-react-popup";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import RenameDialog from "./RenameDialog";
import Moment from "moment";
import { IconHelper } from "../helpers/iconHelper";
import * as Localization from "./Translate";
import {
  HierarchialDataScope,
  MessageBarType,
  MessageDialogType,
  ActionType,
} from "../data/enums";
import { Guid } from "../helpers/globalHelper";
import { isDriveItem, isRecycleBin } from "../helpers/typeCheck";
import MessageDialog from "./MessageDialog";
import LoadingIndicator from "./LoadingIndicator";
import { ModificationType } from "./ItemModificationQueue";
import ShareDialog from "./ShareDialog";
import customProtocolCheck from "custom-protocol-check";

export interface IDriveItemsListProps extends ITranslationProps {
  loadedContainer?: IBaseItem;
  language: string;

  itemClickedEvent?(clickedItem: IBaseItem): void;
  itemRemoveEvent?(itemToRemove: IBaseItem[]): Promise<void>;
  itemRestoreEvent?(itemToRestore: IBaseItem): Promise<void>;
  itemDeleteEvent?(itemToDelete: IBaseItem): Promise<void>;
  itemRenameEvent?(itemToRename: IBaseItem, newName: string): Promise<void>;
  itemMoveEvent?(itemsToMove: IBaseItem[]): void;
  itemCopyEvent?(itemsToCopy: IBaseItem[]): void;

  itemDroppedEvent(sourceItems: IBaseItem[], targetContainer: IBaseItem): void;

  itemCreationEvent(formData: FormData): Promise<IDriveItem>;
  itemsCreatingEvent(item: IBaseItem[]): Promise<void>;
  itemCreatedEvent(item: IBaseItem): Promise<void>;

  itemsSelectedEvent(selected: boolean): void;

  DummyFileRemoveEvent(
    item: IBaseItem,
    modificationType: ModificationType
  ): Promise<boolean>;

  showMessageBar(message: string, messageBarType: MessageBarType): void;
  showMessageBarJSXFormed(
    message: JSX.Element,
    messageBarType: MessageBarType
  ): void;
  closeMessageBar(): void;

  createShareLink(
    item: IDriveItem,
    siteOrOneDriveUrl: string,
    driveIsOneDrive: boolean
  ): Promise<ISharingLinkData>;
}

interface IDriveItemsListState {
  data: IBaseItem[];
  itemPopupOpen: boolean;
  sortDefinition: SortDescriptor[];
  showRenameDialog: boolean;
  selectedItem?: IBaseItem;
  dragging: boolean;
  uploading: boolean;
  cancelClicked: boolean;
  messageDialogContext?: IMessageDialogContext;
  testFailedMessages: boolean;
  uriPrefix: string;
  disableMenuItemOpenInApp: boolean;
  inRecycleBin: boolean;
  shareDialogContext?: IShareDialogContext;
  itemsSelected: boolean;
}

class DriveItemsList extends React.Component<
  IDriveItemsListProps,
  IDriveItemsListState
> {
  private item?: IDriveItem;
  private offset?: Offset;
  private dataItemIndex?: number;
  private _iconHelper: IconHelper;
  private _isMounted = false;
  private _mouseCoordinates: { x: number; y: number };

  constructor(props: IDriveItemsListProps) {
    super(props);

    const testFailedMessages: boolean =
      window.location.search.indexOf("testFailedMessages=true") > -1
        ? true
        : false;

    this.state = {
      data: [],
      itemPopupOpen: false,
      sortDefinition: [],
      showRenameDialog: false,
      selectedItem: undefined,
      dragging: false,
      uploading: false,
      cancelClicked: false,
      messageDialogContext: undefined,
      testFailedMessages: testFailedMessages,
      uriPrefix: "",
      disableMenuItemOpenInApp: false,
      inRecycleBin: false,
      itemsSelected: false,
    };

    this._iconHelper = new IconHelper();

    this._mouseCoordinates = { x: 0, y: 0 };
  }

  public componentDidMount() {
    /*
    You may call setState() immediately in componentDidMount(). It will trigger an
    extra rendering, but it will happen before the browser updates the screen. This
    guarantees that even though the render() will be called twice in this case, the
    user won’t see the intermediate state. Use this pattern with caution because it
    often causes performance issues...
  */
    this._isMounted = true;
    if (typeof this.props.loadedContainer !== "undefined") {
      this.setState({
        data: this.props.loadedContainer.children,
        sortDefinition: [{ field: "displayName", dir: "asc" }],
      });

      if (isRecycleBin(this.props.loadedContainer)) {
        this.setState({
          inRecycleBin: true,
        });
      } else {
        this.setState({
          inRecycleBin: false,
        });
      }
    }

    // Add a click listener to grab the mouse X and Y coordinates
    document.addEventListener("click", this.handleMousemove);

    // Add a click listener to determine if the popup is allowed to close on the disabled "Open in App menuitem"
    document.addEventListener("click", () => {
      if (this.state.itemPopupOpen) {
        var popup = document.getElementById("contextPopupMenuId");
        if (popup !== null) {
          var left = popup.offsetLeft + document.body.scrollLeft;
          var top = popup.offsetTop + document.body.scrollTop;
          var width = popup.offsetWidth;
          var height = popup.offsetHeight;
          var right = left + width;
          var bottom = top + height;

          if (
            this._mouseCoordinates.x < left ||
            this._mouseCoordinates.x > right ||
            this._mouseCoordinates.y < top ||
            this._mouseCoordinates.y > bottom
          ) {
            this.resetItemPopup();
          }
        }
      }
    });
  }

  public componentDidUpdate(prevProps: IDriveItemsListProps) {
    if (this.props.loadedContainer === undefined) return;

    if (this.props.loadedContainer !== prevProps.loadedContainer) {
      this.setState({
        data: this.props.loadedContainer.children.map((item) => {
          item.isSelected = false;
          return item;
        }),
        sortDefinition: [{ field: "name", dir: "asc" }],
      });

      if (isRecycleBin(this.props.loadedContainer)) {
        this.setState({
          inRecycleBin: true,
        });
      } else {
        this.setState({
          inRecycleBin: false,
        });
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("click", this.handleMousemove, true);
  }

  resetItemPopup() {
    this.setState({
      itemPopupOpen: false,
      disableMenuItemOpenInApp: false,
      uriPrefix: "",
    });
  }

  handleMousemove = (event: any) => {
    if (this._isMounted) {
      this._mouseCoordinates = {
        x: event.x,
        y: event.y,
      };
    }
  };

  handleUploadDragEnter = (event: React.DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (
      event.dataTransfer.items &&
      event.dataTransfer.items.length > 0 &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      this.setState({ dragging: true, uploading: false });
    }
  };

  handleUploadDragLeave = (event: React.DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ dragging: false, uploading: false });
  };

  handleUploadDragOver = (event: React.DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  private getSkeletonInstance(parentItem: IBaseItem): IBaseItem {
    return {
      id: "",
      referenceId: Guid.newGuid(),
      displayName: "",
      parentItem: parentItem,
      rootScope: parentItem.rootScope,
      children: [],
      isAdding: true,
      isUpdating: false,
      isDeleting: false,
      isExpanded: false,
      isDirty: true,
      indexPath: [],
      isLoaded: false,
      isLoading: false,
      isContainer: false,
      isMoving: false,
    } as IBaseItem;
  }

  private getDummyItems(rawFiles: FileList): IBaseItem[] {
    let newItems: IBaseItem[] = [];

    if (this.props.loadedContainer !== undefined) {
      for (let index = 0; index < rawFiles.length; index++) {
        let dummyItem: IBaseItem = this.getSkeletonInstance(
          this.props.loadedContainer
        );

        newItems.push({
          ...dummyItem,
          name: rawFiles[index].name,
          displayName: rawFiles[index].name,
          isContainer: false,
          size: rawFiles[index].size,
        } as IDriveItem);
      }
    }

    return newItems;
  }

  private handleUploadDrop = (event: React.DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    event.persist();

    let isFolderIncluded: boolean = false;

    const items: DataTransferItemList = event.dataTransfer.items;

    // Several pre-checks (files-only and not files bigger than 4294967295 bytes)
    for (let index = 0; index < items.length; index++) {
      var entry: any = items[index].webkitGetAsEntry();
      if (entry.isFile) {
      } else if (entry.isDirectory) {
        isFolderIncluded = true;
        break;
      }
    }

    if (isFolderIncluded) {
      this.props.showMessageBar(
        this.props.i18n.folderInDragnDropSelectionMessage,
        MessageBarType.Warning
      );

      // Render to remove the drag and drop area.
      this.setState({ dragging: false });

      return;
    }

    let isFileTooBig: boolean = false;
    const files: FileList = event.dataTransfer.files;

    for (let index = 0; index < files.length; index++) {
      if (files[index].size > 4294967295) {
        isFileTooBig = true;
        break;
      }
    }

    if (isFileTooBig) {
      this.props.showMessageBar(
        this.props.i18n.fileTooLargeMessage,
        MessageBarType.Warning
      );

      // Render to remove the drag and drop area.
      this.setState({ dragging: false });

      return;
    }

    // Fill the sorted files list.
    let sortedFiles: any[] = [];
    for (let index = 0; index < files.length; index++) {
      sortedFiles.push(files[index]);
    }

    // Sort on name.
    sortedFiles.sort((a: File, b: File) => (a.name > b.name ? 1 : -1));

    if (
      sortedFiles &&
      sortedFiles.length > 0 &&
      this.props.loadedContainer !== undefined
    ) {
      this.setState(
        {
          dragging: false,
          uploading: true,
        },
        () => {
          let dummyItems: IBaseItem[] = this.getDummyItems(
            sortedFiles as unknown as FileList
          );

          // First trigger the event to create dummy items representing the new-files-to-be
          this.props.itemsCreatingEvent(dummyItems).then(async () => {
            if (this.props.loadedContainer !== undefined) {
              for (let index = 0; index < sortedFiles.length; index++) {
                // Now upload the actual file and get the returned IDriveItem instance
                await this.UploadDriveItem(
                  sortedFiles[index],
                  this.props.loadedContainer,
                  dummyItems[index],
                  dummyItems[index].referenceId
                ).catch(() => {
                  this.props.DummyFileRemoveEvent(
                    dummyItems[index],
                    ModificationType.ADD
                  );
                });
              }
            }
          });

          event.dataTransfer.clearData();
        }
      );
    }
  };

  /**
   * Handles uploading of files representing IDriveItem instances.
   * @param rawFile File instance representing the new file.
   * @param parentContainer IBaseItem instance representing the container where the new file will be uploaded to.
   * @param referenceId String containing a reference id used for identifying the new file while performing the upload action.
   */
  private UploadDriveItem(
    rawFile: File,
    parentContainer: IBaseItem,
    dummyItem: IBaseItem,
    referenceId?: string
  ): Promise<IDriveItem> {
    return new Promise<IDriveItem>((resolve, reject) => {
      let formData = new FormData();
      formData.set("file", rawFile);

      formData.append(
        "DriveId",
        isDriveItem(parentContainer)
          ? (parentContainer as IDriveItem).driveId
          : parentContainer.id
      );
      formData.append("ParentId", parentContainer.id);

      if (parentContainer.path !== undefined)
        formData.append("FolderPath", parentContainer.path);
      if (referenceId !== undefined)
        formData.append("ReferenceId", referenceId);

      if (parentContainer.rootScope === HierarchialDataScope.OneDrives)
        formData.append("DriveIsOneDrive", "true");
      else formData.append("DriveIsOneDrive", "false");

      formData.append("Overwrite", "false");

      this.props
        .itemCreationEvent(formData)
        .then((result: IDriveItem) => {
          this.props.itemCreatedEvent({
            ...result,
            displayName: result.name,
            rootScope: parentContainer.rootScope,
            isAdding: false,
            isContainer: false,
            path: result["folderPath"],
            parentItem: parentContainer,
          });
          resolve(result);
        })
        .catch(() => {
          reject();
        });
    });
  }

  private resolveDriveItemIcon(driveItem: IDriveItem): string {
    const fileExtension = !driveItem.isContainer
      ? driveItem.name.substring(
          driveItem.name.lastIndexOf(".") + 1,
          driveItem.name.length
        ) || driveItem.name
      : "";
    const iconName: string = !driveItem.isContainer
      ? this._iconHelper._getFileTypeIconNameFromExtension(fileExtension)
      : "folder";
    const iconSize: number = 16; // supported types [16, 20, 32, 40, 48, 64, 96]
    return `https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/${iconSize}/${iconName}.png`;
  }

  onFolderClick = (item: IBaseItem) => {
    if (typeof this.props.itemClickedEvent !== "undefined") {
      if (!this.state.inRecycleBin) {
        this.props.itemClickedEvent(item);
      }
    }
  };

  private resolveNameFieldClassName(dataItem: any): string {
    let item: IBaseItem = dataItem as IBaseItem;
    let classList: string[] = [];

    if (item.isContainer) {
      classList.push("droptarget");
    } else {
      classList.push("dragtarget");
    }

    if (item.isDeleting) {
      classList.push("is-deleting");
    }

    if (item.isUpdating) {
      classList.push("is-updating");
    }

    if (item.isAdding) {
      classList.push("is-adding");
    }

    if (item.isMoving) {
      classList.push("is-moving");
    }

    return classList.join(" ");
  }

  private buildNameColumn(props: React.PropsWithChildren<GridCellProps>) {
    let item: IBaseItem = props.dataItem as IBaseItem;

    // When the item has a modication running (adding, deleting, updating or moving) we render a non-clickable item
    if (item.isAdding || item.isDeleting || item.isUpdating || item.isMoving) {
      return (
        <td>
          <Icon
            imageProps={{ src: this.resolveDriveItemIcon(props.dataItem) }}
            style={{ marginRight: "5px", verticalAlign: "middle" }}
          />
          <span className={this.resolveNameFieldClassName(props.dataItem)}>
            {props.dataItem.displayName}
          </span>
        </td>
      );
    } else if (item.isContainer) {
      return (
        <td>
          <span>
            <Icon
              imageProps={{ src: this.resolveDriveItemIcon(props.dataItem) }}
              style={{ marginRight: "5px", verticalAlign: "middle" }}
            />
            <span
              className={this.resolveNameFieldClassName(props.dataItem)}
              style={{ cursor: "pointer" }}
              onClick={() => this.onFolderClick(props.dataItem)}
              title={`Open "${props.dataItem.name}" ${this.props.i18n.folderPostFixTitle}.`}
              draggable={!this.state.inRecycleBin}
              onDragStart={(event: React.DragEvent) =>
                this.onDragStartHandler(event, props.dataItem)
              }
              onDrop={(event: React.DragEvent) =>
                this.onDropHandler(event, props.dataItem)
              }
              onDragEnter={(event: React.DragEvent) =>
                this.onDragEnterHandler(event)
              }
              onDragLeave={(event: React.DragEvent) =>
                this.onDragLeaveHandler(event)
              }
              onDragOver={(event: React.DragEvent) =>
                this.onDragOverHandler(event)
              }
            >
              {props.dataItem.displayName}
            </span>
          </span>
        </td>
      );
    } else {
      return (
        <td>
          <Icon
            imageProps={{ src: this.resolveDriveItemIcon(props.dataItem) }}
            style={{ marginRight: "5px", verticalAlign: "middle" }}
          />
          <a
            href={props.dataItem.webUrl}
            target="_blank"
            rel="noopener noreferrer"
            title={`Open "${props.dataItem.displayName}" in web app.`}
            className={this.resolveNameFieldClassName(props.dataItem)}
            draggable={!this.state.inRecycleBin}
            onDragStart={(event: React.DragEvent) =>
              this.onDragStartHandler(event, props.dataItem)
            }
            onDragOver={(event: React.DragEvent) =>
              this.onDragOverHandler(event)
            }
          >
            {props.dataItem.displayName}
          </a>
        </td>
      );
    }
  }

  // event on dragtarget
  onDragStartHandler = (event: React.DragEvent, item: IBaseItem): void => {
    if (event.dataTransfer === null) {
      return;
    }

    event.dataTransfer.setData("sourceItem", JSON.stringify(item));
  };

  // event on droptarget
  onDropHandler(event: React.DragEvent, item: IBaseItem): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer === null) {
      return;
    }
    // prevent file upload
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      return;
    }
    let sourceItem: IDriveItem = JSON.parse(
      event.dataTransfer.getData("sourceItem")
    );
    // Prevent driveItem to be moved to self
    if (
      sourceItem.driveId === (item as IDriveItem).driveId &&
      sourceItem.id === item.id
    ) {
      return;
    }
    if (typeof this.props.itemDroppedEvent !== "undefined") {
      let array: IBaseItem[] = [sourceItem];
      this.props.itemDroppedEvent(array, item);
    }
  }

  // event on droptarget
  onDragEnterHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    let target = event.target as HTMLElement;

    if (
      target.classList.contains("droptarget") &&
      target.parentElement !== null
    ) {
      target.parentElement.style.border = "1px dotted black";
    }
  };

  // event on droptarget
  onDragLeaveHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof event !== "undefined") {
      let target = event.target as HTMLElement;

      if (
        target.classList.contains("droptarget") &&
        target.parentElement !== null
      ) {
        target.parentElement.style.border = "1px solid rgba(0,0,0,0)";
      }
    }
  };

  // event on droptarget
  onDragOverHandler = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // if is file, change
    let target = event.target as HTMLElement;
    if (target.classList.contains("droptarget") === false) {
      event.dataTransfer.dropEffect = "none";
    }
  };

  private buildFileSizeColumn(props: React.PropsWithChildren<GridCellProps>) {
    if (props.dataItem.isContainer) {
      let count = props.dataItem.folderChildCount;

      if (count === undefined) {
        count = 0;
      }

      return <td>{count === 1 ? count + " item" : count + " items"}</td>;
    } else {
      return <td>{this.formatBytes(props.dataItem.size)}</td>;
    }
  }

  private buildFileDeletedByColumn(
    props: React.PropsWithChildren<GridCellProps>
  ) {
    return <td>{props.dataItem.deletedBy}</td>;
  }

  private buildFileDeletedOnColumn(
    props: React.PropsWithChildren<GridCellProps>
  ) {
    const dateLM: Date = props.dataItem.deletedDate;

    // Include all locales.
    require("moment/min/locales.min");

    // Set the locale.
    Moment.locale(this.props.language);

    const dateUTC: Date = Moment.utc(dateLM).toDate();
    let dateLocalized: string = Moment(dateUTC).local().format("L");
    dateLocalized += " ";
    dateLocalized += Moment(dateUTC).local().format("LT");

    return <td>{dateLocalized}</td>;
  }

  private formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 bytes";

    const k = 1024;
    let dm = decimals < 0 ? 0 : decimals;
    const sizes = ["bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const pow = Math.pow(k, i);
    let size = bytes / pow;
    let uom = sizes[i];

    // Check when the size is in kB.
    if (sizes[i] === "kB") {
      // When in kB and the size is larger than 1000 convert to MB.
      if (size > 1000) {
        dm = 2;
        size = size / k;
        uom = "MB";
      } else {
        // When in kB the decimal is 1.
        dm = 1;
      }
    }

    // Remove decimals from bytes
    if (sizes[i] === "bytes") {
      dm = 0;
    }

    return size.toFixed(dm).toString() + " " + uom;
  }

  private buildDateTimeColumn(props: React.PropsWithChildren<GridCellProps>) {
    const dateLM: Date = props.dataItem.lastModified; // Last modified datetime in ISO format

    // Include all locales.
    require("moment/min/locales.min");

    // Set the locale.
    Moment.locale(this.props.language);

    const dateUTC: Date = Moment.utc(dateLM).toDate();
    let dateLocalized: string = Moment(dateUTC).local().format("L");
    dateLocalized += " ";
    dateLocalized += Moment(dateUTC).local().format("LT");

    return <td>{dateLocalized}</td>;
  }

  rowRender = (
    row: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => {
    const rowProps = {
      ...row.props,
      onContextMenu: (e: any) => {
        e.preventDefault();
        this.ItemOnContextMenuHandler(e, props.dataItem);
      },
    };

    return React.cloneElement(row, rowProps, row.props.children);
  };

  ItemOnContextMenuHandler = (e: any, item: IDriveItem) => {
    if (this.props.loadedContainer !== undefined) {
      this.item = item;

      this.dataItemIndex = this.props.loadedContainer.children.findIndex(
        (p) => p.id === (this.item === undefined ? undefined : this.item.id)
      );

      this.offset = { left: e.clientX, top: window.scrollY + e.clientY };

      let disableMenuItemOpenInApp: boolean = false;

      if (this.item.isFolder) {
        disableMenuItemOpenInApp = true;

        this.setState({
          itemPopupOpen: !this.state.itemPopupOpen,
          disableMenuItemOpenInApp: disableMenuItemOpenInApp,
        });
      } else {
        this.getUriPrefixForOpenInApp(item).then((uriPrefix: string) => {
          if (uriPrefix === "") disableMenuItemOpenInApp = true;

          this.setState({
            itemPopupOpen: !this.state.itemPopupOpen,
            uriPrefix: uriPrefix,
            disableMenuItemOpenInApp: disableMenuItemOpenInApp,
          });
        });
      }
    }
  };

  getUriPrefixForOpenInApp(item: IDriveItem): Promise<string> {
    const name: string = item.name;
    const index: number = name.lastIndexOf(".");
    const fileType: string = name.substring(index + 1);
    let uriPrefix: string = "";

    return new Promise<string>((resolve) => {
      let promise: Promise<string>;

      switch (fileType) {
        case "mdb":
        case "accdb":
          uriPrefix = "ms-access";
          break;
        case "xls":
        case "xlsx":
        case "xlsm":
        case "xlsb":
        case "csv":
          uriPrefix = "ms-excel";
          break;
        case "ppt":
        case "pptx":
          uriPrefix = "ms-powerpoint";
          break;
        case "mpp":
          uriPrefix = "ms-project";
          break;
        case "pub":
          uriPrefix = "ms-publisher";
          break;
        case "vsd":
        case "vsdx":
          uriPrefix = "ms-visio";
          break;
        case "doc":
        case "docx":
          uriPrefix = "ms-word";
          break;
        default:
          break;
      }

      promise = Promise.resolve(uriPrefix);

      resolve(promise);
    });
  }

  handleItemRemove = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }
    let item: IBaseItem =
      this.props.loadedContainer.children[this.dataItemIndex];

    if (this.props.itemRemoveEvent) {
      this.props.itemRemoveEvent([item]);
    }
  };

  handleItemRestore = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }

    let item = this.props.loadedContainer.children[this.dataItemIndex];

    if (this.props.itemRestoreEvent !== undefined) {
      this.props.itemRestoreEvent(item);
    }
  };

  handleItemDelete = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }

    let item = this.props.loadedContainer.children[this.dataItemIndex];

    if (this.props.itemDeleteEvent !== undefined) {
      this.props.itemDeleteEvent(item);
    }
  };

  handleItemMove = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }

    let items: IBaseItem[] = [];
    items[0] = this.props.loadedContainer.children[this.dataItemIndex];

    if (this.props.itemMoveEvent !== undefined) {
      this.props.itemMoveEvent(items);
    }
  };

  handleItemCopy = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }

    let items: IBaseItem[] = [];
    items[0] = this.props.loadedContainer.children[this.dataItemIndex];

    if (this.props.itemCopyEvent !== undefined) {
      this.props.itemCopyEvent(items);
    }
  };

  handleItemRename = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }
    let item = this.props.loadedContainer.children[this.dataItemIndex];
    this.setState({ showRenameDialog: true, selectedItem: item });
  };

  renameItem = (newName: string) => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }
    let item: IBaseItem =
      this.props.loadedContainer.children[this.dataItemIndex];

    if (this.props.itemRenameEvent !== undefined) {
      this.props.itemRenameEvent(item, newName);
    }
  };

  handleItemDownload = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }
    let item: IDriveItem = this.props.loadedContainer.children[
      this.dataItemIndex
    ] as IDriveItem;
    // Do not allow to download folder.
    // To do: remove/hide download button from folder context menu
    if (item.isContainer) {
      this.props.showMessageBar(
        this.props.i18n.noDownloadAvailableMessage,
        MessageBarType.Warning
      );
      return;
    }

    // Use a hidden iframe to download the file. Hereby we get the best browser compatibility (including IE*)
    var hiddenIFrameID = "hiddenDownloader",
      iframe: HTMLIFrameElement = document.getElementById(
        hiddenIFrameID
      ) as HTMLIFrameElement;
    if (iframe === null) {
      iframe = document.createElement("iframe");
      iframe.id = hiddenIFrameID;
      iframe.style.display = "none";
      document.body.appendChild(iframe);
    }
    iframe.src = item.downloadUrl;
  };

  handleItemOpenInBrowser = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }
    let item: IDriveItem = this.props.loadedContainer.children[
      this.dataItemIndex
    ] as IDriveItem;

    window.open(item.webUrl, "_blank");
  };

  handleItemOpenInApp = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }
    let item: IDriveItem = this.props.loadedContainer.children[
      this.dataItemIndex
    ] as IDriveItem;

    let webUrl: string;
    const { uriPrefix } = this.state;
    let uriPrefixFull: string = uriPrefix;

    const loadedContainer: ISite = this.props.loadedContainer as ISite;
    if (loadedContainer.webUrl !== undefined) {
      // Now we are in a subfolder
      webUrl = loadedContainer.webUrl;
    } else {
      // Now we are in the root of the library
      // driveUrl example contains: "https://iexpertsnl.sharepoint.com/teams/DevColin/Algemeen"
      const drive: IDrive = this.props.loadedContainer as IDrive;
      webUrl = drive.driveUrl;
    }

    // Open-for-edit-cmd = "ofe|u|" document-uri
    uriPrefixFull += ":ofe|u|";

    if (!webUrl.endsWith("/")) webUrl += "/";

    const uri: string = uriPrefixFull.concat(webUrl, item.name);

    // Check if the protocol exist and open the uri.
    customProtocolCheck(
      uri,
      () => {
        const appName: string = uriPrefix;
        let message: string = this.props.i18n.appNotAvailableMessage;
        const fullAppName = this.getAppFullName(appName);

        message = message
          .replace("#appName#", `<b>${fullAppName}</b>`)
          .replace("#fileName#", `<b>${item.name}</b>`)
          .replace(
            "#app#",
            `<b>${fullAppName.substr(fullAppName.indexOf(" "))}</b>`
          );

        let messageJSXFormed: JSX.Element = (
          <span dangerouslySetInnerHTML={{ __html: message }} />
        );

        this.props.showMessageBarJSXFormed(
          messageJSXFormed,
          MessageBarType.Error
        );
      },
      () => {
        console.log(
          `Custom protocol for ${uriPrefix} found and opened the file successfully.`
        );
      },
      5000
    );
  };

  getAppFullName(appName: string): string {
    let appFullName: string;
    switch (appName) {
      case "ms-access":
        appFullName = "Microsoft Access";
        break;
      case "ms-excel":
        appFullName = "Microsoft Excel";
        break;
      case "ms-powerpoint":
        appFullName = "Microsoft Powerpoint";
        break;
      case "ms-project":
        appFullName = "Microsoft Project";
        break;
      case "ms-publisher":
        appFullName = "Microsoft Publisher";
        break;
      case "ms-visio":
        appFullName = "Microsoft Visio";
        break;
      case "ms-word":
        appFullName = "Microsoft Word";
        break;
      default:
        appFullName = appName;
        break;
    }
    return appFullName;
  }

  handleItemShare = () => {
    if (
      this.dataItemIndex === undefined ||
      this.props.loadedContainer === undefined
    ) {
      return;
    }

    let item: IDriveItem = this.props.loadedContainer.children[
      this.dataItemIndex
    ] as IDriveItem;

    const shareContext: IShareDialogContext = { item: item };
    this.setState({ shareDialogContext: shareContext });
  };

  handleItemContextMenuOnSelect = (e: any) => {
    this.props.closeMessageBar();

    switch (e.item.data) {
      case "Restore":
        this.handleItemRestore();
        break;
      case "Delete":
        this.handleItemDelete();
        break;
      case "OpenInBrowser":
        this.handleItemOpenInBrowser();
        break;
      case "OpenInApp":
        this.handleItemOpenInApp();
        break;
      case "Rename":
        this.handleItemRename();
        break;
      case "Copy":
        this.handleItemCopy();
        break;
      case "Download":
        this.handleItemDownload();
        break;
      case "Remove":
        this.handleItemRemove();
        break;
      case "Move":
        this.handleItemMove();
        break;
      case "Share":
        this.handleItemShare();
        break;
      default:
    }
    this.resetItemPopup();
  };

  customSort = (
    data: IBaseItem[],
    sortDefinitions: SortDescriptor[]
  ): any[] => {
    if (sortDefinitions.length === 0) {
      return data;
    }

    const folders: IBaseItem[] = data.filter((item: IBaseItem) => {
      return item.isContainer;
    });

    const files: IBaseItem[] = data.filter((item: IBaseItem) => {
      return !item.isContainer;
    });

    // Depending on the sort direction, we merge the two sorted arrays differently because we don't want to mix the files and folders together
    if (sortDefinitions[0].dir === "asc") {
      return [
        ...orderBy(folders, sortDefinitions),
        ...orderBy(files, sortDefinitions),
      ];
    } else {
      return [
        ...orderBy(files, sortDefinitions),
        ...orderBy(folders, sortDefinitions),
      ];
    }
  };

  handleCloseRenameDialog = () => {
    this.setState({ showRenameDialog: false });
  };

  handleSaveRenameDialog = (newName: string) => {
    this.handleCloseRenameDialog();
    this.renameItem(newName);
  };

  handleCloseMessageDialog() {
    this.setState({ messageDialogContext: undefined });
  }

  handleCloseShareDialog() {
    this.setState({ shareDialogContext: undefined });
  }

  handleConfirmMessageDialog(item: IBaseItem, actionType: ActionType) {
    switch (actionType) {
      case ActionType.Add:
        console.log("can be improved with add later");
        break;
      case ActionType.Update:
        console.log("can be improved with update later");
        break;
      default:
        break;
    }
    this.handleCloseMessageDialog();
  }

  handleHeaderSelectionChange = (e: any) => {
    const checked = e.syntheticEvent.target.checked;

    const data = this.state.data.map((item) => {
      item.isSelected = checked;
      return item;
    });

    this.setState({ data: data, itemsSelected: checked });
    if (this.props.itemsSelectedEvent !== undefined) {
      this.props.itemsSelectedEvent(checked);
    }
  };

  handleSelectionChange = (e: any) => {
    const data = this.state.data.map((item) => {
      if (item.id === e.dataItem.id) {
        item.isSelected = !e.dataItem.isSelected;
      }
      return item;
    });

    if (this.props.itemsSelectedEvent !== undefined) {
      const selected = data.some((element: any) => element.isSelected === true);

      this.props.itemsSelectedEvent(selected);

      this.setState({
        data: data,
        itemsSelected: selected,
      });
    } else {
      this.setState({ data: data });
    }
  };

  handleCreateShareLink(
    item: IDriveItem,
    siteOrOneDriveUrl: string,
    driveIsOneDrive: boolean
  ): Promise<ISharingLinkData> {
    return this.props.createShareLink(item, siteOrOneDriveUrl, driveIsOneDrive);
  }

  private showMessageDialog(
    messageDialogType: MessageDialogType,
    dialogTitle: string,
    message: string,
    item: IBaseItem,
    actionType: ActionType
  ) {
    this.setState({
      messageDialogContext: {
        dialogType: messageDialogType,
        dialogTitle: dialogTitle,
        message: message,
        item: item,
        actionType: actionType,
      },
    });
  }

  render() {
    // Auto size column widths is not yet supported, so we use default column widths that are resizable.
    // https://feedback.telerik.com/kendo-react-ui/1360884-grids-width-auto

    const { disableMenuItemOpenInApp } = this.state;

    return (
      <div
        className="dropZone"
        onDragEnter={this.handleUploadDragEnter.bind(this)}
        onDragOver={this.handleUploadDragOver.bind(this)}
        onDrop={this.handleUploadDrop.bind(this)}
        style={{
          display: "inline-block",
          position: "relative",
        }}
      >
        {this.state.dragging && (
          <div
            onDragLeave={this.handleUploadDragLeave.bind(this)}
            style={{
              border: "dashed grey 4px",
              backgroundColor: "rgba(255,255,255,.8)",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            {this.state.uploading && (
              <LoadingIndicator message="Preparing files...." />
            )}
          </div>
        )}

        <Grid
          className="driveItemsGrid"
          data={this.customSort(this.state.data, this.state.sortDefinition)}
          selectedField="isSelected"
          onSelectionChange={this.handleSelectionChange}
          onHeaderSelectionChange={this.handleHeaderSelectionChange}
          rowRender={this.rowRender}
          resizable
          sortable={{ allowUnsort: false }}
          sort={this.state.sortDefinition}
          onSortChange={(e) => {
            this.setState({
              sortDefinition: e.sort,
            });
          }}
        >
          <Column
            className="driveItemCell"
            field="isSelected"
            width="50px"
            headerSelectionValue={this.state.data.some(
              (element: any) => element.isSelected === true
            )}
          />
          <Column
            className="driveItemCell"
            field="displayName"
            title={this.props.i18n.columnHeaderNameTitle}
            cell={(props) => this.buildNameColumn(props)}
          />
          {!this.state.inRecycleBin && (
            <Column
              className="driveItemCell"
              field="size"
              title={this.props.i18n.columnHeaderSizeTitle}
              width="175"
              cell={(props) => this.buildFileSizeColumn(props)}
            />
          )}
          {this.state.inRecycleBin && (
            <Column
              className="driveItemCell"
              field="originalLocation"
              title={this.props.i18n.columnHeaderOriginalLocationTitle}
              width="400"
              cell={(props) => <td>{props.dataItem.originalLocation}</td>}
            />
          )}
          {this.state.inRecycleBin && (
            <Column
              className="driveItemCell"
              field="deletedBy"
              title={this.props.i18n.columnHeaderDeletedByTitle}
              width="175"
              cell={(props) => this.buildFileDeletedByColumn(props)}
            />
          )}
          {!this.state.inRecycleBin && (
            <Column
              className="driveItemCell"
              field="lastModified"
              title={this.props.i18n.columnHeaderModifiedTitle}
              width="220"
              cell={(props) => this.buildDateTimeColumn(props)}
            />
          )}
          {this.state.inRecycleBin && (
            <Column
              className="driveItemCell"
              field="deletedOn"
              title={this.props.i18n.columnHeaderDeletedOnTitle}
              width="220"
              cell={(props) => this.buildFileDeletedOnColumn(props)}
            />
          )}
          <GridNoRecords>
            {this.props.i18n.noFilesOrFoldersMessage}
          </GridNoRecords>
        </Grid>
        {!this.state.dragging && !this.state.itemsSelected && (
          <Popup
            offset={this.offset}
            show={this.state.itemPopupOpen}
            popupClass={"context-popup-menu"}
            id={"contextPopupMenuId"}
          >
            <Menu
              vertical={true}
              style={{ display: "inline-block", padding: 0 }}
              onSelect={this.handleItemContextMenuOnSelect}
            >
              {this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemRestoreText}
                  data="Restore"
                  cssClass="context-popup-menuitem"
                />
              )}
              {this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemRemoveText}
                  data="Delete"
                  cssClass="context-popup-menuitem"
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemOpenInBrowserText}
                  data="OpenInBrowser"
                  cssClass="context-popup-menuitem"
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemOpenInAppText}
                  data="OpenInApp"
                  cssClass="context-popup-menuitem"
                  disabled={disableMenuItemOpenInApp}
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemDownloadText}
                  data="Download"
                  cssClass="context-popup-menuitem"
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem cssClass={"k-separator"} />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemRenameText}
                  data="Rename"
                  cssClass="context-popup-menuitem"
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemCopyText}
                  data="Copy"
                  cssClass="context-popup-menuitem"
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemRemoveText}
                  data="Remove"
                  cssClass="context-popup-menuitem"
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemMoveText}
                  data="Move"
                  cssClass="context-popup-menuitem"
                />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem cssClass={"k-separator"} />
              )}
              {!this.state.inRecycleBin && (
                <MenuItem
                  text={this.props.i18n.contextMenuItemShareText}
                  data="Share"
                  cssClass="context-popup-menuitem"
                />
              )}
            </Menu>
          </Popup>
        )}
        {!this.state.dragging && this.state.selectedItem && (
          <RenameDialog
            showRenameDialog={this.state.showRenameDialog}
            item={this.state.selectedItem}
            onClose={this.handleCloseRenameDialog.bind(this)}
            onSave={this.handleSaveRenameDialog.bind(this)}
          />
        )}
        {!this.state.dragging &&
          this.state.messageDialogContext !== undefined && (
            <MessageDialog
              showDialog={true}
              messageContext={this.state.messageDialogContext}
              onCloseEvent={this.handleCloseMessageDialog.bind(this)}
              onButtonConfirmClickedEvent={this.handleConfirmMessageDialog.bind(
                this
              )}
            />
          )}
        {this.state.shareDialogContext !== undefined && (
          <ShareDialog
            shareContext={this.state.shareDialogContext}
            onCloseEvent={this.handleCloseShareDialog.bind(this)}
            onCreateShareLink={this.handleCreateShareLink.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Localization.translateComponent<
  IDriveItemsListProps,
  IDriveItemsListState
>("DriveItemsList")(DriveItemsList);
