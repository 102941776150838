import * as React from "react";

export interface LoadingIndicatorProps {
  message?: string;
}

export default class LoadingIndicator extends React.Component<
  LoadingIndicatorProps
> {
  /**
   * Render()
   */
  render() {
    const { message } = this.props;

    return (
      <div className="loading-body">
        <div className="loading-wrapper">
          <div className="loading-container">
            <span className="k-icon k-i-loading" />
            <span className="loading-text font-weight-standard">{message}</span>
          </div>
        </div>
      </div>
    );
  }
}
