import * as msal from "@azure/msal-browser";
import CacheHelper from "./cache";

/*
 * Browser check variables
 * If you support IE, our recommendation is that you sign-in using Redirect APIs
 * If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
 */
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

let _pendingRequest: Promise<void>;
let _msalInteractionCacheKey: string;

let _msalApp: msal.PublicClientApplication;
let _msalConfig: msal.Configuration;
let _signInType: SignInType;
let _sessionCache: CacheHelper;
let _verboseLogging: boolean;

let _accountId: string;
let _resourceScope: string;

let ssoRequest: msal.SsoSilentRequest = {
  scopes: [],
};

let redirectRequest: msal.RedirectRequest = {
  scopes: [],
};

let popupRequest: msal.PopupRequest = {
  scopes: [],
};

export enum SignInType {
  REDIRECT,
  POPUP,
}

/**
 * Applies the specified msal configuration to the internal msal application.
 * @param config Msal configuration to apply
 * @param preferredSignInType Enum of type SignInType representing the preferred signin type.
 * @param verboseLogging Boolean to define verbose logging should be enabled or not.
 */
export const loadConfig = (
  config: msal.Configuration,
  preferredSignInType: SignInType,
  verboseLogging: boolean
) => {
  _msalApp = new msal.PublicClientApplication(config);
  _msalConfig = config;

  _verboseLogging = verboseLogging;
  _sessionCache = new CacheHelper("sessionStorage");

  _msalInteractionCacheKey = `msal.interaction.status`;
  _signInType = preferredSignInType;

  const account: msal.AccountInfo | undefined = getCurrentAccount();

  if (account) {
    _accountId = account.homeAccountId;
  }

  _resourceScope = `${window.location.origin.replace("https://", "api://")}/${
    _msalConfig.auth.clientId
  }/access_as_user`;

  ssoRequest = includeDomainHint({
    ...ssoRequest,
    scopes: [_resourceScope],
  });

  redirectRequest = includeDomainHint({
    ...redirectRequest,
    scopes: [_resourceScope],
  });

  popupRequest = includeDomainHint({
    ...popupRequest,
    scopes: [_resourceScope],
  });
};

/**
 * Returns an unique id representing the authenticated user.
 * @returns string containing an unique id if the user is authenticated, else undefined.
 */
export const getUniqueUserId = (): string | undefined => {
  const account: msal.AccountInfo | undefined = getCurrentAccount();

  if (account) {
    return account.localAccountId;
  }

  return undefined;
};

/**
 * Returns the username of the authenticated user.
 * @returns string containing the username if the user is authenticated, else undefined.
 */
export const getUsername = (): string | undefined => {
  const account: msal.AccountInfo | undefined = getCurrentAccount();

  if (account) {
    return account.username;
  }

  return undefined;
};

/**
 * Initiates the signin process for authenticating the user.
 * @returns Empty promise
 */
export const signIn = async (): Promise<void> => {
  if (hasPendingMsalInteraction()) {
    await waitForPendingRedirectToFinish();
  } else {
    const accounts: msal.AccountInfo[] = _msalApp.getAllAccounts();

    if (accounts.length === 1) {
      logEntry(
        msal.LogLevel.Info,
        `Only one account '${accounts[0].username}' available from the msal context. We will be using that one.`
      );
      return Promise.resolve();
    }

    const signInTypeToUse: SignInType = getSignInType();

    if (signInTypeToUse === SignInType.POPUP) {
      try {
        const resp = await _msalApp.loginPopup(popupRequest);
        return handleAuthenticationResponse(resp);
      } catch (error) {
        logEntry(msal.LogLevel.Error, error as string);
      }
    } else if (signInTypeToUse === SignInType.REDIRECT) {
      return _msalApp.loginRedirect(redirectRequest);
    }
  }

  return Promise.resolve();
};

/**
 * Initiates the signout process.
 */
export const signOut = () => {
  const logoutRequest = {
    account: _msalApp.getAccountByHomeId(_accountId),
  };
  _msalApp.logoutRedirect(logoutRequest);
  _accountId = "";
};

/**
 * Returns a deferred Promise containing a string with the access token which can be used for accessing the current resource.
 * @returns Deferred Promise
 */
export const getToken = async (): Promise<string> => {
  const currentAccount: msal.AccountInfo | undefined = getCurrentAccount();

  if (!currentAccount) {
    await signIn();
  } else {
    const signInTypeToUse: SignInType = getSignInType();
    let deferredResult: Promise<void | msal.AuthenticationResult>;

    if (signInTypeToUse === SignInType.POPUP) {
      deferredResult = acquireTokenPopup(popupRequest, currentAccount);
    } else if (signInTypeToUse === SignInType.REDIRECT) {
      deferredResult = acquireTokenRedirect(popupRequest, currentAccount);
    }

    return new Promise<string>((resolve, reject) => {
      deferredResult
        .then(
          (result: void | msal.AuthenticationResult) => {
            if (result) {
              resolve(result.accessToken);
            } else {
              logEntry(
                msal.LogLevel.Error,
                `Resolving an access token for resource '${_resourceScope}' resulted in an empty authentication result`
              );
              resolve(""); // We do this on purpose to keep the calling code clean
            }
          },
          (error) => {
            reject(error);
          }
        )
        .then((error) => {
          reject(error);
        });
    });
  }

  return Promise.resolve("");
};

/**
 * Wrapper function for perfoming authenticated fetch operations to the current resource.
 * @param url String with the url of the requested endpoint.
 * @param options Additional fetch options.
 * @returns Deferred Promise with the response data of the fetch operation.
 */
export const apiFetch = async (
  url: string,
  options: any = {}
): Promise<Response> => {
  const token: string = await getToken();
  const o = options || {};
  if (!o.headers) o.headers = {};
  o.headers.Authorization = `Bearer ${token}`;
  return fetch(url, o);
};

const hasPendingMsalInteraction = (): boolean => {
  if (_sessionCache.hasCacheEntry(_msalInteractionCacheKey)) {
    const cacheEntry: string | null = _sessionCache.getCacheEntry(
      _msalInteractionCacheKey
    );

    if (cacheEntry) {
      return cacheEntry === _msalConfig.auth.clientId;
    }
  }

  return false;
};

/**
 * Includes any domain hint info in the msal request instances.
 * @param requestInfo Various msal request instances (sso, redirect, popup)
 * @returns Enriched request instance
 */
const includeDomainHint = (requestInfo: any): any => {
  let search: URLSearchParams = new URLSearchParams(window.location.search);
  let domainHint: string | null = search.get("domain_hint");

  if (domainHint && domainHint.length > 0) {
    requestInfo = {
      ...requestInfo,
      domainHint: domainHint,
    };
  }

  return requestInfo;
};

/**
 * Gets the current msal AccountInfo instance if the user is authenticated
 * @returns msal AccountInfo instance if the user is authenticated, else undefined.
 */
const getCurrentAccount = (): msal.AccountInfo | undefined => {
  if (_accountId) {
    return _msalApp.getAccountByHomeId(_accountId) || undefined;
  } else {
    const accounts: msal.AccountInfo[] = _msalApp.getAllAccounts();

    if (accounts.length === 1) {
      logEntry(
        msal.LogLevel.Info,
        `Only one account '${accounts[0].username}' available from the msal context. We will be using that one.`
      );
      return accounts[0];
    } else {
      if (accounts.length > 1) {
        logEntry(
          msal.LogLevel.Warning,
          "Multiple accounts exist in the msal context. Unable to determine the corresponding user."
        );
      } else {
        logEntry(
          msal.LogLevel.Warning,
          "No account found in the msal context. You need to signin first."
        );
      }

      return undefined;
    }
  }
};

/**
 * Returns a deferred Promise to wait for the pending redirect request to finish.
 * @returns Empty Promise
 */
const waitForPendingRedirectToFinish = (): Promise<void> => {
  if (!_pendingRequest) {
    _pendingRequest = new Promise<void>((resolve, reject) => {
      _msalApp
        .handleRedirectPromise()
        .then((result: msal.AuthenticationResult | null) => {
          if (result && result.account) {
            _accountId = result.account.homeAccountId;
            resolve();
          } else {
            logEntry(
              msal.LogLevel.Info,
              "Still waiting for the pending request to finish"
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return _pendingRequest;
};

/**
 * Processes the authentication reponse after the authorization flow finishes.
 * @param resp msal AuthenticationResult instance.
 */
const handleAuthenticationResponse = (
  resp: msal.AuthenticationResult | null
) => {
  if (resp && resp.account) {
    _accountId = resp.account.homeAccountId;
  } else {
    // need to call getAccount here?
    const currentAccounts = _msalApp.getAllAccounts();
    if (!currentAccounts || currentAccounts.length < 1) {
      signIn();
    } else if (currentAccounts.length > 1) {
      logEntry(
        msal.LogLevel.Error,
        "Unable to determine which msal account needs to be used for this resource."
      );
    } else if (currentAccounts.length === 1) {
      _accountId = currentAccounts[0].homeAccountId;
    }
  }
};

/**
 * Gets the proper SignInType type instance depending of the preference.
 * @returns SignInType representing the signin type to use.
 */
const getSignInType = (): SignInType => {
  return isIE ? SignInType.REDIRECT : _signInType;
};

/**
 * Returns a deferred Promise containing the authentication result using the popup scenario.
 * @param request msal PopupRequest instance
 * @param account msal account for which a token is requested.
 * @returns Deferred Promise containiong the authentication result.
 */
const acquireTokenPopup = async (
  request: msal.PopupRequest,
  account: msal.AccountInfo
): Promise<void | msal.AuthenticationResult> => {
  request.account = account;
  return await _msalApp.acquireTokenSilent(request).catch(async (error) => {
    logEntry(msal.LogLevel.Info, "silent token acquisition fails.");
    if (error instanceof msal.InteractionRequiredAuthError) {
      logEntry(msal.LogLevel.Info, "acquiring token using popup");
      return _msalApp.acquireTokenPopup(request).catch((error) => {
        logEntry(msal.LogLevel.Error, error);
      });
    } else {
      logEntry(msal.LogLevel.Error, error);
    }
  });
};

/**
 * Returns a deferred Promise containing the authentication result using the redirect scenario.
 * @param request msal c instance
 * @param account msal account for which a token is requested.
 * @returns Deferred Promise containiong the authentication result.
 */
const acquireTokenRedirect = async (
  request: msal.RedirectRequest,
  account: msal.AccountInfo
): Promise<void | msal.AuthenticationResult> => {
  request.account = account;
  return await _msalApp.acquireTokenSilent(request).catch(async (error) => {
    logEntry(msal.LogLevel.Info, "silent token acquisition fails.");
    if (error instanceof msal.InteractionRequiredAuthError) {
      logEntry(msal.LogLevel.Info, "acquiring token using redirect");
      _msalApp.acquireTokenRedirect(request);
    } else {
      logEntry(msal.LogLevel.Error, error);
    }
  });
};

/**
 * Logs the message using the specified log level.
 * @param level LogLevel to use
 * @param message String containing the message to log
 * @param data Object to include in the log message
 * @param containsPii Boolean which represents if personal identification information is present in the logging info.
 */
const logEntry = (
  level: msal.LogLevel,
  message: string,
  data?: object,
  containsPii?: boolean
) => {
  if (containsPii) {
    return;
  }

  switch (level) {
    case msal.LogLevel.Error:
      if (data) {
        console.error(message, data);
      } else {
        console.error(message);
      }
      return;
    case msal.LogLevel.Info:
      if (data) {
        console.info(message, data);
      } else {
        console.info(message);
      }
      return;
    case msal.LogLevel.Verbose:
      if (_verboseLogging) {
        if (data) {
          console.debug(message, data);
        } else {
          console.debug(message);
        }
      }
      return;
    case msal.LogLevel.Warning:
      if (data) {
        console.warn(message, data);
      } else {
        console.warn(message);
      }
      return;
  }
};
