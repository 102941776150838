const colors: string[] = ["blue", "orange", "purple", "red"];

export function calculateColor(userPrincipalName: string): string {
  var sum = 0;
  var colorId = 0;

  for (let index = 0; index < userPrincipalName.length; index++) {
    if (!isNaN(userPrincipalName.charCodeAt(index))) {
      sum += userPrincipalName.charCodeAt(index);
    }
  }

  colorId = sum % colors.length;

  return colors[colorId];
}
