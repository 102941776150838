import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";

import {
  ILicensingService,
  ILicensingContext,
  LicensingServiceFactory,
} from "@iexpertsnl/licensing-core";
import { IAppMetadata } from "./data/interfaces";
import * as msal from "@azure/msal-browser";
import * as authContext from "./msal";

const licenseCheck = (): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const licensingService: ILicensingService =
      LicensingServiceFactory.GetService(
        {
          enableLogging: true,
          proxyRequestHost: window.location.host,
        },
        await authContext.getToken()
      );

    const userId: string | undefined = authContext.getUniqueUserId();

    if (userId) {
      licensingService
        .AcquireLicenseForProduct("fileexplorer", userId)
        .then((licensingContext: ILicensingContext | undefined) => {
          resolve(licensingContext ? licensingContext.isValid : false);
        })
        .catch((error) => reject(error));
    } else {
      resolve(false);
    }
  });
};

const initializeApp = () => {
  fetch(`${window.location.origin}/.metadata`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then(async (settings: IAppMetadata) => {
      const msalConfig: msal.Configuration = authContext.getConfig(
        settings.AppId
      );

      authContext.loadConfig(msalConfig, authContext.SignInType.REDIRECT, true);

      await authContext.signIn();

      licenseCheck().then((hasLicense: boolean) => {
        ReactDOM.render(
          <App
            context={{
              metadata: settings,
              hasProductLicense: hasLicense,
            }}
          />,
          document.getElementById("root") as HTMLElement
        );
      });
    });

  // Prevent default DnD browser behaviour
  window.addEventListener(
    "dragover",
    function (e) {
      var event: Event | undefined;
      e = e || event;
      e.preventDefault();

      let dt: DataTransfer = e.dataTransfer as DataTransfer;
      dt.dropEffect = "none";
    },
    false
  );
  window.addEventListener(
    "drop",
    function (e) {
      var event: Event | undefined;
      e = e || event;
      e.preventDefault();
    },
    false
  );
};

initializeApp();
