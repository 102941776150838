import * as React from "react";
import { Modal, Icon, IconButton, IIconProps } from "office-ui-fabric-react";
import * as Localization from "./Translate";
import {
  IShareDialogContext,
  ISharingLinkData,
  IErrorResult,
  IDriveItem,
  ITranslationProps
} from "../data/interfaces";
import copy from "copy-to-clipboard";
import { GetSiteOrOneDriveUrl } from "../helpers/globalHelper";

export interface IShareDialogProps extends ITranslationProps {
  shareContext: IShareDialogContext;
  onCloseEvent(): void;
  onCreateShareLink(
    item: IDriveItem,
    siteOrOneDriveUrl: string,
    driveIsOneDrive: boolean
  ): Promise<ISharingLinkData>;
}

export interface IShareDialogState {
  isDraggable: boolean;
  isCopyInactive: boolean;
  isCopyActive: boolean;
  isCopySuccess: boolean;
}

const linkIcon: IIconProps = { iconName: "Link12" };

class ShareDialog extends React.Component<
  IShareDialogProps,
  IShareDialogState
> {
  constructor(props: IShareDialogProps) {
    super(props);
    this.state = {
      isDraggable: false,
      isCopyInactive: true,
      isCopyActive: false,
      isCopySuccess: false
    };
  }
  private handleOnDismissModal() {
    this.props.onCloseEvent();
  }

  private handleOnClickCopyLink() {
    this.setState({ isCopyInactive: false, isCopyActive: true }, () => {
      const item: IDriveItem = this.props.shareContext.item;

      const siteOrOneDriveUrl: string = GetSiteOrOneDriveUrl(item);

      this.props
        .onCreateShareLink(item, siteOrOneDriveUrl, item.driveIsOneDrive)
        .then((result: ISharingLinkData) => {
          // Copy the link to the clipboard
          this.copyCodeToClipboard(result.webUrl);
          this.setState({ isCopyActive: false, isCopySuccess: true });
        })
        .catch((error: IErrorResult) => {
          console.error(
            "Exception in ShareDialog.handleOnClickCopyLink for item: ",
            item.displayName,
            error
          );
          this.setState({ isCopyInactive: true, isCopyActive: false });
          this.handleOnDismissModal();
        });
    });
  }

  copyCodeToClipboard = (webUrl: string) => {
    if (navigator.clipboard) {
      // Chrome
      navigator.clipboard.writeText(webUrl);
    } else {
      // Other browsers
      copy(webUrl);
    }

    this.setState({ isCopySuccess: true });
  };

  render() {
    const { isCopyInactive, isCopyActive, isCopySuccess } = this.state;
    if (isCopyInactive) {
      return (
        <div>
          <Modal
            isOpen={true}
            onDismiss={this.handleOnDismissModal.bind(this)}
            isModeless={false}
            containerClassName="shareDialog-modal"
            isBlocking={true}
          >
            <div className="shareDialog-header">
              <span className="shareDialog-header-title">
                {this.props.i18n.dialogTitle}
              </span>
              <button
                type="button"
                className="shareDialog-closebutton"
                onClick={this.handleOnDismissModal.bind(this)}
              >
                <Icon iconName="ChromeClose" className="icon" />
              </button>
            </div>
            <div className="shareDialog-content">
              <IconButton
                iconProps={linkIcon}
                title={this.props.i18n.dialogTitle}
                className="shareDialog-iconButton"
                onClick={this.handleOnClickCopyLink.bind(this)}
              />
              <div className="shareDialog-text">
                {this.props.i18n.copyShareLinkToClipboardText}
              </div>
            </div>
          </Modal>
        </div>
      );
    } else if (isCopyActive) {
      return (
        <Modal
          isOpen={true}
          onDismiss={this.handleOnDismissModal.bind(this)}
          isModeless={false}
          containerClassName="shareDialog-modal"
          isBlocking={true}
        >
          <div className="shareDialog-header">
            <span className="shareDialog-header-title">
              {this.props.i18n.dialogTitle}
            </span>
          </div>
          <div className="shareDialog-content">
            <div className="k-icon k-i-loading"></div>
            <div className="shareDialog-text">
              {this.props.i18n.creatingLinkMessage}
            </div>
          </div>
        </Modal>
      );
    } else if (isCopySuccess) {
      return (
        <Modal
          isOpen={true}
          onDismiss={this.handleOnDismissModal.bind(this)}
          isModeless={false}
          containerClassName="shareDialog-modal"
          isBlocking={true}
        >
          <div className="shareDialog-header">
            <span className="shareDialog-header-title">
              {this.props.i18n.dialogTitle}
            </span>
            <button
              type="button"
              className="shareDialog-closebutton"
              onClick={this.handleOnDismissModal.bind(this)}
            >
              <Icon iconName="ChromeClose" className="icon" />
            </button>
          </div>
          <div className="shareDialog-linkCopied">
            <div className="shareDialog-linkCopied-icon">
              <Icon iconName="CheckMark" className="icon"></Icon>
            </div>
            <div className="shareDialog-text">
              {this.props.i18n.createdLinkMessage}
            </div>
          </div>
        </Modal>
      );
    }
  }
}

export default Localization.translateComponent<
  IShareDialogProps,
  IShareDialogState
>("ShareDialog")(ShareDialog);
