import React from "react";
import BreadCrumbNavigation from "./BreadCrumbNavigation";

export interface ITopNavigationBarProps {
  items: ITopNavigationItem[];
  navigationChanged(key: string, index: number, instance: any): void;
}

export interface ITopNavigationItem {
  title: string;
  key: string;
  static?: boolean;
  dataset?: any;
  instance?: any;
}

class TopNavigationBar extends React.Component<ITopNavigationBarProps> {
  constructor(props: ITopNavigationBarProps) {
    super(props);

    this.state = {};
  }
  private onBreadcrumbNavigationChanged(key: string): void {
    let index: number = this.props.items.findIndex(
      (item: ITopNavigationItem) => {
        return item.key === key;
      }
    );

    this.props.navigationChanged(key, index, this.props.items[index].instance);
  }

  render() {
    return (
      <div>
        <BreadCrumbNavigation 
          elements={this.props.items}
          navigationChanged={this.onBreadcrumbNavigationChanged.bind(this)}
        />
      </div>
    );
  }
}

export default TopNavigationBar;
