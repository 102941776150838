// temporary placeholder
import {
  IClient,
  ITemplate,
  OfficeHostType,
  ISite,
  IDocumentLibrary,
  IDriveItemList,
} from "@iexpertsnl/templates-ui";
import {
  ILicensingService,
  ILicensingContext,
  LicensingServiceFactory,
} from "@iexpertsnl/licensing-core";
import * as authContext from "../msal";
import ServiceClient from "./ServiceClient";
import { IDriveItem, IErrorResult } from "./interfaces";
import natsort from "natsort";

class Settings {
  public name: string | undefined;
  public templatesSite: string | undefined;
  public templatesDocumentLibrary: string | undefined;
}

export class Client implements IClient {
  private serviceClient: ServiceClient;
  private static client: Client;
  private settings: Settings | undefined;

  private constructor(client: ServiceClient) {
    this.serviceClient = client;
  }

  public static async GetClient(serviceClient: ServiceClient): Promise<Client> {
    if (Client.client) return Client.client;

    Client.client = new Client(serviceClient);
    let success = await Client.client.LoadSettings();
    if (!success) {
      (Client.client as any) = undefined;
      throw new Error("Failed to initialize client");
    }

    return Client.client;
  }

  private async LoadSettings(): Promise<boolean> {
    try {
      let settings = await this.serviceClient.Get<Settings>(
        `templates/settings/tenant`
      );
      this.settings = settings;
      if (this.settings) {
        if (!this.settings.templatesSite) this.settings.templatesSite = "root";
        if (!this.settings.templatesDocumentLibrary)
          this.settings.templatesDocumentLibrary = "Templates";

        return true;
      }
    } catch {}

    return false;
  }

  public async GetLicense(): Promise<boolean> {
    try {
      const licensingService: ILicensingService =
        LicensingServiceFactory.GetService(
          {
            enableLogging: true,
            proxyRequestHost: window.location.host,
          },
          await authContext.getToken()
        );

      const userId: string | undefined = authContext.getUniqueUserId();
      if (userId) {
        let licensingContext: ILicensingContext =
          await licensingService.AcquireLicenseForProductAndFeatures(
            "easymail",
            ["word-templates"],
            userId || ""
          );

        return licensingContext.isValid || false;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  // ToDo: move cache things to NPM package
  // expiration in minutes
  private SetCache(key: string, value: string, expiration: number = 0) {
    try {
      localStorage.setItem(key, value);
      if (expiration > 0) {
        let expires = Date.now() + expiration * 60 * 1000;
        localStorage.setItem(key + "_exp", expires.toString());
      }
    } catch {}
  }

  private GetCache(key: string) {
    try {
      let exp = localStorage.getItem(key + "_exp");
      let value = localStorage.getItem(key);
      if (exp) {
        try {
          let expiration = parseInt(exp, 10);
          if (Date.now() < expiration) {
            return value;
          }
        } catch {
          return value;
        }
      } else {
        return value;
      }
    } catch {}

    return undefined;
  }

  private async loadListItems(
    siteId: string,
    listId: string
  ): Promise<ITemplate[]> {
    let cache = this.GetCache("Templates");
    if (cache) {
      try {
        let cachedTemplates: ITemplate[] = JSON.parse(cache);
        if (cachedTemplates && cachedTemplates.length > 0)
          return cachedTemplates;
      } catch {}
    }

    const site = await this.serviceClient.Get<any>(`templates/sites/${siteId}`);
    const templateResult = await this.serviceClient.Get<any>(
      `templates/sites/${siteId}/lists/${listId}/items?&top=2000&orderBy=fields/Title&withDriveItem=true`
    );

    let templates: ITemplate[] = [];
    if (templateResult.count > 0 && templateResult.items) {
      templateResult.items.forEach((item: any) => {
        try {
          let name = item.fields["LinkFilename"];
          let hostType = OfficeHostType.Unknown;
          if (item.fields["FileLeafRef"].endsWith("docx")) {
            hostType = OfficeHostType.Word;
            name = name.replace(".docx", "");
          } else if (item.fields["FileLeafRef"].endsWith("xlsx")) {
            hostType = OfficeHostType.Excel;
            name = name.replace(".xlsx", "");
          } else if (item.fields["FileLeafRef"].endsWith("pptx")) {
            hostType = OfficeHostType.PowerPoint;
            name = name.replace(".pptx", "");
          }

          let imgURL = "";
          try {
            // drive thumnail preview
            let thumbnails = item.driveItem["thumbnails"];
            if (item.fields["Image"]) {
              let imgObj = JSON.parse(item.fields["Image"]);
              imgURL = imgObj.serverUrl + imgObj.serverRelativeUrl;
            } else if (thumbnails && thumbnails.length > 0) {
              imgURL = thumbnails[0].medium.url;
            }
          } catch {}

          let docPreview = "";
          try {
            let etag: string = item.fields["@odata.etag"];
            if (etag && site.webUrl) {
              let docID = etag.replace('"', "").split(",")[0];
              docPreview = `${site.webUrl}/_layouts/15/Doc.aspx?sourcedoc={${docID}}&action=interactivepreview`;
            }
          } catch {}

          let tags: string[] = [];
          try {
            let keywordString = item.fields["Keywords"];
            let keywords = keywordString.split(";");
            keywords.forEach((element: string) => {
              tags.push(element.trim());
            });
          } catch {}

          let template: ITemplate = {
            id: item.driveItem["id"],
            title: name,
            name: item.fields["FileLeafRef"],
            description: item.fields["Description"],
            parentReference: {
              driveId: item.driveItem["driveId"],
              siteId: item.driveItem["siteId"],
              folderId: item.driveItem["parentId"],
            },
            webURL: item.driveItem["webUrl"],
            docPreviewUrl: docPreview,
            hostType: hostType,
            imageURL: imgURL,
            tags: tags,
          };

          templates.push(template);
        } catch {}
      });
    }

    const sorter = natsort({ insensitive: true });
    templates.sort((a: ITemplate, b: ITemplate) => {
      return sorter(a.name, b.name);
    });

    this.SetCache("Templates", JSON.stringify(templates), 30);
    return templates;
  }

  public async GetWordTemplates(): Promise<ITemplate[]> {
    try {
      if (this.settings) {
        let templates: ITemplate[] = await this.loadListItems(
          this.settings.templatesSite || "",
          this.settings.templatesDocumentLibrary || ""
        );
        let wordTemplates = templates.filter(
          (t) => t.hostType == OfficeHostType.Word
        );

        return wordTemplates;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  public async GetPowerPointTemplates(): Promise<ITemplate[]> {
    try {
      if (this.settings) {
        let templates: ITemplate[] = await this.loadListItems(
          this.settings.templatesSite || "",
          this.settings.templatesDocumentLibrary || ""
        );
        let wordTemplates = templates.filter(
          (t) => t.hostType == OfficeHostType.PowerPoint
        );

        return wordTemplates;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  public async GetExcelTemplates(): Promise<ITemplate[]> {
    try {
      if (this.settings) {
        let templates: ITemplate[] = await this.loadListItems(
          this.settings.templatesSite || "",
          this.settings.templatesDocumentLibrary || ""
        );
        let wordTemplates = templates.filter(
          (t) => t.hostType == OfficeHostType.Excel
        );

        return wordTemplates;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  public async CopyFile(
    source: ITemplate,
    target: ITemplate
  ): Promise<ITemplate> {
    let result = await this.serviceClient.Post<IDriveItem | IErrorResult>(
      "templates/copy",
      {
        DriveId: source.parentReference.driveId,
        ItemIds: [source.id],
        ItemNames: [source.name],
        DriveIsOneDrive: false, // not relevant for template copy
        destinationDriveId: target.parentReference.driveId,
        destinationItemId: target.parentReference.folderId,
        destinationIsOneDrive: false,
        destinationPath: "",
        destinationNames: [target.name],
      }
    );

    if ((result as IErrorResult).internalErrorCode !== undefined) {
      throw new Error("Failed to save file");
    } else {
      let resultFile: ITemplate = {
        ...target,
        id: (result as IDriveItem).id,
        webURL: (result as IDriveItem).webUrl,
        name: (result as IDriveItem).name,
        parentReference: {
          driveId: (result as IDriveItem).driveId,
          folderId: (result as IDriveItem).folderPath,
        },
      };

      return resultFile;
    }
  }

  /** Not used for FileExplorer but required for Client */

  public async GetSitesForUser(): Promise<ISite[]> {
    let sites: ISite[] = [];
    return sites;
  }

  public async GetDrivesForSite(siteId: string): Promise<IDocumentLibrary[]> {
    let drives: IDocumentLibrary[] = [];
    return drives;
  }

  public async GetFoldersInDrive(
    siteId: string,
    driveId: string,
    folderPath?: string,
    top?: number,
    pageToken?: string
  ): Promise<IDriveItemList> {
    return { items: [], count: 0, nextPageToken: "" };
  }
}
