import * as React from "react";
import {
  MessageBar as Message,
  MessageBarType as MessageType,
} from "office-ui-fabric-react/lib/MessageBar";
import { Link } from "office-ui-fabric-react/lib/Link";
import { IMessageBarContext } from "../data/interfaces";
import { MessageBarType } from "../data/enums";

export interface IMessageBarProps {
  messageContext?: IMessageBarContext;
  onClose?(): void;
}

class MessageBar extends React.Component<IMessageBarProps, {}> {
  private handleClose() {
    if (this.props.onClose !== undefined) {
      this.props.onClose();
    }
  }

  render() {
    const { messageContext } = this.props;

    let message: JSX.Element;

    if (messageContext === undefined) return null;
    else {
      let messageType: MessageType | undefined = undefined;
      switch (messageContext.type) {
        case MessageBarType.Info:
          messageType = MessageType.info;
          break;
        case MessageBarType.Error:
          messageType = MessageType.error;
          break;
        case MessageBarType.Success:
          messageType = MessageType.success;
          break;
        case MessageBarType.Warning:
          messageType = MessageType.warning;
          break;
        default:
          messageType = MessageType.info;
          break;
      }

      if (messageContext.messageJSXFormed)
        message = messageContext.messageJSXFormed;
      else message = <span>{messageContext.message}</span>;

      return (
        <div>
          <Message
            className="messageBar"
            messageBarType={messageType}
            isMultiline={true}
            onDismiss={
              this.props.onClose !== undefined
                ? this.handleClose.bind(this)
                : undefined
            }
          >
            {message}
            {messageContext.link !== undefined && (
              <Link href={"http://" + messageContext.link.url} target="_blank">
                {messageContext.link.text}
              </Link>
            )}
          </Message>
        </div>
      );
    }
  }
}

export default MessageBar;
