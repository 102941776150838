import React from "react";
import { Popup } from "@progress/kendo-react-popup";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Icon, IconType } from "office-ui-fabric-react/lib/Icon";
import { IDriveItem, ITranslationProps, IBaseItem } from "../data/interfaces";
import ReactDOM from "react-dom";
import { ValidateName, Guid } from "../helpers/globalHelper";
import * as Localization from "./Translate";
import { HierarchialDataScope, MessageBarType } from "../data/enums";
import { isDriveItem, isRecycleBin } from "../helpers/typeCheck";
import { ModificationType } from "./ItemModificationQueue";

export interface IActionBarProps extends ITranslationProps {
  loadedContainer?: IBaseItem;
  itemsSelected?: boolean;
  language: string; // LCID string like nl-NL (language-country)

  RefreshItemListEvent(): void;
  DeleteSelectedItemsEvent(): void;

  EmptyRecycleBinEvent(): void;
  RestoreRecycleBinItemEvent(): void;

  MoveSelectedItemsEvent(): void;
  CopySelectedItemsEvent(): void;

  allowShowEasyTemplate: boolean;
  EasyTemplateOpenEvent(): void;

  FolderCreationEvent(body: any): Promise<IDriveItem>;
  FolderCreatingEvent(items: IBaseItem): void;
  FolderCreatedEvent(items: IBaseItem): void;

  DocumentCreationEvent(body: any): Promise<IDriveItem>;
  DocumentCreatingEvent(item: IBaseItem): void;
  DocumentCreatedEvent(item: IBaseItem): void;

  FileCreationEvent(formData: FormData): Promise<IDriveItem>;
  FileAddedEvent(items: IBaseItem): void;
  FilesAddingEvent(items: IBaseItem[]): void;
  DummyFileRemoveEvent(
    item: IBaseItem,
    modificationType: ModificationType
  ): void;

  showMessageBar(message: string, messageBarType: MessageBarType): void;
  closeMessageBar(): void;
}

interface IDocumentType {
  text: string;
  icon: string;
  extension: string;
}

interface IActionBarState {
  addFilePopupOpen: boolean;
  addFolderPopupOpen: boolean;
  newDocumentPopupOpen: boolean;
  newDocumentType: IDocumentType;
  isProcessing: boolean;
  buttonCreateDisabled: boolean;
  showErrorMessageEmpty: boolean;
  showErrorMessageRegEx: boolean;
  newDocumentOrFoldername: string;
  inRecycleBin: boolean;
  itemsSelected: boolean;
}

class ActionBar extends React.Component<IActionBarProps, IActionBarState> {
  addFileAnchor: HTMLButtonElement | null;
  addFolderAnchor: HTMLButtonElement | null;
  newDocumentAnchor: HTMLSpanElement | null;
  private documentTypeItems: IDocumentType[];
  private fileSelector: any;

  constructor(props: IActionBarProps) {
    super(props);

    this.documentTypeItems = [
      { text: "Word", icon: "WordDocument", extension: "docx" },
      { text: "Excel", icon: "ExcelDocument", extension: "xlsx" },
      { text: "PowerPoint", icon: "PowerPointDocument", extension: "pptx" },
    ];

    this.addFileAnchor = null;
    this.addFolderAnchor = null;
    this.newDocumentAnchor = null;
    this.state = {
      addFilePopupOpen: false,
      addFolderPopupOpen: false,
      newDocumentPopupOpen: false,
      isProcessing: false,
      newDocumentType: this.documentTypeItems[0],
      buttonCreateDisabled: true,
      showErrorMessageEmpty: false,
      showErrorMessageRegEx: false,
      newDocumentOrFoldername: "",
      inRecycleBin: false,
      itemsSelected: this.props.itemsSelected
        ? this.props.itemsSelected
        : false,
    };
  }

  public componentDidMount() {
    /*
        When using React you should generally not need to call addEventListener to add 
        listeners to a DOM element after it is created. Instead, just provide a listener 
        when the element is initially rendered.
        https://reactjs.org/docs/handling-events.html
        */
    document.addEventListener("click", this.handleClickOutside, true);
    document.addEventListener("keydown", this.handleEscapeKey, true);

    if (typeof this.props.loadedContainer !== "undefined") {
      if (isRecycleBin(this.props.loadedContainer)) {
        this.setState({
          inRecycleBin: true,
        });
      } else {
        this.setState({
          inRecycleBin: false,
        });
      }
    }

    this.fileSelector = this.buildFileSelector();
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
    document.removeEventListener("keydown", this.handleEscapeKey, true);
  }

  public componentDidUpdate(prevProps: IActionBarProps) {
    if (this.props.itemsSelected !== prevProps.itemsSelected) {
      this.setState({
        itemsSelected: this.props.itemsSelected
          ? this.props.itemsSelected
          : false,
      });
    }

    if (this.props.loadedContainer !== prevProps.loadedContainer) {
      if (typeof this.props.loadedContainer !== "undefined") {
        if (isRecycleBin(this.props.loadedContainer)) {
          this.setState({
            inRecycleBin: true,
          });
        } else {
          this.setState({
            inRecycleBin: false,
          });
        }
      }
    }

    const elements = Array.from(
      document.getElementsByClassName("k-button k-upload-button")
    );
    if (elements.length > 0) {
      for (const element of elements) {
        element.setAttribute("title", "");
      }
    }
  }

  private buildFileSelector() {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("multiple", "multiple");
    fileSelector.addEventListener(
      "change",
      this.handleFileSelectorChange,
      true
    );

    return fileSelector;
  }

  private getDummyItems(rawFiles: FileList): IBaseItem[] {
    let newItems: IBaseItem[] = [];

    if (this.props.loadedContainer !== undefined) {
      for (let index = 0; index < rawFiles.length; index++) {
        let dummyItem: IBaseItem = this.getSkeletonInstance(
          this.props.loadedContainer
        );

        newItems.push({
          ...dummyItem,
          name: rawFiles[index].name,
          displayName: rawFiles[index].name,
          isContainer: false,
          size: rawFiles[index].size,
        } as IDriveItem);
      }
    }

    return newItems;
  }

  private handleFileSelectorChange = async (event: any): Promise<void> => {
    this.props.closeMessageBar();

    const files: FileList = event.target.files;

    // Pre-check (files not bigger than 4294967295 bytes).
    let isFileTooBig: boolean = false;

    for (let index = 0; index < files.length; index++) {
      if (files[index].size > 4294967295) {
        isFileTooBig = true;
        break;
      }
    }

    if (isFileTooBig) {
      this.props.showMessageBar(
        this.props.i18n.fileTooLargeMessage,
        MessageBarType.Warning
      );
      return;
    }

    if (files && files.length > 0 && this.props.loadedContainer !== undefined) {
      const dummyItems: IBaseItem[] = this.getDummyItems(files);
      this.props.FilesAddingEvent(dummyItems);

      for (let index = 0; index < files.length; index++) {
        let formData = new FormData();

        formData.set("file", files[index]);

        formData.append(
          "DriveId",
          isDriveItem(this.props.loadedContainer)
            ? (this.props.loadedContainer as IDriveItem).driveId
            : this.props.loadedContainer.id
        );
        formData.append("ParentId", this.props.loadedContainer.id);
        formData.append(
          "FolderPath",
          this.props.loadedContainer.path
            ? `/${this.props.loadedContainer.path}`
            : ""
        );

        if (dummyItems[index].referenceId !== undefined) {
          formData.append(
            "ReferenceId",
            dummyItems[index].referenceId as string
          );
        }

        if (
          this.props.loadedContainer.rootScope ===
          HierarchialDataScope.OneDrives
        )
          formData.append("DriveIsOneDrive", "true");
        else formData.append("DriveIsOneDrive", "false");

        formData.append("Overwrite", "false");

        this.props
          .FileCreationEvent(formData)
          .then((result: IDriveItem) => {
            if (this.props.loadedContainer === undefined) return;
            this.props.FileAddedEvent({
              ...result,
              displayName: result.name,
              rootScope: this.props.loadedContainer.rootScope,
              isAdding: false,
              isContainer: false,
              path: result["folderPath"],
              parentItem: this.props.loadedContainer,
            });
          })
          .catch(() => {
            this.props.DummyFileRemoveEvent(
              dummyItems[index], // Index of dummyitems object is identical to files object because these are based on the number of files.
              ModificationType.ADD
            );
          });
      }
    }
  };

  private handleClickOutside = (event: any) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      const filePopupNode = document.getElementById("add-file-popup"),
        folderPopupNode = document.getElementById("add-folder-popup"),
        documentPopupNode = document.getElementById("new-document-popup");

      let newState: IActionBarState = this.state;

      if (
        filePopupNode !== null ||
        folderPopupNode !== null ||
        documentPopupNode !== null
      ) {
        if (filePopupNode && !filePopupNode.contains(event.target)) {
          newState.addFilePopupOpen = false;
        }

        if (folderPopupNode && !folderPopupNode.contains(event.target)) {
          newState.addFolderPopupOpen = false;
        }

        if (documentPopupNode && !documentPopupNode.contains(event.target)) {
          newState.newDocumentPopupOpen = false;
        }

        this.setState(newState);
      }
    }
  };

  private handleEscapeKey = (event: any) => {
    if (event.keyCode === 27) {
      this.setState({
        addFilePopupOpen: false,
        addFolderPopupOpen: false,
      });
    }
  };

  NewDocumentOnClickHandler = (documentType: IDocumentType) => {
    this.props.closeMessageBar();

    this.setState({
      addFilePopupOpen: false,
      addFolderPopupOpen: false,
      newDocumentPopupOpen: !this.state.newDocumentPopupOpen,
      newDocumentType: documentType,
      buttonCreateDisabled: true,
      showErrorMessageEmpty: false,
      showErrorMessageRegEx: false,
    });
  };

  AddFileOnClickHandler = () => {
    this.setState({
      addFilePopupOpen: !this.state.addFilePopupOpen,
      addFolderPopupOpen: false,
      newDocumentPopupOpen: false,
    });
  };

  AddFolderOnClickHandler = () => {
    this.props.closeMessageBar();

    this.setState({
      addFilePopupOpen: false,
      addFolderPopupOpen: !this.state.addFolderPopupOpen,
      newDocumentPopupOpen: false,
      buttonCreateDisabled: true,
      showErrorMessageEmpty: false,
      showErrorMessageRegEx: false,
    });
  };

  RefreshItemListOnClickHandler = () => {
    this.props.RefreshItemListEvent();
  };

  DeleteSelectedItemsHandler = () => {
    this.props.DeleteSelectedItemsEvent();
  };

  EmptyRecycleBinClickHandler = () => {
    this.props.EmptyRecycleBinEvent();
  };

  RestoreRecycleBinItemClickHandler = () => {
    this.props.RestoreRecycleBinItemEvent();
  };

  MoveSelectedItemsHandler = () => {
    this.props.MoveSelectedItemsEvent();
  };

  CopySelectedItemsHandler = () => {
    this.props.CopySelectedItemsEvent();
  };

  EasyTemplateOnClickHandler = () => {
    this.props.EasyTemplateOpenEvent();
  };

  private getSkeletonInstance(parentItem: IBaseItem): IBaseItem {
    return {
      id: "",
      referenceId: Guid.newGuid(),
      displayName: "",
      parentItem: parentItem,
      rootScope: parentItem.rootScope,
      children: [],
      isAdding: true,
      isUpdating: false,
      isDeleting: false,
      isExpanded: false,
      isDirty: true,
      indexPath: [],
      isLoaded: false,
      isLoading: false,
      isContainer: false,
      isMoving: false,
    } as IBaseItem;
  }

  handleNewFolderSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.loadedContainer !== undefined) {
      const { newDocumentOrFoldername: folderName } = this.state;

      if (this.props.loadedContainer === undefined) return;

      let dummyItem: IBaseItem = this.getSkeletonInstance(
        this.props.loadedContainer
      );

      dummyItem = {
        ...dummyItem,
        name: folderName,
        displayName: folderName,
        isContainer: true,
      } as IDriveItem;

      this.props.FolderCreatingEvent(dummyItem);

      const data = {
        Name: folderName,
        DriveId: isDriveItem(this.props.loadedContainer)
          ? (this.props.loadedContainer as IDriveItem).driveId
          : this.props.loadedContainer.id,
        ParentId: this.props.loadedContainer.id,
        FilePath:
          this.props.loadedContainer.path !== undefined
            ? this.props.loadedContainer.path
            : undefined,
        ReferenceId:
          dummyItem.referenceId !== undefined
            ? dummyItem.referenceId
            : undefined,
        IsOneDrive:
          this.props.loadedContainer.rootScope ===
          HierarchialDataScope.OneDrives
            ? "true"
            : "false",
      };

      this.props
        .FolderCreationEvent(data)
        .then((result: IDriveItem) => {
          if (this.props.loadedContainer === undefined) return;

          this.props.FolderCreatedEvent({
            ...result,
            displayName: result.name,
            rootScope: this.props.loadedContainer.rootScope,
            isAdding: false,
            isContainer: true,
            path: result["folderPath"],
            parentItem: this.props.loadedContainer,
          });
        })
        .catch(() => {
          console.error(
            "Exception in handleNewFolderSubmit for item: ",
            dummyItem
          );
          this.props.DummyFileRemoveEvent(dummyItem, ModificationType.ADD);
        });
    }

    this.setState({ addFolderPopupOpen: !this.state.addFolderPopupOpen });
  };

  handleNewDocumentSubmit = (event: any) => {
    event.preventDefault();

    if (this.props.loadedContainer !== undefined) {
      const { newDocumentOrFoldername: documentName } = this.state;

      let dummyItem: IBaseItem = this.getSkeletonInstance(
        this.props.loadedContainer
      );

      dummyItem = {
        ...dummyItem,
        name: `${documentName}.${this.state.newDocumentType.extension}`,
        displayName: `${documentName}.${this.state.newDocumentType.extension}`,
        isContainer: false,
        size: 0,
      } as IDriveItem;

      this.setState({
        isProcessing: true,
        newDocumentPopupOpen: !this.state.newDocumentPopupOpen,
      });

      this.props.DocumentCreatingEvent(dummyItem);

      const data = {
        Name: documentName,
        Type: this.state.newDocumentType.extension,
        DriveId: isDriveItem(this.props.loadedContainer)
          ? (this.props.loadedContainer as IDriveItem).driveId
          : this.props.loadedContainer.id,
        ParentId: this.props.loadedContainer.id,
        FolderPath:
          this.props.loadedContainer.path !== undefined
            ? this.props.loadedContainer.path
            : undefined,
        ReferenceId:
          dummyItem.referenceId !== undefined
            ? dummyItem.referenceId
            : undefined,
        IsOneDrive:
          this.props.loadedContainer.rootScope ===
          HierarchialDataScope.OneDrives
            ? "true"
            : "false",
      };

      this.props
        .DocumentCreationEvent(data)
        .then((result: IDriveItem) => {
          this.setState({
            isProcessing: false,
          });

          if (this.props.loadedContainer !== undefined) {
            this.props.DocumentCreatedEvent({
              ...result,
              displayName: result.name,
              rootScope: this.props.loadedContainer.rootScope,
              isAdding: false,
              isContainer: false,
              path: result["folderPath"],
              parentItem: this.props.loadedContainer,
            });
          }
        })
        .catch(() => {
          this.setState({
            isProcessing: false,
          });

          this.props.DummyFileRemoveEvent(dummyItem, ModificationType.ADD);
        });
    } else {
      this.setState({ isProcessing: false });
    }
  };

  handleChange = (event: any) => {
    const name: string = event.target.value.trim();
    let buttonCreateDisabled: boolean = false;
    let showErrorMessageEmpty: boolean = false;
    let showErrorMessageRegEx: boolean = false;
    let newDocumentOrFoldername: string = name;

    if (name.length === 0) {
      showErrorMessageEmpty = true;
      buttonCreateDisabled = true;
      newDocumentOrFoldername = "";
    } else if (!ValidateName(name)) {
      buttonCreateDisabled = true;
      showErrorMessageRegEx = true;
      newDocumentOrFoldername = "";
    }

    this.setState({
      newDocumentOrFoldername: newDocumentOrFoldername,
      buttonCreateDisabled: buttonCreateDisabled,
      showErrorMessageEmpty: showErrorMessageEmpty,
      showErrorMessageRegEx: showErrorMessageRegEx,
    });
  };

  handleFocus = (event: any) => {
    event.target.select();
  };

  handleFileSelect = (event: any) => {
    this.props.closeMessageBar();
    this.fileSelector.click();
  };

  private resolveDocumentTypeItemIcon(item: any): string {
    /*     
    Get the file type via this function cause slowness in building up the icons on the dropdownlist
    const fileExtension: string = item.extension;
    const iconName: string = this.iconHelper._getFileTypeIconNameFromExtension(
      fileExtension
    ); */
    const iconName: string = item.extension;
    const iconSize: number = 16; // supported types [16, 20, 32, 40, 48, 64, 96]
    const png: string = `https://spoprod-a.akamaihd.net/files/fabric/assets/item-types/${iconSize}/${iconName}.png`;
    return png;
  }

  render() {
    const documentTypeItemRender = (props: any) => {
      return (
        <div>
          <Icon
            iconName={props.item.icon}
            iconType={IconType.image}
            className="pr-2"
            imageProps={{ src: this.resolveDocumentTypeItemIcon(props.item) }}
          />
          <span className="font-weight-standard">{props.item.text}</span>
        </div>
      );
    };
    const {
      buttonCreateDisabled,
      showErrorMessageEmpty,
      showErrorMessageRegEx,
    } = this.state;
    return (
      <div className="mt-2 mb-2 text-left">
        <span
          ref={(button) => {
            this.newDocumentAnchor = button;
          }}
        >
          {!this.state.inRecycleBin && (
            <DropDownButton
              items={this.documentTypeItems}
              primary={true}
              itemRender={documentTypeItemRender}
              text={this.props.i18n.buttonNewDocumentText}
              icon="file-add"
              className="mr-2"
              disabled={this.props.loadedContainer === undefined}
              onItemClick={(clickedElement) =>
                this.NewDocumentOnClickHandler(clickedElement.item)
              }
            />
          )}
        </span>
        {(this.props.allowShowEasyTemplate &&  !this.state.inRecycleBin) && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.EasyTemplateOnClickHandler}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="AddTo" className="pr-2" />
            {this.props.i18n.buttonEasyTemplate}
          </button>
        )}
        {!this.state.inRecycleBin && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.handleFileSelect}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="Upload" className="pr-2" />
            {this.props.i18n.buttonFileText}
          </button>
        )}
        {!this.state.inRecycleBin && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.AddFolderOnClickHandler}
            ref={(button) => {
              this.addFolderAnchor = button;
            }}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="AddTo" className="pr-2" />
            {this.props.i18n.buttonFolderText}
          </button>
        )}
        {!this.state.inRecycleBin && this.state.itemsSelected && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.DeleteSelectedItemsHandler}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="Delete" className="pr-2" />
            {this.props.i18n.buttonDeleteText}
          </button>
        )}
        {this.state.inRecycleBin && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.EmptyRecycleBinClickHandler}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="Delete" className="pr-2" />
            {this.props.i18n.buttonDeleteAllText}
          </button>
        )}
        {this.state.inRecycleBin && this.state.itemsSelected && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.RestoreRecycleBinItemClickHandler}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="RevToggleKey" className="pr-2" />
            {this.props.i18n.buttonRestoreText}
          </button>
        )}
        {!this.state.inRecycleBin && this.state.itemsSelected && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.MoveSelectedItemsHandler}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="FabricMovetoFolder" className="pr-2" />
            {this.props.i18n.buttonMoveAllText}
          </button>
        )}
        {!this.state.inRecycleBin && this.state.itemsSelected && (
          <button
            className="k-button k-primary mr-2 font-weight-standard"
            onClick={this.CopySelectedItemsHandler}
            disabled={this.props.loadedContainer === undefined}
          >
            <Icon iconName="Copy" className="pr-2" />
            {this.props.i18n.buttonCopyAllText}
          </button>
        )}
        <button
          className="k-button k-primary mr-2 font-weight-standard"
          onClick={this.RefreshItemListOnClickHandler}
          disabled={this.props.loadedContainer === undefined}
        >
          <Icon iconName="Refresh" className="pr-2" />
          {this.props.i18n.buttonRefreshText}
        </button>

        <Popup
          id="new-document-popup"
          show={this.state.newDocumentPopupOpen}
          popupClass={"popup-content"}
          anchor={this.newDocumentAnchor as HTMLButtonElement}
        >
          <form onSubmit={this.handleNewDocumentSubmit} className="k-form p-1">
            <Input
              name="Name"
              label={this.props.i18n.newDocumentInputLabel}
              className="mb-1"
              minLength={1}
              required={true}
              autoComplete="off"
              autoFocus={true}
              onChange={this.handleChange.bind(this)}
              onFocus={this.handleFocus.bind(this)}
            />
            {showErrorMessageEmpty && (
              <div className="row popup-errorMessage">
                {this.props.i18n.nameEmptyErrorMessage}
              </div>
            )}
            {showErrorMessageRegEx && (
              <div className="row popup-errorMessage">
                {this.props.i18n.nameValidationErrorMessage}
              </div>
            )}
            <Input
              type="submit"
              className="k-button k-primary font-weight-standard"
              value={this.props.i18n.buttonDocumentCreateText}
              disabled={buttonCreateDisabled || this.state.isProcessing}
            />
          </form>
        </Popup>
        <Popup
          id="add-folder-popup"
          show={this.state.addFolderPopupOpen}
          popupClass={"popup-content"}
          anchor={this.addFolderAnchor as HTMLButtonElement}
        >
          <form onSubmit={this.handleNewFolderSubmit} className="k-form p-1">
            <Input
              name="Name"
              label={this.props.i18n.folderNameInputLabel}
              className="mb-1"
              minLength={1}
              required={true}
              autoComplete="off"
              autoFocus={true}
              onChange={this.handleChange.bind(this)}
              onFocus={this.handleFocus.bind(this)}
            />
            {showErrorMessageEmpty && (
              <div className="row popup-errorMessage">
                {this.props.i18n.nameEmptyErrorMessage}
              </div>
            )}
            {showErrorMessageRegEx && (
              <div className="row popup-errorMessage">
                {this.props.i18n.nameValidationErrorMessage}
              </div>
            )}
            <Input
              type="submit"
              className="k-button k-primary"
              value={this.props.i18n.buttonFolderCreateText}
              disabled={buttonCreateDisabled}
            />
          </form>
        </Popup>
      </div>
    );
  }
}

export default Localization.translateComponent<
  IActionBarProps,
  IActionBarState
>("ActionBar")(ActionBar);
