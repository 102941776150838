import * as React from "react";
import * as Localization from "./Translate";
import {
  ITranslationProps,
  IOrganizationData,
  IUser,
} from "../data/interfaces";
import { Modal, Link } from "office-ui-fabric-react";

export interface ITenantInvalidMessageProps extends ITranslationProps {
  organizationData: IOrganizationData | undefined;
  activeUser: IUser | undefined;
  onSignOut(e: any): void;
}

export interface ITenantInvalidMessageState {}

class TenantInvalidMessage extends React.Component<
  ITenantInvalidMessageProps,
  ITenantInvalidMessageState
> {
  private handleSignOutClick(e: any) {
    this.props.onSignOut(e);
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={true}
          isBlocking={true}
          isDarkOverlay={true}
          className="licenseDialog-modal"
        >
          <div className="licenseDialog-header">
            <h2>Invalid product license</h2>
          </div>
          <div className="licenseDialog-content">
            <p>{this.props.i18n.licenseInValidMessage}</p>
            <p>
              {this.props.activeUser &&
                Localization.formatString(
                  this.props.i18n.signedInUserText,
                  this.props.activeUser.userPrincipalName
                )}
            </p>
          </div>
          <div className="licenseDialog-footer">
            <Link
              onClick={this.handleSignOutClick.bind(this)}
              className="licenseDialog-signout-link"
            >
              {this.props.i18n.linkSignOutText}
            </Link>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Localization.translateComponent<
  ITenantInvalidMessageProps,
  ITenantInvalidMessageState
>("TenantInvalidMessage")(TenantInvalidMessage);
