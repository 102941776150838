import * as React from "react";
import * as Localization from "./Translate";
import {
  IBaseItem,
  IDrive,
  IDriveItem,
  ITranslationProps,
} from "../data/interfaces";
import { Modal } from "office-ui-fabric-react";
import {
  EasyTemplate,
  IClient,
  OfficeHostType,
} from "@iexpertsnl/templates-ui";
import ServiceClient from "../data/ServiceClient";
import { Client } from "../data/EasyTemplateClient";
import { isDrive, isDriveItem } from "../helpers/typeCheck";
import LoadingIndicator from "./LoadingIndicator";

export interface IEasyTemplateDialogProps extends ITranslationProps {
  serviceClient: ServiceClient;
  onCloseEvent(): void;
  activeItem: IBaseItem | undefined;
}

export interface IEasyTemplateDialogState {
  folderId: string;
  client: Client | undefined;
}

class EasyTemplateDialog extends React.Component<
  IEasyTemplateDialogProps,
  IEasyTemplateDialogState
> {
  constructor(props: IEasyTemplateDialogProps) {
    super(props);
    this.state = {
      folderId: "",
      client: undefined,
    };
  }

  private handleOnDismissModal() {
    this.props.onCloseEvent();
  }

  public async componentDidMount() {
    let client: Client = await Client.GetClient(this.props.serviceClient);
    this.setState({ client: client });
  }

  public async componentWillReceiveProps(newProps: IEasyTemplateDialogProps) {
    if (this.props.serviceClient != newProps.serviceClient) {
      let client: Client = await Client.GetClient(newProps.serviceClient);
      this.setState({ client: client });
    }
  }

  public render(): React.ReactNode {
    if (!this.state.client || !this.props.activeItem) return <LoadingIndicator message="" />;

    let siteId = undefined;
    let driveId = undefined;
    let folderId = undefined;
    if (isDrive(this.props.activeItem)) {
      driveId = (this.props.activeItem as IDrive).id;
    } else if (isDriveItem(this.props.activeItem)) {
      driveId = (this.props.activeItem as IDriveItem).driveId;
      folderId = (this.props.activeItem as IDriveItem).id;
    }

    return (
      <Modal
        isOpen={true}
        onDismiss={this.handleOnDismissModal.bind(this)}
        isModeless={false}
        isBlocking={true}
      >
        <div className="messageDialog-content">
          <EasyTemplate
            strings={this.props.i18n}
            hostType={OfficeHostType.Unknown}
            client={this.state.client}
            driveId={driveId}
            siteId={siteId}
            folderId={folderId}
            onClose={this.handleOnDismissModal.bind(this)}
          />
        </div>
      </Modal>
    );
  }
}

export default Localization.translateComponent<
  IEasyTemplateDialogProps,
  IEasyTemplateDialogState
>("EasyTemplateDialog")(EasyTemplateDialog);
