import { IErrorResult, IError } from "../data/interfaces";
import { ErrorVisibility } from "../data/enums";

export default {
  ActionBar: {
    fileLabelRecycleBin: "Prullenbak",
    buttonFileText: "Uploaden",
    buttonMoveAllText: "Verplaats",
    buttonCopyAllText: "Kopieer",
    buttonRefreshText: "Vernieuwen",
    buttonDeleteText: "Verwijderen",
    buttonDeleteAllText: "Prullenbak leegmaken",
    buttonRestoreText: "Item(s) herstellen",
    buttonFolderText: "Map",
    buttonFolderCreateText: "Maken",
    buttonDocumentCreateText: "Maken",
    buttonNewDocumentText: "Nieuw Document",
    newDocumentInputLabel: "Documentnaam",
    buttonEasyTemplate: "EasyTemplate",
    fileAddingFailedMessage: "Het bestand kan niet worden toegevoegd",
    fileTooLargeMessage:
      "Uploaden is afgebroken omdat minstens één bestand groter is dan de maximale grootte van 4 GB.",
    folderNameInputLabel: "Uw mapnaam:",
    folderCreatingFailedMessage: "De map kan niet worden aangemaakt",
    nameEmptyErrorMessage: "Naam mag niet leeg zijn!",
    nameValidationErrorMessage: `Voer een naam in waarin de volgende tekens niet voorkomen: " * : < > ? / \\ |`,
  },
  AlertDialog: {
    loadingIndicatorMessage: "Bezig met verplaatsen...",
  },
  App: {
    errorDialogTitle: "Foutmelding",
    moveFileFailedMessage: "Het verplaatsen is mislukt.",
    copyFileFailedMessage: "Het kopiëren is mislukt.",
    moveFileFailedMessageResult:
      "Één of meer items zijn niet verplaatst. Bijvoorbeeld omdat deze in gebruik is/zijn of dat ze al bestaan op de doellocatie.",
    copyFileFailedMessageResult:
      "Één of meer items zijn niet gekopieerd. Bijvoorbeeld omdat deze al bestaan op de doellocatie",
    noSitesFoundMessage: "Er zijn geen sites gevonden in",
    oneDrivesDatasetName: "Persoonlijke bestanden",
    favoriteSitesDatasetName: "Favoriete sites",
    sharePointSitesDatasetName: "Alle sites",
    noTreeviewItemSelectedMessage:
      "In de boomstructuur aan de linkerkant is nog geen OneDrive, site, bibliotheek of map geselecteerd!",
    noContentsToShowForSites:
      "Klik in de boomstructuur aan de linkerkant op een bibliotheek of map om de inhoud weer te geven.",
    moveFileMessageDialogTitle: "Item(s) verplaatsen",
    copyFileMessageDialogTitle: "Item(s) kopiëren",
    moveFileMessageDialogSourceAndTargetAreEqualMessage:
      "Bron en doellocatie zijn hetzelfde. Item(s) kunnen niet verplaatst worden.",
    copyFileMessageDialogSourceAndTargetAreEqualMessage:
      "Bron en doellocatie zijn hetzelfde. Item(s) kunnen niet gekopieerd worden.",
    tenantIsValidSPOFailedMessage:
      "Deze tenant heeft geen geldige licentie in Azure of is verlopen.",
    fileLabelRecycleBin: "Prullenbak",
  },
  BasePanel: {
    closeButtonAriaLabel: "Afsluiten",
  },
  CommandBar: {
    searchBoxPlaceHolderText: "Zoeken in SharePoint",
    searchBoxTooltipText: "Zoekopdracht verzenden",
  },
  CommandBarDropdown: {
    linkSignOutText: "Afmelden",
    myAccountText: "Mijn account",
  },
  DataLoadingSpinnner: {
    loadingIndicatorMessage: "Data wordt geladen...",
  },
  DriveItemsList: {
    appNotAvailableMessage:
      "U probeert #fileName# te openen in een app, echter is de betreffende app #appName# niet op deze computer geïnstalleerd. Of klik op 'Open #app#?' in de popup. ",
    columnHeaderNameTitle: "Naam",
    columnHeaderSizeTitle: "Bestandsgrootte",
    columnHeaderModifiedTitle: "Gewijzigd",
    columnHeaderOriginalLocationTitle: "Oorspronkelijke locatie",
    columnHeaderDeletedByTitle: "Verwijderd door",
    columnHeaderDeletedOnTitle: "Verwijderd op",
    contextMenuItemRestoreText: "Terugzetten",
    contextMenuItemOpenInBrowserText: "Openen in browser",
    contextMenuItemOpenInAppText: "Openen in app",
    contextMenuItemRenameText: "Naam wijzigen",
    contextMenuItemCopyText: "Kopiëren",
    contextMenuItemDownloadText: "Downloaden",
    contextMenuItemRemoveText: "Verwijderen",
    contextMenuItemMoveText: "Verplaatsen naar",
    contextMenuItemShareText: "Delen",
    confirmDeletePreFixMessage: "Weet u zeker dat u",
    confirmDeletePostFixMessage: "naar de Prullenbak wilt verzenden?",
    failedDeleteMessage: "Item kan niet worden verwijderd.",
    failedDeletePreFixMessage: "Item",
    failedDeletePostFixMessage: "kan niet worden verwijderd.",
    failedRenameMessage: "Item kan niet worden hernoemd.",
    messageDialogConfirmTitle: "Verwijderen bevestigen",
    noDownloadAvailableMessage:
      "Op dit moment is het niet mogelijk om mappen en alle inhoud te downloaden.",
    noFilesOrFoldersMessage: "Geen bestanden of mappen aanwezig",
    fileLabelFile: "Bestand",
    fileLabelRecycleBin: "Prullenbak",
    fileLabelOf: "van",
    fileAddingFailedMessage: "Het bestand kan niet worden toegevoegd",
    fileTooLargeMessage:
      "Uploaden is afgebroken omdat minstens één bestand groter is dan de maximale grootte van 4 GB.",
    folderInDragnDropSelectionMessage:
      "Selecteer alleen bestanden a.u.b. (mappen kunnen nog niet worden gesleept en neergezet)",
    folderPostFixTitle: "map",
  },
  ItemModificationQueue: {
    addMessage: "wordt toegevoegd...",
    uploadMessage: "wordt geüpload...",
    removeMessage: "wordt verwijderd...",
    restoreMessage: "wordt teruggeplaatst...",
    emptyMessage: "Prullenbak wordt geleegd...",
    updateMessage: "wordt bijgewerkt...",
    renameMessage: "wordt hernoemd...",
    moveMessage: "wordt verplaatst...",
    copyMessage: "wordt gekopieerd...",
    loadMessage: "wordt geladen...",
    batchRemoveMessage: "items worden verwijderd...",
    batchMoveMessage: "items worden verplaatst...",
    batchCopyMessage: "items worden gekopieerd...",
    labelFile: "Bestand",
    labelFolder: "Map",
  },
  MessageDialog: {
    buttonNoText: "Nee",
    buttonYesText: "Ja",
    buttonCloseText: "Sluiten",
  },
  DestinationDialog: {
    breadCrumbKey0Title: "Kies een locatie",
    loadingMessage: "Even geduld a.u.b....",
    emptyFolderMoveText:
      "Deze map is leeg. Klik op de knop 'Hierheen verplaatsen' om het bestand naar deze map te verplaatsen.",
    emptyFolderCopyText:
      "Deze map is leeg. Klik op de knop 'Hierheen kopiëren' om het bestand naar deze map te kopiëren.",
    moveToHereText: "Hierheen verplaatsen",
    copyToHereText: "Hierheen kopiëren",
  },
  CopyDialog: {
    breadCrumbKey0Title: "Kies een locatie",
    loadingMessage: "Even geduld a.u.b....",
    loadingIndicatorMessage: "Item wordt gekopieerd...",
    emptyFolderText:
      "Deze map is leeg. Klik op de knop 'Hierheen kopiëren' om het bestand naar deze map te kopiëren.",
    copyToHereText: "Hierheen kopiëren",
  },
  ProgressBar: {
    buttonCancelText: "Upload annuleren",
    labelCancelText: "Bezig met annuleren...",
    labelInitText: "Bezig met uploaden...",
    modalTitle: "Bestanden uploaden",
  },
  RenameDialog: {
    dialogTitle: "Naam wijzigen",
    newFileNameInputText: "Nieuwe bestandsnaam",
    newFolderNameInputText: "Nieuwe mapnaam",
    buttonSaveText: "Opslaan",
    buttonCancelText: "Annuleren",
    nameEmptyErrorMessage: "Naam mag niet leeg zijn!",
    nameValidationErrorMessage: `Voer een naam in waarin de volgende tekens niet voorkomen: " * : < > ? / \\ |`,
    nameLabel: "Naam",
  },
  ShareDialog: {
    dialogTitle: "Kopieer koppeling",
    copyShareLinkToClipboardText: "Kopieer de koppeling naar het klembord",
    creatingLinkMessage: "Koppeling wordt gemaakt...",
    createdLinkMessage: "Koppeling is gekopieerd",
  },
  TenantInvalidMessage: {
    licenseInValidMessage:
      "Er is geen actieve product licentie aangetroffen voor de huidige tenant.",
    signedInUserText:
      "U bent momenteel ingelogd als '{0}'. U kunt proberen om uit te loggen en opnieuw in te loggen met een ander account.",
    linkSignOutText: "Afmelden",
    linkSignOutTooltipText: "Klik hier om de huidige gebruiker af te melden",
    SPOInValidMessage:
      "Deze tenant heeft een ongeldige of verlopen licentie in Azure, de applicatie kan hierdoor niet worden gestart!",
  },
  TreeViewNavigator: {
    fileLabelRecycleBin: "Prullenbak",
    fileTooLargeMessage:
      "Uploaden is afgebroken omdat minstens één bestand groter is dan de maximale grootte van 4 GB.",
    folderInDragnDropSelectionMessage:
      "Selecteer alleen bestanden a.u.b. (mappen kunnen nog niet worden gesleept en neergezet)",
    permissionControlText: "Machtigingen beheren",
    reloadItemText: "Opnieuw laden",
    loadingText: "worden geladen...",
    followSite: "Site volgen",
    unfollowSite: "Site niet volgen",
  },
  upload: {
    cancel: "Annuleren", //Cancel
    clearSelectedFiles: "Wissen", //Clear
    dropFilesHere: "Plaats hier uw bestanden om te uploaden", //Drop files here to upload
    headerStatusUploaded: "Gereed", //Done
    headerStatusUploading: "Uploaden...", //Uploading...
    invalidFileExtension: "Bestandstype niet toegestaan.", //File type not allowed.
    invalidFiles:
      "Ongeldige bestand (en). Controleer de vereisten voor het uploaden van bestanden.", //Invalid file(s). Please check file upload requirements.
    invalidMaxFileSize: "Bestandsgrootte te groot.", //File size too large.
    invalidMinFileSize: "Bestandsgrootte te klein.", //File size too small.
    remove: "Verwijderen", //Remove
    retry: "Оpnieuw proberen", //Retry
    select: "Selecteer bestanden...", //Select files...
    uploadSelectedFiles: "Upload", //Upload
    total: "Totaal", //Total
    files: "bestanden", //files
  },
  EasyTemplateDialog: {
    Title: "EasyTemplate",
    Description: "Maak een nieuw bestand aan de hand van een bestaand sjabloon",
    Error: "Oeps! Er is iets fout gegaan",
    Close: "Sluiten",
    Retry: "Opnieuw proberen",
    Back: "Terug",
    Next: "Volgende",
    Unlicensed: "Uw organisatie heeft geen geldige licentie voor deze applicatie.",

    Word: "Word",
    Excel: "Excel",
    PowerPoint: "PowerPoint",

    Site: "Site",
    Sites: "Sites",
    Library: "Bibliotheek",
    Libraries: "Bibliotheken",
    Folder: "Map",
    Folders: "Mappen",
    Templates: "Sjablonen",

    Loading: "{0} laden...",
    CreatingFile: "Bestand maken...",
    ForWhichApplication: "Voor welke applicatie wilt u een bestand maken?",
    EnterMetadata: "Voer aub document informatie in",
    FilenameHelp: "Voer aub een naam voor uw bestand in ",
    Preview: "Voorbeeld van sjabloon '{filename}'",
    CopySuccesfull: "Bestand succesvol aangemaakt.",
    NewFile: "U kunt uw nieuwe bestand <a href='{webURL}'>hier vinden!</a>",
    SelectTemplate: "Selecteer een sjabloon",
    NoTemplatesFound: "Geen sjablonen gevonden",
    NoPreviewFound: "Voorbeeld niet beschikbaar",
    SearchText: "Vind een sjabloon",
    ChooseLocation: "Waar moet uw nieuwe document worden opgeslagen?",

    SitePages: "Pagina's",
    SelectTag: "Filter op tag...",
    NoDescriptionText: "",
    NoTagsText: ""
  },
};

const httpErrors = [
  {
    Code: "accessDenied",
    Description:
      "De gebruiker heeft geen toestemming om de actie uit te voeren.", //The caller doesn't have permission to perform the action.
    HTTPstatuscode: 403,
  },
  {
    Code: "activityLimitReached",
    Description: "De app of gebruiker is gestopt.", // The app or user has been throttled.
    HTTPstatuscode: 429,
  },
  {
    Code: "generalException",
    Description: "Er is een niet-gespecificeerde fout opgetreden.", //An unspecified error has occurred.
    HTTPstatuscode: 500, // General error
  },
  {
    Code: "invalidRange",
    Description: "Het opgegeven bytebereik is ongeldig of niet beschikbaar.", //The specified byte range is invalid or unavailable.
    HTTPstatuscode: 416,
  },
  {
    Code: "invalidRequest",
    Description: "Het verzoek of de opmaak is onjuist.", //The request is malformed or incorrect.
    HTTPstatuscode: 400,
  },
  {
    Code: "itemNotFound",
    Description: "De bron kan niet worden gevonden.", //The resource could not be found.
    HTTPstatuscode: 404,
  },
  {
    Code: "malwareDetected",
    Description: "Malware is gedetecteerd in de aangevraagde bron.", //Malware was detected in the requested resource.
    HTTPstatuscode: 500, // General error
  },
  {
    Code: "nameAlreadyExists",
    Description: "Het item bestaat al.", //The specified item name already exists.
    HTTPstatuscode: 409,
  },
  {
    Code: "notAllowed",
    Description: "De actie is niet toegestaan door het systeem.", //The action is not allowed by the system.
    HTTPstatuscode: 405,
  },
  {
    Code: "notSupported",
    Description: "Het verzoek wordt niet ondersteund door het systeem.", //The request is not supported by the system.
    HTTPstatuscode: 415,
  },
  {
    Code: "resourceModified",
    Description:
      "De bron die wordt bijgewerkt, is gewijzigd sinds de gebruiker deze voor het laatst heeft gelezen, meestal een niet-overeenkomende eTag.", //The resource being updated has changed since the caller last read it, usually an eTag mismatch.
    HTTPstatuscode: 410,
  },
  {
    Code: "resyncRequired",
    Description:
      "Het deltatoken is niet langer geldig en de app moet de synchronisatiestatus opnieuw instellen.", //The delta token is no longer valid, and the app must reset the sync state.
    HTTPstatuscode: 500, // General error
  },
  {
    Code: "serviceNotAvailable",
    Description:
      "De service is niet beschikbaar. Probeer het verzoek na een vertraging opnieuw. Er kan een Retry-After-header zijn.", //The service is not available. Try the request again after a delay.There may be a Retry-After header.
    HTTPstatuscode: 503,
  },
  {
    Code: "quotaLimitReached",
    Description: "De gebruiker heeft zijn quotumlimiet bereikt.", //The user has reached their quota limit.
    HTTPstatuscode: 507,
  },
  {
    Code: "unauthenticated",
    Description: "De gebruiker is niet geverifieerd.", //The caller is not authenticated.
    HTTPstatuscode: 401,
  },

  // Custom statuscodes section
  {
    Code: "pathIsTooLongWhenRenaming",
    Description:
      "De opgegeven bestands- of mapnaam is te lang. Het URL-pad voor alle bestanden en mappen mag 400 tekens of minder bevatten. Typ een kortere bestands- of mapnaam voor: ",
    HTTPstatuscode: 475,
  },
  {
    Code: "pathIsTooLongWhenMoving",
    Description:
      "In de nieuwe map wordt het totale URL-pad te lang. Het URL-pad voor alle bestanden en mappen mag 400 tekens of minder bevatten. Typ eerst een kortere bestands- of mapnaam en probeer het opnieuw voor: ",
    HTTPstatuscode: 476,
  },
  {
    Code: "tenantNoValidSPOLicense",
    Description: "Deze tenant heeft geen geldige SPO licentie of is verlopen.", //Tenant does not have a SPO license.
    HTTPstatuscode: 477,
  },
  {
    Code: "pathIsTooLongWhenAddingFolder",
    Description:
      "Door het aanmaken van de nieuwe map wordt het totale URL-pad te lang (door deze nieuwe map en voorliggende mappen). Het URL-pad voor alle bestanden en mappen mag 400 tekens of minder bevatten. Typ een kortere mapnaam en probeer het opnieuw voor de map: ",
    HTTPstatuscode: 478,
  },
];

export function getErrors_nl() {
  return httpErrors;
}

const errors: IError[] = [
  {
    internalErrorCode: 400,
    correlationId: "",
    message:
      "Er gaat iets mis aan onze kant. Gelieve melding te maken als vaker voorkomt.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 401,
    correlationId: "",
    message:
      "U heeft geen toegang tot alle benodigde bronnen om deze applicatie te gebruiken.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 420,
    correlationId: "",
    message: "Uw organisatie heeft geen geldige licentie voor deze applicatie.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 421,
    correlationId: "",
    message: "De licentie van uw organisatie voor deze applicatie is verlopen.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 422,
    correlationId: "",
    message: "Uw organisatie kan niet via Microsoft worden geverifieerd.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 500,
    correlationId: "",
    message:
      "Er is een onbekende fout opgetreden. Probeer het later nog eens of neem contact op met het beheer.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 608,
    correlationId: "",
    message: "Er bestaat al een item met dezelfde naam.",
    errorVisibility: ErrorVisibility.MessageBar,
  },
  {
    internalErrorCode: 710,
    correlationId: "",
    message: "De gekozen naam is te lang.",
    errorVisibility: ErrorVisibility.MessageBar,
  },
  {
    internalErrorCode: 711,
    correlationId: "",
    message: "Het verplaatsen naar een andere bibliotheek is mislukt.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 720,
    correlationId: "",
    message: "Het is niet mogelijk om een leeg bestand te uploaden.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 721,
    correlationId: "",
    message:
      "Er is een fout opgetreden bij het uploaden. Probeer het nog eens.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 723,
    correlationId: "",
    message: "Het ophalen van het gekozen type bestand is mislukt.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 724,
    correlationId: "",
    message:
      "Er is een fout opgetreden bij het aanmaken. Probeer het nog eens.",
    errorVisibility: ErrorVisibility.MessageBar,
  },
  {
    internalErrorCode: 725,
    correlationId: "",
    message:
      "Er is een timeout opgetreden bij het verplaatsen. Probeer het nog eens.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 726,
    correlationId: "",
    message: "Het item is geblokkeerd. Probeer het nog eens.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 730,
    correlationId: "",
    message:
      "Delen is niet ingeschakeld voor dit item. Om delen in te schakelen kunt u contact opnemen met uw beheerder.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 901,
    correlationId: "",
    message:
      "Er bestaat reeds een bestand of map met dezelfde naam op de oorspronkelijke locatie. <br /><br /> Om het bestand terug te plaatsen, hernoem eerst het bestand op de oorspronkelijke locatie.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 902,
    correlationId: "",
    message:
      "De oorspronkelijke locatie om het bestand of map terug te plaatsen is verwijderd.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
  {
    internalErrorCode: 903,
    correlationId: "",
    message: "Het bestand of map kon niet gevonden worden in de prullenbak.",
    errorVisibility: ErrorVisibility.GlobalDialog,
  },
];

export function getError_nl(errorResult: IErrorResult): IError {
  let error: IError | undefined = errors.find((error: IError) => {
    return error.internalErrorCode === errorResult.internalErrorCode;
  });

  if (error === undefined) {
    return {
      internalErrorCode: 500,
      correlationId:
        "Als deze fout blijft optreden, neem dan contact op met het beheer.",
      message:
        "Er is een onbekende fout opgetreden. Probeer het later nog eens.",
      errorVisibility: ErrorVisibility.GlobalDialog,
    };
  }

  error.correlationId = errorResult.correlationId;

  return error;
}
