import { ISite, IDrive, IDriveItem, IRootData, IRecycleBin } from "../data/interfaces";

// Type guard for interface type checking of ISite instances
export function isSite(instance: any): instance is ISite {
  return "webUrl" in instance && instance.id.indexOf(",") > -1;
}

// Type guard for interface type checking of IRecycleBin instances
export function isRecycleBin(instance: any): instance is IRecycleBin {
  return instance.id.startsWith("rb"); 
}

// Type guard for interface type checking of IDrive instances
export function isDrive(instance: any): instance is IDrive {
  return "driveUrl" in instance;
}

// Type guard for interface type checking of IDrive instances
export function isOneDrive(instance: any): instance is IDrive {
  return "driveUrl" in instance && (instance as IDrive).isOneDrive;
}

// Type guard for interface type checking of IDriveItem instances
export function isDriveItem(instance: any): instance is IDriveItem {
  return "isFolder" in instance;
}

// Type guard to check the result.
export function isDriveItemServiceClient(instance: any) {
  var driveItem = instance as IDriveItem;
  if (driveItem.name !== undefined) {
    return true;
  } else {
    return false;
  }
}

// Type guard to check the result of rootdata.
export function isRootDataServiceClient(data: any) {
  var rootData = data as IRootData;
  if (rootData.displayName !== undefined) return true;
  else return false;
}
