import React from "react";
import { ProgressIndicator } from "office-ui-fabric-react/lib/ProgressIndicator";
import * as Localization from "./Translate";
import {
  ITranslationProps,
  IBaseItem,
  IOperationStatus,
} from "../data/interfaces";
import { Icon } from "office-ui-fabric-react";

export interface IItemModificationQueueProps extends ITranslationProps {
  modifications: IBaseItemModification[];
  actionCompletedEvent(item: IBaseItem): void;
  actionFailedEvent(item: IBaseItem): void;
  onClose(): void;
  onMonitorProgressEvent(monitorUrl: string): Promise<IOperationStatus>;
  onStatusCompleteEvent(item: IBaseItemModification): void;
}

// export interface IItemModification extends IBaseItemModification {
//   item: IBaseItem;
// }

// export interface IModificationSummary extends IBaseItemModification {
//   items: number;
// }

export interface IBaseItemModification {
  type: ModificationType;
  items: IBaseItem[];
  monitorUrls: string[] | undefined;
}

export enum ModificationType {
  ADD,
  UPLOAD,
  REMOVE,
  RESTORE,
  UPDATE,
  RENAME,
  MOVE,
  LOAD,
  EMPTY,
  COPY,
}

interface IItemModificationQueueState {
  queue: IBaseItemModification[];
  intervalId: any;
}

class ItemModificationQueue extends React.Component<
  IItemModificationQueueProps,
  IItemModificationQueueState
> {
  constructor(props: IItemModificationQueueProps) {
    super(props);

    this.state = {
      queue: [],
      intervalId: undefined,
    };
  }

  public componentDidMount() {
    this.setState({
      queue: this.props.modifications,
    });

    window.addEventListener("beforeunload", this.unloadMessage);

    this.checkProgressStatus(this);
    var intervalId = setInterval(() => this.checkProgressStatus(this), 5000);
    this.setState({ intervalId: intervalId });
  }

  public componentDidUpdate(prevProps: IItemModificationQueueProps) {
    if (this.props.modifications !== prevProps.modifications) {
      this.setState({
        queue: this.props.modifications,
      });
    }
  }

  unloadMessage = (e: any) => {
    e.preventDefault();
    return (e.returnValue =
      "Er worden nog bestanden verplaast, weet u zeker dat u wilt stoppen?");
  };

  public componentWillUnmount() {
    window.removeEventListener("beforeunload", this.unloadMessage);

    clearInterval(this.state.intervalId);
  }

  private checkProgressStatus(self: this) {
    var modifiedQueue = self.state.queue;

    modifiedQueue.forEach((item) => {
      if (item.monitorUrls !== undefined) {
        item.monitorUrls.forEach((url) => {
          this.props
            .onMonitorProgressEvent(url)
            .then((status: IOperationStatus) => {
              if (status.status == "completed") {
                if (item.monitorUrls !== undefined) {
                  item.monitorUrls.splice(item.monitorUrls.indexOf(url), 1);
                }
              }
            });
        });
        if (item.monitorUrls.length === 0) {
          this.props.onStatusCompleteEvent(item);
        } else {
          self.setState({
            queue: modifiedQueue,
          });
        }
      }
    });
  }

  // private isItemModification(i: any) {
  //   return i.item !== undefined;
  // }

  private getModificationMessage(modification: IBaseItemModification) {
    if (modification.items.length == 1) {
      switch (modification.type) {
        case ModificationType.ADD:
          return `Item '${modification.items[0].displayName}' ${this.props.i18n.addMessage}`;
        case ModificationType.UPLOAD:
          return `${this.props.i18n.labelFile} '${modification.items[0].displayName}' ${this.props.i18n.uploadMessage}`;
        case ModificationType.REMOVE:
          if (modification.items[0].isContainer) {
            return `${this.props.i18n.labelFolder} '${modification.items[0].displayName}' ${this.props.i18n.removeMessage}`;
          } else {
            return `${this.props.i18n.labelFile} '${modification.items[0].displayName}' ${this.props.i18n.removeMessage}`;
          }
        case ModificationType.UPDATE:
          return `Item '${modification.items[0].displayName}' ${this.props.i18n.updateMessage}`;

        case ModificationType.RESTORE:
          return `Item '${modification.items[0].displayName}' ${this.props.i18n.restoreMessage}`;
        case ModificationType.RENAME:
          if (modification.items[0].isContainer) {
            return `${this.props.i18n.labelFolder} '${modification.items[0].displayName}' ${this.props.i18n.renameMessage}`;
          } else {
            return `${this.props.i18n.labelFile} '${modification.items[0].displayName}' ${this.props.i18n.renameMessage}`;
          }
        case ModificationType.MOVE:
          if (modification.items[0].isContainer) {
            return `${this.props.i18n.labelFolder} '${modification.items[0].displayName}' ${this.props.i18n.moveMessage}`;
          } else {
            return `${this.props.i18n.labelFile} '${modification.items[0].displayName}' ${this.props.i18n.moveMessage}`;
          }
        case ModificationType.COPY:
          if (modification.items[0].isContainer) {
            return `${this.props.i18n.labelFolder} '${modification.items[0].displayName}' ${this.props.i18n.copyMessage}`;
          } else {
            return `${this.props.i18n.labelFile} '${modification.items[0].displayName}' ${this.props.i18n.copyMessage}`;
          }
        case ModificationType.LOAD:
          return `Item '${modification.items[0].displayName}' ${this.props.i18n.loadMessage}`;
        default:
          break;
      }
    } else {
      switch (modification.type) {
        case ModificationType.REMOVE:
          return `${modification.items.length} ${this.props.i18n.batchRemoveMessage}`;
        case ModificationType.MOVE:
          return `${modification.items.length} ${this.props.i18n.batchMoveMessage}`;
        case ModificationType.COPY:
          return `${modification.items.length} ${this.props.i18n.batchCopyMessage}`;
        default:
          break;
      }
    }
  }

  private handleOnDismiss() {
    this.props.onClose();
  }

  render() {
    let currentModifications: JSX.Element[] = [];

    this.state.queue.forEach(
      (modification: IBaseItemModification, index: number) => {
        currentModifications.push(
          <li className="modification-queue-item" key={index}>
            <ProgressIndicator
              barHeight={4}
              label={this.getModificationMessage(modification)}
            />
          </li>
        );
      }
    );

    return (
      <div className="modification-container">
        <div className="modification-header">
          <button
            type="button"
            className="modification-closebutton"
            onClick={this.handleOnDismiss.bind(this)}
          >
            <Icon iconName="ChromeClose" className="icon" />
          </button>
        </div>
        <div className="modification-queue">
          <ul className="modification-queue-list">{currentModifications}</ul>
        </div>
      </div>
    );
  }
}

export default Localization.translateComponent<
  IItemModificationQueueProps,
  IItemModificationQueueState
>("ItemModificationQueue")(ItemModificationQueue);
