import * as React from "react";
import {
  IMessageDialogContext,
  ITranslationProps,
  IBaseItem
} from "../data/interfaces";
import * as Localization from "./Translate";
import Modal, { IDragOptions } from "office-ui-fabric-react/lib/Modal";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { MessageDialogType, ActionType } from "../data/enums";

export interface IMessageDialogProps extends ITranslationProps {
  showDialog: boolean;
  messageContext: IMessageDialogContext;
  onCloseEvent(): void;
  onButtonConfirmClickedEvent(item: IBaseItem, actionType: ActionType): void;
}

export interface IMessageDialogState {
  isDraggable: false;
}

class MessageDialog extends React.Component<
  IMessageDialogProps,
  IMessageDialogState
> {
  private _dragOptions: IDragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu
  };

  constructor(props: IMessageDialogProps) {
    super(props);
    this.state = {
      isDraggable: false
    };
  }

  private handleOnDismissModal() {
    this.props.onCloseEvent();
  }

  private handleOnClickButtonYes() {
    if (
      this.props.messageContext.item !== undefined &&
      this.props.messageContext.actionType !== undefined
    ) {
      this.props.onButtonConfirmClickedEvent(
        this.props.messageContext.item,
        this.props.messageContext.actionType
      );
    }
  }

  private handleOnClickButtonNo() {
    this.props.onCloseEvent();
  }

  render() {
    const { messageContext, i18n } = this.props;
    if (messageContext === undefined) return null;
    else {
      return (
        <div>
          <Modal
            isOpen={this.props.showDialog}
            onDismiss={this.handleOnDismissModal.bind(this)}
            isModeless={false}
            containerClassName="messageDialog-modal"
            dragOptions={this.state.isDraggable ? this._dragOptions : undefined}
            isBlocking={true}
          >
            <div className="messageDialog-header">
              <h2>{this.props.messageContext.dialogTitle}</h2>
            </div>
            <div className="messageDialog-content">
              <div
                dangerouslySetInnerHTML={{ __html: messageContext.message }}
              />
            </div>
            {messageContext.dialogType === MessageDialogType.Confirm && (
              <div className="messageDialog-footer">
                <button
                  type="button"
                  className="messageDialog-footer-button k-button"
                  onClick={this.handleOnClickButtonYes.bind(this)}
                >
                  {i18n.buttonYesText}
                </button>

                <button
                  type="button"
                  className="messageDialog-footer-button k-button k-primary"
                  onClick={this.handleOnClickButtonNo.bind(this)}
                >
                  {i18n.buttonNoText}
                </button>
              </div>
            )}
            {messageContext.dialogType === MessageDialogType.Close && (
              <div className="messageDialog-footer">
                <button
                  type="button"
                  className="messageDialog-footer-button k-button k-primary"
                  onClick={this.handleOnDismissModal.bind(this)}
                >
                  {i18n.buttonCloseText}
                </button>
              </div>
            )}
          </Modal>
        </div>
      );
    }
  }
}

export default Localization.translateComponent<
  IMessageDialogProps,
  IMessageDialogState
>("MessageDialog")(MessageDialog);
