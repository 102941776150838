import * as React from "react";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import {
  IUser,
  ITranslationProps,
  IMessageDialogContext,
} from "../data/interfaces";
import { calculateColor } from "../helpers/avatarHelper";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import CommandBarDropdown from "./CommandBarDropdown";
import * as Localization from "./Translate";
import { Icon } from "office-ui-fabric-react";
import { MessageDialogType } from "../data/enums";

export interface ICommandBarProps extends ITranslationProps {
  user: IUser | undefined;
  language: string;
  onSearch(keyword: string): void;
  onSignOut(e: any): void;
  showMessageDialog(messageDialogContext: IMessageDialogContext): void;
}

export interface ICommandBarState {
  showPanel: boolean;
  keyword: string;
  avatarColor: string;
  profileButtonSelectedClassName: string;
}

class CommandBar extends React.Component<ICommandBarProps, ICommandBarState> {
  constructor(props: ICommandBarProps) {
    super(props);
    this.state = {
      showPanel: false,
      keyword: "",
      avatarColor: "blue",
      profileButtonSelectedClassName: "",
    };
  }

  componentDidMount() {
    if (
      this.props.user !== undefined &&
      this.props.user.isPhotoAvailable === false
    ) {
      let avatarColor: string = calculateColor(
        this.props.user.userPrincipalName
      );
      this.setState({ avatarColor: avatarColor });
    } else {
      // Force a render otherwise the profile picture cannot be displayed.
      this.forceRender();
    }
  }

  componentDidUpdate() {
    if (this.props.user !== undefined)
      this.displayPhoto(this.props.user.imageDataURL);
  }

  forceRender() {
    this.setState({ showPanel: this.state.showPanel });
  }

  private displayPhoto(imgDataURL: any) {
    const photoElement: HTMLElement | null = document.getElementById(
      "commandBarProfilePhotoId"
    );
    if (photoElement !== null) {
      photoElement.setAttribute("src", imgDataURL);
    }
  }

  private handleProfileButtonClick() {
    this.setState({
      showPanel: true,
      profileButtonSelectedClassName: "commandbar-profile-button-selected",
    });
  }

  private handleCommandBarDropdownClose = () => {
    this.setState({ showPanel: false, profileButtonSelectedClassName: "" });
  };

  private handleCommandBarDropdownSignOut(e: any) {
    this.props.onSignOut(e);
  }

  private handleSearchBoxChange = (newValue: string) => {
    this.setState({
      keyword: newValue,
    });
  };

  private handleSearchBoxSearch(newValue: string) {
    if (newValue.length === 0) return;

    if (this.props.onSearch !== undefined) {
      this.props.onSearch(this.state.keyword);
    }
  }

  private handleTooltipHostButtonClick = () => {
    if (this.state.keyword.length === 0) return;

    if (this.props.onSearch !== undefined) {
      this.props.onSearch(this.state.keyword);
    }
  };

  private handleUserManualButtonClick = () => {
    const fullPath: string = `/manuals/Manual-FE-${this.props.language}.pdf`;

    this.fileExists(`${window.location.origin}${fullPath}`).then(
      (result: boolean) => {
        if (result) window.open(fullPath, "_blank");
        else {
          const dialogType: MessageDialogType = MessageDialogType.Close;
          const messageDialogContext: IMessageDialogContext = {
            dialogTitle: "User manual not available!",
            message: `${"The user manual for this language ("}${
              this.props.language
            }${") is not available. Make a service request to get this manual in this language"}`,
            dialogType: dialogType,
          };
          this.props.showMessageDialog(messageDialogContext);
        }
      }
    );
  };

  private fileExists(fullPath: string): Promise<boolean> {
    return new Promise((resolve) => {
      fetch(fullPath)
        .then((response: Response) => {
          resolve(response.ok && response.status === 200);
        })
        .catch((error: any) => {
          //console.log("error", error);
          resolve(false);
        });
    });
  }

  render() {
    return (
      <div className="text-right">
        <div className="commandbar-search-box-container">
          <SearchBox
            placeholder={this.props.i18n.searchBoxPlaceHolderText}
            onChange={this.handleSearchBoxChange.bind(this)}
            onSearch={this.handleSearchBoxSearch.bind(this)} // User presses enter
            className="commandbar-search-box"
          />
        </div>
        <div
          className="commandbar-search-button-container"
          hidden={this.state.keyword.length === 0}
        >
          <TooltipHost
            content={this.props.i18n.searchBoxTooltipText}
            calloutProps={{ gapSpace: 0 }}
            closeDelay={500}
            styles={{
              root: { display: "inline-block", height: "100%", width: "100%" },
            }}
          >
            <button
              onClick={this.handleTooltipHostButtonClick.bind(this)}
              disabled={this.state.keyword.length === 0}
              className="commandbar-search-button"
            >
              <Icon iconName="Forward" />
            </button>
          </TooltipHost>
        </div>
        <div className="commandbar-usermanual-button-container ml-4">
          <button
            className={"commandbar-usermanual-button"}
            onClick={this.handleUserManualButtonClick.bind(this)}
          >
            <Icon iconName="Help" className="commandbar-usermanual-icon" />
          </button>
        </div>
        <div className="commandbar-profile-button-container">
          <button
            className={
              "commandbar-profile-button " +
              this.state.profileButtonSelectedClassName
            }
            onClick={this.handleProfileButtonClick.bind(this)}
          >
            {this.props.user !== undefined &&
              this.props.user.isPhotoAvailable && (
                <img
                  id="commandBarProfilePhotoId"
                  className="commandbar-profile-photo"
                  src={undefined}
                  alt="MyPhoto"
                />
              )}
            {this.props.user !== undefined &&
              this.props.user.isPhotoAvailable === false && (
                <div
                  className={
                    "commandbar-profile-avatar avatar-color-" +
                    this.state.avatarColor
                  }
                >
                  {this.props.user.userInitials}
                </div>
              )}
          </button>
        </div>
        {this.state.showPanel && this.props.user !== undefined && (
          <CommandBarDropdown
            user={this.props.user}
            avatarColor={this.state.avatarColor}
            onClose={this.handleCommandBarDropdownClose.bind(this)}
            onSignOut={this.handleCommandBarDropdownSignOut.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Localization.translateComponent<
  ICommandBarProps,
  ICommandBarState
>("CommandBar")(CommandBar);
