import * as React from "react";
import { IUser, ITranslationProps } from "../data/interfaces";
import { Link } from "office-ui-fabric-react/lib/Link";
import * as Localization from "./Translate";
import ReactDOM from "react-dom";
import { Icon } from "office-ui-fabric-react";

export interface ICommandBarDropdownProps extends ITranslationProps {
  user: IUser;
  onClose(): void;
  onSignOut(e: any): void;
  avatarColor: string;
}

export interface ICommandBarDropdownState {
  showPanel: boolean;
}

class CommandBarDropdown extends React.Component<
  ICommandBarDropdownProps,
  ICommandBarDropdownState
> {
  constructor(props: ICommandBarDropdownProps) {
    super(props);
    this.state = { showPanel: true };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleEscapeKey, true);
    this.forceRender();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscapeKey, true);
  }

  componentDidUpdate() {
    this.displayPhoto(this.props.user.imageDataURL);
  }

  private handleClickOutside(event: any) {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.props.onClose();
    }
  }

  private handleEscapeKey = (event: any) => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  private forceRender() {
    this.setState({ showPanel: this.state.showPanel });
  }

  private displayPhoto(imgDataURL: any) {
    const photoElement: HTMLElement | null = document.getElementById(
      "commandBarDropdownProfilePhotoId"
    );
    if (photoElement !== null) {
      photoElement.setAttribute("src", imgDataURL);
    }
  }

  private handleLinkClick(e: any) {
    this.props.onSignOut(e);
  }

  private handleOnDismiss() {
    this.props.onClose();
  }

  render() {
    const {
      displayName,
      userInitials,
      userPrincipalName,
      isPhotoAvailable
    } = this.props.user;
    return (
      <div className="commandbar-dropdown-container text-left">
        <div className="commandbar-dropdown-header">
          <span className="commandbar-dropdown-header-title">
            {this.props.i18n.myAccountText}
          </span>
          <button
            type="button"
            className="commandbar-dropdown-closebutton"
            onClick={this.handleOnDismiss.bind(this)}
          >
            <Icon iconName="ChromeClose" className="icon" />
          </button>
        </div>
        <div className="commandbar-dropdown-content">
          <div className="commandbar-dropdown-profile-photo-container">
            {this.props.user !== undefined && isPhotoAvailable && (
              <img
                id="commandBarDropdownProfilePhotoId"
                className="commandbar-dropdown-profile-photo"
                src={undefined}
                alt="MyPhoto"
              />
            )}
            {this.props.user !== undefined && isPhotoAvailable === false && (
              <div
                className={
                  "commandbar-dropdown-profile-avatar avatar-color-" +
                  this.props.avatarColor
                }
              >
                {userInitials}
              </div>
            )}
          </div>
          <ul className="commandbar-dropdown-profile-data">
            <li>
              <b>
                {this.props.user !== undefined && displayName !== ""
                  ? displayName
                  : userInitials}
              </b>
            </li>
            <li>{this.props.user !== undefined ? userPrincipalName : null}</li>
            <li>
              <Link
                className="commandbar-dropdown-text-signout"
                onClick={this.handleLinkClick.bind(this)}
              >
                {this.props.i18n.linkSignOutText}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Localization.translateComponent<
  ICommandBarDropdownProps,
  ICommandBarDropdownState
>("CommandBarDropdown")(CommandBarDropdown);
