import React from "react";
import { IBaseItem, ITranslationProps } from "../data/interfaces";
import { ValidateName } from "../helpers/globalHelper";
import * as Localization from "./Translate";
import Modal, { IDragOptions } from "office-ui-fabric-react/lib/Modal";
import { ContextualMenu } from "office-ui-fabric-react/lib/ContextualMenu";
import { TextField } from "office-ui-fabric-react/lib/TextField";

export interface IRenameDialogProps extends ITranslationProps {
  showRenameDialog: boolean;
  item: IBaseItem;
  onClose(): void;
  onSave(newName: string): void;
}

interface IRenameDialogState {
  isResizable: boolean;
  newName: string;
  isDraggable: boolean;
}

class RenameDialog extends React.Component<
  IRenameDialogProps,
  IRenameDialogState
> {
  private fileExtensionWithPeriod: string = "";
  private showErrorMessageEmpty: boolean = false;
  private showErrorMessageRegEx: boolean = false;
  private buttonSaveDisabled: boolean = true;

  private _dragOptions: IDragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu
  };

  constructor(props: IRenameDialogProps) {
    super(props);

    this.state = {
      isResizable: false,
      newName: "",
      isDraggable: false
    };
  }

  handleButtonSave() {
    const { item, onSave } = this.props;
    const newName: string = this.state.newName;
    if (
      newName === undefined ||
      newName === "" ||
      newName === this.props.item.displayName
    ) {
      this.handleButtonClose();
      return;
    }

    if (ValidateName(newName)) {
      if (!item.isContainer) {
        onSave(newName + this.fileExtensionWithPeriod);
      } else onSave(newName);
    }

    this.setState({ newName: "" });
  }

  handleButtonClose() {
    this.showErrorMessageEmpty = false;
    this.showErrorMessageRegEx = false;
    this.setState({ newName: "" });
    this.props.onClose();
  }

  handleOnChange = (event: any) => {
    const value: string = event.target.value.trim();

    this.showErrorMessageEmpty = false;
    this.showErrorMessageRegEx = false;
    this.buttonSaveDisabled = false;

    if (value.length === 0) {
      this.showErrorMessageEmpty = true;
      this.buttonSaveDisabled = true;
      this.setState({ newName: "" });
      return;
    }

    if (!ValidateName(value)) {
      this.showErrorMessageRegEx = true;
      this.buttonSaveDisabled = true;
      this.setState({ newName: "" });
      return;
    }

    this.setState({ newName: value });
  };

  handleFocus = (event: any) => {
    event.target.select();
  };

  getFileName(fileName: string) {
    const lastIndex: number = fileName.lastIndexOf(".");
    if (lastIndex < 1) return "";
    return fileName.substr(0, lastIndex);
  }

  getFileExtension(fileName: string) {
    const lastIndex: number = fileName.lastIndexOf(".");
    if (lastIndex < 1) return "";
    return fileName.substr(lastIndex + 1);
  }

  getItemData = () => {
    const { showRenameDialog, item } = this.props;
    let fileName: string = "";
    let fileExtension: string = "";
    let folderName: string = "";

    if (item.isContainer) {
      folderName = item.displayName;
    } else {
      fileName = this.getFileName(item.displayName);
      fileExtension = "." + this.getFileExtension(item.displayName);
      this.fileExtensionWithPeriod = fileExtension;
    }

    return { showRenameDialog, folderName, fileName, fileExtension };
  };

  private handleOnDismiss() {
    this.handleButtonClose();
  }

  render() {
    const {
      showRenameDialog,
      folderName,
      fileName,
      fileExtension
    } = this.getItemData();
    return (
      <div>
        <Modal
          isOpen={showRenameDialog}
          onDismiss={this.handleOnDismiss.bind(this)}
          isModeless={false}
          containerClassName="renameDialog-modal"
          dragOptions={this.state.isDraggable ? this._dragOptions : undefined}
          isBlocking={true}
        >
          <div className="renameDialog-header">
            <h2>{this.props.i18n.dialogTitle}</h2>
          </div>
          <div className="renameDialog-content">
            <TextField
              label={this.props.i18n.nameLabel}
              placeholder={
                (fileName && this.props.i18n.newFileNameInputText) ||
                (folderName && this.props.i18n.newFolderNameInputText)
              }
              defaultValue={fileName || folderName}
              onChange={this.handleOnChange.bind(this)}
              autoFocus={true}
              suffix={fileName && fileExtension}
            />
          </div>
          <div className="renameDialog-error">
            {this.showErrorMessageEmpty &&
              this.props.i18n.nameEmptyErrorMessage}
            {this.showErrorMessageRegEx &&
              this.props.i18n.nameValidationErrorMessage}
          </div>
          <div className="renameDialog-footer">
            <button
              type="button"
              className="renameDialog-footer-button k-button"
              onClick={this.handleButtonClose.bind(this)}
            >
              {this.props.i18n.buttonCancelText}
            </button>
            <button
              type="button"
              className="renameDialog-footer-button k-button k-primary"
              onClick={this.handleButtonSave.bind(this)}
              disabled={this.buttonSaveDisabled}
            >
              {this.props.i18n.buttonSaveText}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Localization.translateComponent<
  IRenameDialogProps,
  IRenameDialogState
>("RenameDialog")(RenameDialog);
