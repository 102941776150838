const MSAL_LOGIN_PENDING: string = "msal.login.pending";
const MSAL_ACQUIRE_TOKEN_PENDING: string = "msal.acquire.token.pending";
const MSAL_PENDING_REDIRECT: string = "msal.pending.redirect";

export default class CacheHelper {
  private _cacheStorage: Storage | undefined;

  constructor(cacheLocation: string) {
    if (cacheLocation === "sessionStorage") {
      this._cacheStorage = sessionStorage;
    } else if (cacheLocation === "localStorage") {
      this._cacheStorage = localStorage;
    }
  }

  public setCacheEntry(key: string, value: string): void {
    if (this._cacheStorage) {
      this._cacheStorage.setItem(key, value);
    }
  }

  public getCacheEntry(key: string): string | null {
    if (this._cacheStorage) {
      return this._cacheStorage.getItem(key);
    }

    return null;
  }

  public hasCacheEntry(key: string): boolean {
    if (this._cacheStorage) {
      if (this._cacheStorage.getItem(key)) {
        return true;
      }
    }

    return false;
  }

  public clearCacheEntry(key: string): void {
    if (this._cacheStorage) {
      if (this._cacheStorage.getItem(key)) {
        this._cacheStorage.removeItem(key);
      }
    }
  }

  public setPendingRequest(value: string): void {
    this.setCacheEntry(MSAL_ACQUIRE_TOKEN_PENDING, value);
  }

  public hasPendingRequest(): boolean {
    return this.hasCacheEntry(MSAL_ACQUIRE_TOKEN_PENDING);
  }

  public clearPendingRequest(): void {
    this.clearCacheEntry(MSAL_ACQUIRE_TOKEN_PENDING);
  }

  public setPendingLogin(value: string): void {
    this.setCacheEntry(MSAL_LOGIN_PENDING, value);
  }

  public hasPendingLogin(): boolean {
    return this.hasCacheEntry(MSAL_LOGIN_PENDING);
  }

  public clearPendingLogin(): void {
    this.clearCacheEntry(MSAL_LOGIN_PENDING);
  }

  public setPendingRedirect(value: string): void {
    this.setCacheEntry(MSAL_PENDING_REDIRECT, value);
  }

  public hasPendingRedirect(): boolean {
    return this.hasCacheEntry(MSAL_PENDING_REDIRECT);
  }

  public clearPendingRedirect(): void {
    this.clearCacheEntry(MSAL_PENDING_REDIRECT);
  }
}
