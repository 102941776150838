import * as Localization from "../components/Translate";

export const getErrorDescription = (
  httpStatusCode: number,
  languageCode: string
): string => {
  const language: string = languageCode.substring(0, 2);
  const errors = Localization.getLanguagesErrors(language);

  const description = errors
    .filter(m => m.HTTPstatuscode === httpStatusCode)
    .map(x => x.Description)[0];

  return description;
};
